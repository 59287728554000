export class PlayerMetaData{
    fps?: number;
    streamName?: string;
    resolution?: string;
    streamDuration?: any;
    codec?:string;
    startedAt?:any

    constructor(init?: Partial<PlayerMetaData>) {
        Object.assign(this, init);
    }
}