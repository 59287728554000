<div class="page-filter p-relative flex-center-between">
    <span class="flex-shrink-0 mr-10">Sort by:</span>
    <button (click)="toggleDropdown()" [ngClass]="{'active' : dropdownOpen}" type="button" class="flex-center-between flex-1 button_selected_value">
      <span class="fw-600 t-color-primary selected_text_value">{{currentValue.name}}</span>
      <i class="icon icon_md arrow arrow-down arrow-color-primary-orange flex-shrink-0"></i>
    </button>

    <ul role="listbox" aria-expanded="false" [ngClass]="dropdownOpen ? 'dropdown_showed' : ''" class="dropdown-list dropdown">
        <li *ngFor="let o of options" class="dropdown-list__item text-left" [ngClass]="{'active' : currentValue === o}"
        tabindex="0"
        (click)="select(o)"
        role="option"
        [innerHTML]="o.name"></li>
    </ul>
  </div>
