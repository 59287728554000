<div class="dialog dialog-error">

  <div class="mb-40">
    <h2 class="text-center mb-20 t-color-black">Profile logout</h2>

    <p class="dialog__text text-center">
      You wish to leave this service.<br>
      Are you sure?
    </p>
  </div>

    <div style="justify-content: space-around;" class="dialog__actions">
        <button class="btn btn_outline btn_outline-main btn_size-primary btn_fluid btn_rounded-md" (click)="cancel()">Cancel</button>
        <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md"
            (click)="confirm()">Logout</button>
    </div>
</div>
