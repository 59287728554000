<div class="dialog dialog-copy-player">
    <div class="dialog__title-container">
      <h5 class="dialog__title dialog__title_md text-center mb-30">Copy this code to embed stream to your site</h5>
    </div>
    <div class="dialog__main mb-30">
        <div class="form-group">
          <div class="d-flex justify-content-between c-gap-10">
            <label for="embedded-video" class="form-label ">Code:</label>
            <label [ngClass]="copiedToClipBoard ? 'form-label-hint_showed' : ''" class="form-label form-label-hint pr-20 text-right t-color-primary fw-600">Copied to clipboard</label>
          </div>
          <textarea id="embedded-video" class="form-control form-control_size-sm " readonly="readonly" [(ngModel)]="data.embedCode"></textarea>
        </div>

      <div *ngIf="showPasswordInput" class="form-group">
        <div class="d-flex justify-content-between c-gap-10">
          <label for="player-password" class="form-label ">Player password:</label>
          <label [ngClass]="passwordCopiedToClipBoard ? 'form-label-hint_showed' : ''" class="form-label form-label-hint pr-20 text-right t-color-primary fw-600">Copied to clipboard</label>
        </div>
        <div class="input-container several-buttons">
          <input readonly type="text" id="player-password" [ngModel]="data.privateKey" class="form-control form-control_size-sm">

          <button type="button" (pointerdown)="copyPasswordToClipboard()" class="input-tool-btn input-tool-btn_size-sm"><i class="icon icon_sm icon_copy"></i></button>

          <!-- <button type="button" class="input-tool-btn input-tool-btn_size-sm"><i class="icon icon_sm icon_share"></i></button> -->
        </div>
      </div>
    </div>
    <div class="dialog__actions">
        <button class="btn btn_size-primary btn_outline btn_outline-main btn_fluid btn_rounded-md" (pointerdown)="copyToClipboard()">Copy</button>
        <button class="btn btn_size-primary btn_color-secondary btn_fluid btn_rounded-md" (click)="close()">Close</button>
    </div>
</div>
