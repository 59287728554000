import { Component, Output, Input, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestreamItem } from 'src/app/models/restream-item';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { StreamType } from 'src/app/models/enums/StreamType';
import { CustomSelectOption } from 'src/app/models/custom-select-option.model';

@Component({
  selector: 'app-facebook-settings',
  templateUrl: './facebook-settings.component.html',
  styleUrls: ['./facebook-settings.component.scss']
})
export class FacebookSettingsComponent implements OnInit {

  @Input() facebookConfiguration: RestreamItem = this.data.item;
  @Output() validateDestinations = new EventEmitter()
  @Output() updatedRestreamItem = new EventEmitter()

  constructor(
    private dialogRef: MatDialogRef<FacebookSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  public privacyModeList: Array<CustomSelectOption> = new Array<CustomSelectOption>();

  userGroups: any = [];
  errors: any = [];
  streamTypevalues: any = [];
  groupsToSearchEvent: any = [];
  streamToValue: any;
  groupWithEvents: any;
  error: any;
  searchTimeoutId: any;
  facebookTypeId: any = this.facebookConfiguration.facebookTypeId;
  eventGroupId: any = this.facebookConfiguration.eventGroupId;
  streamType: any = this.facebookConfiguration.streamType;

  ngOnInit(): void {
    this.privacyModeList.push({ name: "Everyone", value: "EVERYONE" }, { name: "Friends of friends", value: "FRIENDS_OF_FRIENDS" }, { name: "All friends", value: "ALL_FRIENDS" }, { name: "Self", value: "SELF" });

    this.validateItem();
    if (this.facebookConfiguration.streamType == StreamType.Pages) {
      this.setupStreamService.getFacebookUserAccounts(this.facebookConfiguration?.userProfileId).subscribe(x => {
        if (!x || x.length == 0) {
          this.error = "no data";
        }
        else if (x) {
          console.log(x);
          let data = [];
          if (!this.facebookConfiguration.facebookTypeId)
            data.push({ value: null, name: 'select page' });
          data = data.concat(x.map((val: any) => { return { value: val.id, name: val.name }; }));
          console.log(data);
          this.streamTypevalues = data;
          this.streamToValue = this.facebookConfiguration.facebookTypeId;
        }
        console.log(this.streamTypevalues);
      });
    }
    else if (this.facebookConfiguration.streamType == StreamType.Group) {
      this.setupStreamService.getFacebookUserGroups(this.facebookConfiguration?.userProfileId).subscribe(x => {
        this.streamTypevalues = [];
        if (!x || !x.data || x.data.length == 0) {
          this.error = "no data";
        }
        else if (x?.data) {
          let data = [];
          if (!this.facebookConfiguration.facebookTypeId)
            data.push({ value: null, name: 'select group' });
          data = data.concat(x.data.map((val: any) => { return { value: val.id, name: val.name }; }));
          this.streamTypevalues = data;
          console.log(data);
        }
        this.streamToValue = this.facebookConfiguration.facebookTypeId;
        console.log(this.streamTypevalues);
      });
    }
    else if (this.facebookConfiguration.streamType == StreamType.Event) {
      this.setupStreamService.getFacebookUserGroups(this.facebookConfiguration?.userProfileId).subscribe(x => {
        console.log(x);
        this.groupWithEvents = this.facebookConfiguration.eventGroupId;
        if (!x || !x.data || x.data.length == 0) {
          this.error = "no data";
        }
        else if (x) {
          let data = [];
          if (!this.facebookConfiguration.eventGroupId)
            data.push({ value: null, name: 'select group' });
          data = data.concat(x.data.map((val: any) => { return { value: val.id, name: val.name }; }));
          this.groupsToSearchEvent = data
          console.log(data);
          if (this.facebookConfiguration.eventGroupId) {
            this.setupStreamService.getFacebookGroupEvents(this.facebookConfiguration?.userProfile?.id, this.facebookConfiguration.eventGroupId).subscribe(y => {
              console.log(y);
              if (y.length > 0) {
                let data = [];
                if (!this.facebookConfiguration.facebookTypeId)
                  data.push({ value: null, name: 'select event' });
                data = data.concat(y.map((val: any) => { return { value: val.id, name: val.name }; }));
                console.log(data);
                this.streamTypevalues = data;
              }
              else {
                this.error = "no data";
              }
              this.streamToValue = this.facebookConfiguration.facebookTypeId;
            });
          }
        }
      });
    }

    this.userGroups.push({
      value: 0,
      name: "Self Page"
    },
      {
        value: 1,
        name: "Pages"
      },
      {
        value: 2,
        name: "Group"
      },
      {
        value: 3,
        name: "Event"
      });
  }
  validateItem() {
    if (!this.facebookConfiguration.streamName) {
      this.errors.push('stream title is required');
    }
    else if (this.facebookConfiguration.streamName.length >= 254) {
      this.errors.push('stream title max size is 254 chars');
    }

    if (this.facebookConfiguration.description)
      if (new Blob([this.facebookConfiguration.description]).size >= 5000) {
        this.errors.push('stream description max size is 5000 chars');
      }
  }

  saveConfigurationUpdate(): void {
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.errors = [];

      this.validateItem();

      this.setupStreamService.updateSettings(this.facebookConfiguration).subscribe({
        next: (data) => {
          this.validateDestinations.emit();
          this.dialogRef.close(false);
          console.log(data);
        },
        error: (error) => {
          console.log(error);

        }
      });
    }, 500);
  }

  changePrivacyStatus(selectedItem: any) {
    this.facebookConfiguration.privacyStatus = selectedItem.value;
  }

  onClose(): void {
    this.facebookConfiguration.facebookTypeId = this.facebookTypeId;
    this.facebookConfiguration.eventGroupId = this.eventGroupId;
    this.facebookConfiguration.streamType = this.streamType;
    this.dialogRef.close(false);
  }

  dropdownValueChanged(eventChange: any): void {
    this.streamTypevalues = [];
    this.groupsToSearchEvent = [];
    this.groupWithEvents = null;
    this.streamToValue = null;
    this.error = null;

    if (eventChange.value == StreamType.SelfPage) {
      this.facebookConfiguration.streamType = StreamType.SelfPage;
      // this.setupStreamService.changeFacebookStreamType(this.facebookConfiguration.id, { streamType: StreamType.SelfPage }).subscribe(x => {
      //   console.log(x);
      //   // this.validateDestinations.emit();
      // });
    } else if (eventChange.value == StreamType.Pages) {
      this.facebookConfiguration.streamType = StreamType.Pages;
      this.setupStreamService.getFacebookUserAccounts(this.facebookConfiguration?.userProfile?.id).subscribe(x => {
        console.log(x);
        console.log(this.streamTypevalues);
        if (x.length > 0) {
          let data = [];
          data.push({ value: null, name: 'select page' });
          data = data.concat(x.map((val: any) => { return { value: val.id, name: val.name }; }));
          console.log(data);
          this.streamTypevalues = data;
        }
        else {
          this.error = "no data";
        }
        console.log(this.streamTypevalues);
      });
    }
    else if (eventChange.value == StreamType.Group) {
      this.facebookConfiguration.streamType = StreamType.Group;

      this.setupStreamService.getFacebookUserGroups(this.facebookConfiguration?.userProfile?.id).subscribe(x => {
        console.log(x);
        if (x.data.length > 0) {
          let data = [];
          data.push({ value: null, name: 'select group' });
          data = data.concat(x.data.map((val: any) => { return { value: val.id, name: val.name }; }));
          console.log(data);
          this.streamTypevalues = data;
        }
        else {
          this.error = "no data";
        }
      });
    }
    else if (eventChange.value == StreamType.Event) {
      this.error = null;
      this.facebookConfiguration.streamType = StreamType.Event;

      this.setupStreamService.getFacebookUserGroups(this.facebookConfiguration?.userProfile?.id).subscribe(x => {
        console.log(x);
        if (x.data.length > 0) {
          let data = [];
          data.push({ value: null, name: 'select group' });
          data = data.concat(x.data.map((val: any) => { return { value: val.id, name: val.name }; }));
          console.log(data);
          this.groupsToSearchEvent = data;
        }
        else {
          this.error = "no data";
        }
      });
    }

    this.facebookConfiguration.facebookTypeId = undefined;
    this.facebookConfiguration.eventGroupId = undefined;
    // this.setupStreamService.changeFacebookStreamType(this.facebookConfiguration.id, { streamType: this.facebookConfiguration.streamType }).subscribe(x => {
    //   console.log(x);
    //   this.facebookConfiguration.facebookTypeId = undefined;
    //   this.facebookConfiguration.eventGroupId = undefined;
    // });
  }

  getEvents(eventChange: any): void {
    if (!eventChange.value) {
      return;
    }

    this.error = null;
    this.streamTypevalues = [];

    this.facebookConfiguration.eventGroupId = eventChange.value;
    // this.setupStreamService.changeFacebookStreamType(this.facebookConfiguration.id, { streamType: this.facebookConfiguration.streamType, eventGroupId: eventChange.value }).subscribe(x => {
    //   console.log(x);
    // });

    this.setupStreamService.getFacebookGroupEvents(this.facebookConfiguration?.userProfile?.id, eventChange.value).subscribe(x => {
      console.log(x);
      this.groupWithEvents = eventChange.value;
      if (x.length > 0) {
        let data = [];
        data.push({ value: null, name: 'select event' });
        data = data.concat(x.map((val: any) => { return { value: val.id, name: val.name }; }));
        console.log(data);
        this.streamTypevalues = data;
      }
      else {
        this.error = "no data";
      }

    },
    (error: any) => {
      console.log(error);

    });
  }

  streamTypeSelected(eventChange: any): void {
    if (!eventChange.value) {
      return;
    }
    this.facebookConfiguration.facebookTypeId = eventChange.value;
    this.facebookConfiguration.eventGroupId = this.groupWithEvents;

    // this.setupStreamService.changeFacebookStreamType(this.facebookConfiguration.id, { streamType: this.facebookConfiguration.streamType, facebookTypeId: eventChange.value, eventGroupId: this.groupWithEvents }).subscribe(x => {
    //   // this.validateDestinations.emit();
    // });
  }
}
