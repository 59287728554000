<div>
    <button class="btn_show-options flex-center-center rounded-circle" type="button" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <a (click)="redirectToControl()" mat-menu-item class="d-flex align-items-center c-gap-15">
            <img [src]="item.iconUrl" alt="">
            <span class="fw-600 ff-gilroy t-color-black">Open on {{item.title}}</span>
        </a>

        <button *ngIf="item.title === 'YouTube'" (click)="redirectToControl()" mat-menu-item class="d-flex align-items-center c-gap-15">
            <img src="assets/images/svg/destinations-options/control-room-icon.svg" alt="">
            <span class="fw-600 ff-gilroy t-color-black">Control Room</span>
        </button>

        <span class="divider"></span>
        <button (click)="stopRestreamItem()" mat-menu-item class="d-flex align-items-center c-gap-15">
            <i class="icon icon_sm icon_stop-stream rounded-circle"></i>
            <span class="fw-600 ff-gilroy error-text">Stop streaming here </span>
        </button>
    </mat-menu>
</div>