import { Injectable } from '@angular/core';
import { ChatMessageData } from '../models/chat';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_VERSION } from '../common/constants';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(data: ChatMessageData, vmIP: string): Observable<any> {
    const requestData = data.parentMessageId ?  data : { message: data.message, restreamItemIds: data.restreamItemIds };
    return this.http.post(`https://${vmIP}/api/${API_VERSION}/Transfer/message`, requestData);
  }

  getAllMessages(streamId: string, skip: number, take: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('streamInstance', streamId);
    params = params.append('skip', skip.toString());
    params = params.append('take', take);
    return this.http.get(`${environment.BLUSTER_API_URL}Restream/get-chat-messages`, {params});
  }
}
