import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CopyClipboardType } from 'src/app/models/enums/CopyClipboardType';
import { WoopraService } from 'src/app/services/woopra/woopra.service';
import { COPIED_VM_DETAILS } from 'src/app/common/constants/woopra';

@Component({
  selector: 'app-instance-info',
  templateUrl: './instance-info.component.html',
  styleUrls: ['./instance-info.component.scss']
})
export class InstanceInfoComponent implements OnInit {

  @Input() rtmpUrl: string = '';
  @Input() rtmpsUrl: string = '';
  @Input() streamKey: string = '';
  @Output() onToggleAccordion = new EventEmitter();

  public rtmpUrlCopied: boolean = false;
  public rtmpsUrlCopied: boolean = false;
  public sreamKeyCopied: boolean = false;
  public showStreamKey: boolean = false;

  constructor(private woopraService: WoopraService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  copyToClipboard(type: CopyClipboardType): void {
    let copyText = "";
    switch (type) {
      case CopyClipboardType.rtmpUrl:
        copyText = this.rtmpUrl;
        this.rtmpUrlCopied = true;
        setTimeout(() => {
          this.rtmpUrlCopied = false;
        }, 3000);
        break;
      case CopyClipboardType.rtmpsUrl:
        copyText = this.rtmpsUrl;
        this.rtmpsUrlCopied = true;
        setTimeout(() => {
          this.rtmpsUrlCopied = false;
        }, 3000);
        break;
      case CopyClipboardType.streamKey:
        copyText = this.streamKey;
        this.sreamKeyCopied = true;
        setTimeout(() => {
          this.sreamKeyCopied = false;
        }, 3000);
        break;
    }
    this.clipboard.copy(copyText);

    this.woopraService.trackInWoopra({
      actionName: COPIED_VM_DETAILS
    }).subscribe((x: any) => {});
  }

  showStresmKey(): void {
    this.showStreamKey = !this.showStreamKey;
  }

  toggleAccordion(event: any): void {
    this.onToggleAccordion.emit(event);
  }

}
