import { Component, OnInit } from '@angular/core';
import { WoopraService } from 'src/app/services/woopra/woopra.service';
import { environment } from 'src/environments/environment';
import { OPENED_PLANS_PAGE } from 'src/app/common/constants/woopra';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  environment: any = environment;
  tryFtree: any = window.location.origin;
  constructor(private woopraService: WoopraService) { }

  ngOnInit(): void {
    this.woopraService.trackInWoopra({
      actionName: OPENED_PLANS_PAGE
    }).subscribe((x: any) => {});
  }

}
