import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserPlayerSettings } from 'src/app/models/user-player-settings-model';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { generateRandomString } from '../../common/utils';
import {DebounceService} from '../../services/debounce.service';

@Component({
  selector: 'app-player-settings',
  templateUrl: './player-settings.component.html',
  styleUrls: ['./player-settings.component.scss']
})
export class PlayerSettingsComponent implements OnInit {

  @Input() streamInstaceId: number = this.data.streamInstaceId;
  @Input() playerSettings: UserPlayerSettings = this.data.playerSettings

  public passwordEnabled: boolean = false;
  public copiedToClipBoard:boolean = false;

  constructor(
    private dialogRef: MatDialogRef<PlayerSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private clipboard: Clipboard,
    private debounceService: DebounceService,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.passwordEnabled = this.playerSettings.isPrivateStream;
  }

  setPlayerSettings(): void {
    this.debounceService.handleButtonClick().subscribe(() => {
      this.setupStreamService.setUserPlayerSettings(this.streamInstaceId, this.playerSettings).subscribe({
        next: (data: UserPlayerSettings) => {
          this.passwordEnabled = data.isPrivateStream
          this.playerSettings = data;
          this.data.onSave();
          this.dialogRef.close(false);
        },
        error: (error) => {
          console.log(error);
        }
      });
    });
    this.debounceService.notifyButtonClick();
  }

  generatePassword(): void {
    if(this.playerSettings.isPrivateStream)
    {
      this.playerSettings.privateStreamKey = generateRandomString();
    }
    else{
      this.playerSettings.privateStreamKey = '';
    }
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  copyToClipboard():void {
    var copyText = this.playerSettings.privateStreamKey != undefined ? this.playerSettings.privateStreamKey : '';

    this.clipboard.copy(copyText);
    this.copiedToClipBoard = true;
    setTimeout(() => {
      this.copiedToClipBoard = false;
    }, 3000);
    //alert("Code has been copied.");
    // var copyText = this.playerSettings.privateStreamKey != undefined ? this.playerSettings.privateStreamKey : '';
    // navigator.clipboard.writeText(copyText).then(
    //   () => {
    //     alert("Code has been copied.");
    //   },
    //   () => {
    //     alert("Your browser doesn't support this functionality. Could you please copy manually.");
    //   }
    // );
  }
}
