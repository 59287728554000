import { Component, Input, OnInit } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() tooltipText: string = '';
  @Input() tooltipPosition: TooltipPosition = 'above';
  @Input() withIcon: boolean = true;

  constructor() { }

  ngOnInit(): void {}

}
