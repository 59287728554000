import { ConcatInstance } from "./concat-instance";
import { VmStatus } from "./enums/VmStatus";
import { RestreamItem } from "./restream-item";
import { UserPlayerSettings } from "./user-player-settings-model";

export class StreamInstance {
    constructor(
        public id: number = 0,
        public instanceStatus: VmStatus = VmStatus.NotSet,
        public streamName: string = "",
        public vmId?: string,
        public vmIP?: string,
        public vmDomain?: string,
        public vmDomainSsl?: string,
        public streamKey?: string,
        public concatInstance?: ConcatInstance,
        public userId?: number,
        public isFinished?: boolean,
        public isLive?: boolean,
        public startDate?: Date,
        public endDate?: Date,
        public videoCodec?: string,
        public videoFps?: number,
        public videoWidth?: number,
        public videoHeight?: number,
        public rtmpBytesIn?: number,
        public rtmpBytesOut?: number,
        public streamBytesIn?: number,
        public streamBytesOut?: number,
        public bwVideo?: number,
        public bwVideoCount?: number,
        public rtmpBwVideoIn?: number,
        public rtmpBwVideoInCount?: number,
        public rtmpBwVideoOut?: number,
        public rtmpBwVideoOutCount?: number,
        public streamBwVideoIn?: number,
        public streamBwVideoInCount?: number,
        public streamBwVideoOut?: number,
        public streamBwVideoOutCount?: number,
        public isPlayerSettingsEnabled?: boolean,
        public saveStreamToDownloads?: boolean,
        public streamSource:number = 0,
        public embeddedPlayerSettings: UserPlayerSettings = {
            allowFullScreenControl: false,
            allowPauseControl: false,
            allowProgressControl: false,
            allowVolumeControl: false,
            isPrivateStream: false,
            privateStreamKey: ""
        },
        public restreamItems: Array<RestreamItem> = []
    ) { }
}