
<div class="form-select p-relative" [ngClass]="{'active' : dropdownOpen}">
    <button
      class="form-select__selected-value d-flex justify-content-between align-items-center block-w-100"
      (click)="toggleDropdown()"
      [ngClass]="{'active' : dropdownOpen}"
    >
      <span class="text-overflow">{{currentValue?.name}}</span>
      <i class="icon icon_md arrow arrow-down arrow_color-primary-secondary flex-shrink-0"></i>
    </button>
    <ul role="listbox" aria-expanded="false" class="dropdown-list form-select__value-list custom-scroll custom-scroll_size-sm">
        <li *ngFor="let o of options" [ngClass]="{'active' : currentValue === o}"
        tabindex="0"
        (click)="select(o)"
        role="option"
        [innerHTML]="o?.name"></li>
    </ul>
</div>
