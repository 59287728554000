import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: any;
  public setNewPasswordForm: any;
  public showPassword: boolean = false;

  public emailIsSent: boolean = false;
  public code:any = "";
  public isCodeExpired:boolean = false;
  public successChangePasswordMessage: string = "";
  public emailResetPasswordError:string ="";

  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.code = params.get('code');
    });
    if(this.code){
      this.checkActualCode();
    }
    window.onunload = function () {
      if (opener && !opener.closed) {
        try {
          opener.location.reload(1);
        }
        catch (e) {
        }
        window.close();
      }
    }

    this.resetPasswordForm = this.formBuilder.group({
      login: ['', [Validators.required]]
    });

    this.setNewPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]]
      }
    );
  }

  saveNewPassword():void{


    this.subscriptions.add(this.authService.saveNewPassword(this.setNewPasswordForm.value.password, this.code).subscribe({
      next: (data) => {
        this.successChangePasswordMessage = "Password was updated successfully!"


        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      },
      error: (error) => {
        console.log(error);

      }
    }));
  }

  checkActualCode(){


    this.subscriptions.add(this.authService.isResetPasswordRequestActual(this.code).subscribe({
      next: (data) => {
        this.isCodeExpired = !data

      },
      error: (error) => {
        console.log(error);
        this.isCodeExpired = true;

      }
    }));
  }

  sendResetPasswordEmail(): void {


    this.subscriptions.add(this.authService.sendResetPasswordEmail(this.resetPasswordForm.value.login).subscribe({
      next: (data) => {
        this.emailIsSent = data;

        if(!data){
          this.emailResetPasswordError = "An error occured while sending e-mail. Please check entered data."
        }
      },
      error: (error) => {
        console.log(error);

      }
    }));
  }

  get efc() {
    return this.resetPasswordForm.controls;
  }

  get pasFormControls(){
    return this.setNewPasswordForm.controls;
  }

  showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
