<div class="accordion-component">
    <button (click)="toggleAccordion($event)" type="button"
        class="accordion-component__btn active d-flex align-items-center justify-content-between block-w-100">
        <span class="fw-600 text-md-2">Destinations</span>
        <i class="icon icon_md arrow arrow-right arrow-color-black"></i>
    </button>
    <div class="accordion-content mt-10 bg-table rounded-xl">
        <p class="fw-600 t-color-gray pb-5 pl-30 mt-10">Channel(s):</p>
        <ul class="channels-list">
            <li *ngFor="let item of channels"
                class="channels-list__item d-flex justify-content-between align-items-center pt-20 border-top">
                <app-channel [item]="item" [fps]="playerMetaData.fps"
                    [resolution]="playerMetaData.resolution"></app-channel>
                <div class="d-flex align-items-center">
                    <div style="color: green;" *ngIf="item?.isActive">
                        Active
                    </div>
                    <div style="color: red;" *ngIf="!item?.isActive">
                        Inactive
                    </div>
                    <app-channel-menu [style.visibility]="item.serviceConfigurationType == 3 || item.serviceConfigurationType == 5 ? 'hidden' : 'visible'" [item]="item" (onRedirectToControl)="redirectToControl($event)"
                        (onStopRestreamItem)="stopRestreamItem($event)" [streamInstance]="streamInstance"
                        [playerMetaData]="playerMetaData"></app-channel-menu>
                </div>
            </li>
        </ul>
    </div>
</div>