import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {
  @Input() item: any
  @Input() resolution: any
  @Input() fps: any

  constructor() { }

  ngOnInit(): void {}

}
