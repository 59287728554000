<div class="app d-flex flex-col bg-app-main">
  <app-sidebar></app-sidebar>
  <main class="d-flex flex-col container container_md3">
    <div class="stream-container d-flex flex-col flex-1 gap-30">
      <div class="stream-container-header">
        <h1 class="mb-10">Setup your stream</h1>
        <p class="text-md-2 mb-10">Choose your stream source:</p>
      </div>

      <div class="stream-container-content">
        <app-setup-source (validate)="validateStreamInstance()"
          (setupSource)="handleSetupSourceEvent($event)"></app-setup-source>
        <!-- text -->
        <div class="stream-to d-flex justify-content-center align-items-center">
          <span class="stream-to__arrows"></span>
          <h2>Stream to</h2>
          <span class="stream-to__arrows"></span>
        </div>
        <!-- destinations -->
        <app-destinations [validationDestinationErrors]="validationDestinationErrors" [streamInstance]="streamInstance"
          [isOpenedDestinationDropdown]="isOpenedDestinationDropdown"
          (onGetRestreamItemSettings)="getRestreamItemSettings($event)"
          (onSelectDestination)="mapDestinationToHandler($event)" (onDeleteRestreamItem)="deleteRestreamItem($event)"
          (onResetPlayerSettings)="resetPlayerSettings($event)"></app-destinations>
      </div>

      <div class="btn-wrapper d-flex justify-content-center">
        <a *ngIf="streamInstance.instanceStatus == 0" (click)="startStreamInstance()" [class.disabled]="isLinkDisabled"
          class="btn btn_color-secondary btn_size-primary btn_rounded-sm">Start Live Streaming</a>
      </div>
    </div>
  </main>
</div>


<!-- instance loader -->
<div *ngIf="showVmStatusPopup" class="stream-preview-loader overlay active flex-center-center">
  <div class="dialog-container">
    <div class="stream-preview-loader__content block-center">

      <div *ngIf="streamData.streamSource == 1" class="d-flex align-items-center c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="streamUploadStatus == 1" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="streamUploadStatus == 0" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Upload video</span>
      </div>

      <div class="d-flex align-items-center c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="initStreamStatus > 1" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="initStreamStatus < 2" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Create domain</span>
      </div>

      <div class="d-flex align-items-center  c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="initStreamStatus > 2" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="initStreamStatus < 3" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Create DNS record</span>
      </div>

      <div class="d-flex align-items-center c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="initStreamStatus > 3" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="initStreamStatus < 4" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Create Virtual Machine</span>
      </div>

      <div class="d-flex align-items-center c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="initStreamStatus > 4" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="initStreamStatus < 5" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Setup stream configuration</span>
      </div>

      <div class="d-flex align-items-center c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="initStreamStatus == 5" src="../../../assets/images/svg/check-icon.svg" alt="" width="24"
            height="24">
          <img *ngIf="initStreamStatus < 5" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24"
            height="24" class="stream-preview-loader__loader-element">
        </div>

        <span class="text-xl">Stream server is ready</span>
      </div>
    </div>

    <span>Starting instance may take one minute approximately.</span>
  </div>
</div>

<!-- create broadcasts loader -->
<div *ngIf="showCreateBroadcastStatusInfo" class="stream-preview-loader overlay active flex-center-center">
  <div class="dialog-container">
    <div class="stream-preview-loader__content block-center">
      <div *ngFor="let item of broadcastWithStatuses" class="d-flex align-items-center  c-gap-15 mb-35">
        <div class="flex-shrink-0">
          <img *ngIf="item.isCreated" src="../../../assets/images/svg/check-icon.svg" alt="" width="24" height="24">
          <img *ngIf="!item.isCreated" src="../../../assets/images/svg/loader-icon.svg" alt="" width="24" height="24"
            class="stream-preview-loader__loader-element">
        </div>

        <span style="overflow: auto;" class="text-xl">{{item.type}} <i style="font-size: 14px;color:#F32828"
            *ngIf="item.error">Error: {{item.error}}</i></span>
      </div>
      <span>Create broadcasts</span>
      <div *ngIf="broadcastErrors.length > 0">
        <i>Press "start anyway" and stream will be started only for success created broadcast.</i>
        <div style="display: flex;margin-top:10px;justify-content: space-between;">
          <button (click)="cancelCreateBroadcasts()"
            class="btn btn_color-main btn_size-primary btn_rounded-xs">Cancel</button>
          <button (click)="startVmAnyWay()" class="btn btn_color-secondary btn_size-primary btn_rounded-sm">Start VM
            anyway</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="overlay"></div>