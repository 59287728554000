export const environment = {
  staging: true,
  production: false,
  MA_API_URL: 'https://cinaio-stage.newlinetechno.net/api/',
  MA_URL: 'https://cinaio-stage.newlinetechno.net/#/',
  SSO_URL: 'https://sso.newlinetechno.net/',
  BLUSTER_API_URL: 'https://stage-blaster-api.newlinetechno.net/api/',
  CLOUDCORE_URL: 'https://cloud-core.newlinetechno.net/api/v1/',
  GOOGLEREDIRECTURL: 'https://stage-blaster.newlinetechno.net/setup-stream',
  GOOGLELOGINREDIRECTURL: 'https://stage-blaster.newlinetechno.net/login',
  GOOGLECLIENTID: '147850304865-5u5im9sqmc03p4grfm29m1hqu98prc5p.apps.googleusercontent.com',
  FACEBOOKREDIRECTURL: 'https://stage-blaster.newlinetechno.net/setup-stream/',
  FACEBOOKAUTHURL: 'https://stage-blaster.newlinetechno.net/login',
  FACEBOOKCLIENTID: '713316798851227',
  FACEBOOKGRAPHAPIURL: 'https://graph.facebook.com/',
  SIGNALR_URL: 'https://stage-blaster-api.newlinetechno.net/createVm',
  PLAYER_VIEWER_HUB: 'https://stage-blaster-api.newlinetechno.net/playerViewer',
  RESTREAM_ITEM_STATUS_HUB: 'https://stage-blaster-api.newlinetechno.net/restreamItemStatus',
  ROOT_SITE_URL: 'https://stage-blaster.newlinetechno.net',
  LINKEDIN_OAUTH_REDIRECT_URL: 'https://stage-blaster.newlinetechno.net/login',
  LINKEDIN_OAUTHCONNECT_REDIRECT_URL: 'https://stage-blaster.newlinetechno.net/setup-stream/',
  LINKEDIN_CLIENTID: '77l4m3ufvrz4it',
  TWITTER_CONSUMER_KEY: 'S3dybVFRd1g3dVA3T0R5Qk5DTDM6MTpjaQ',
  TWITTER_REDIRECT_URL: 'https://stage-blaster.newlinetechno.net/login',
  APPLE_CLIENTID: 'com.cinamaker.blaster',
  APPLE_REDIRECT_URL: 'https://stage-blaster.newlinetechno.net/login',
  ZOOM_REDIRECT_URL: 'https://stage-blaster.newlinetechno.net/login',
  TWITCH_CLIENT_ID: 'z5ofg7hjjudu0dtmn1bieob3klysie',
  TWITCH_REDIRECT_URI: 'https://stage-blaster.newlinetechno.net/setup-stream',
  RESET_PASSWORD_URL: 'https://cinaio-stage.newlinetechno.net/#/reset-password',
  BLASTER_ANUAL: 'https://wpdev05.newlinetechno.net/plans/?add-to-cart=18205',
  BLASTER_MOUNTHLY: 'https://wpdev05.newlinetechno.net/plans/?add-to-cart=18204'
};
