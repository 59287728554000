
  <div class="dialog dialog_manage-account">
    <h5 class="dialog__title text-center mb-30">Choose account</h5>

    <ul class="accounts-list mb-30">
      <li class="active d-flex align-items-center justify-content-between transition-300 rounded-md cursor-pointer">
        <figure class="account d-flex align-items-center c-gap-10">
          <span class="account-avatar account-avatar_size-xl flex-shrink-0 rounded-md">
            <img src="../../../assets/images/profile-image.jpg" alt="" width="46" height="46">
          </span>
          <figcaption>
            <span class="d-block text-md fw-600 ">Name</span>
            <a href="mailto:mail@example.com" class="d-block text-small t-color-gray-light">mail@example.com</a>
          </figcaption>
        </figure>

        <button class="accounts-list__delete-btn d-flex align-items-center justify-content-center rounded-circle" type="button"></button>
      </li>
      <li class="d-flex align-items-center justify-content-between transition-300 rounded-md cursor-pointer">
        <figure class="account d-flex align-items-center c-gap-10">
          <span class="account-avatar account-avatar_size-xl flex-shrink-0 rounded-md">
            <img src="../../../assets/images/profile-image.jpg" alt="" width="46" height="46">
          </span>
          <figcaption>
            <span class="d-block text-md fw-600 ">Name</span>
            <a href="mailto:mail@example.com" class="d-block text-small t-color-gray-light">mail@example.com</a>
          </figcaption>
        </figure>

        <button class="accounts-list__delete-btn d-flex align-items-center justify-content-center rounded-circle" type="button"></button>
      </li>
      <li class="d-flex align-items-center justify-content-between transition-300 rounded-md cursor-pointer">
        <figure class="account d-flex align-items-center c-gap-10">
          <span class="account-avatar account-avatar_size-xl flex-shrink-0 rounded-md">
            <img src="../../../assets/images/profile-image.jpg" alt="" width="46" height="46">
          </span>
          <figcaption>
            <span class="d-block text-md fw-600 ">Name</span>
            <a href="mailto:mail@example.com" class="d-block text-small t-color-gray-light">mail@example.com</a>
          </figcaption>
        </figure>

        <button class="accounts-list__delete-btn d-flex align-items-center justify-content-center rounded-circle" type="button"></button>
      </li>

    </ul>

    <div class="dialog__actions gap-10">
      <button class="btn btn_size-md btn_outline btn_outline-main btn_fluid btn_rounded-xs" type="button">Add Account</button>
      <button class="btn btn_size-md btn_color-secondary btn_fluid btn_rounded-xs" type="button">Use</button>
    </div>
  </div>

