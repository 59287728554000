import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoopraService {

  constructor(
    private http: HttpClient) { }

    trackInWoopra(model: any): Observable<any> {
      return this.http.post(environment.BLUSTER_API_URL + 'WoopraTracking', model);
    }
}
