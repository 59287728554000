import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { getSubDomainName } from 'src/app/common/utils';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['../auth.component.scss']
})
export class LogoutDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<LogoutDialogComponent>,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService
  ) { }

  private subscriptions = new Subscription();

  ngOnInit(): void {
  }

  confirm(): boolean {
    this.subscriptions.add(
      this.authService.logout().subscribe(res => {
        this.dialogRef.close();
        this.router.navigate(['/login']);
      },
        err => {
          this.dialogRef.close();
          this.router.navigate(['/login']);
        })
    )

    return true;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
