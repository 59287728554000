export class UserPlayerSettings{
    allowPauseControl?: boolean;
    allowProgressControl?: boolean;
    allowVolumeControl?: boolean;
    allowFullScreenControl?: boolean;
    privateStreamKey?: string;
    isPrivateStream:boolean = false;

    constructor(init?: Partial<UserPlayerSettings>) {
        Object.assign(this, init);
    }
}