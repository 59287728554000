import { Component, Output, Input, OnDestroy, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestreamItem } from 'src/app/models/restream-item';
import { UserProfile } from 'src/app/models/user-profile';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import { CustomSelectOption } from 'src/app/models/custom-select-option.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ManageAccountDialogComponent } from 'src/app/components/manage-account-dialog/manage-account-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-youtube-settings',
  templateUrl: './youtube-settings.component.html',
  styleUrls: ['./youtube-settings.component.scss']
})
export class YoutubeSettingsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  @Input() youtubeConfiguration: RestreamItem = this.data.item;
  @Output() validateDestinations = new EventEmitter()
  @Output() updatedRestreamItem = new EventEmitter()

  constructor(
    private dialogRef: MatDialogRef<YoutubeSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  errors: any = [];
  searchTimeoutId: any;
  public resolutionList: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  public latencyList: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  public frameRateList: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  public privacyModeList: Array<CustomSelectOption> = new Array<CustomSelectOption>();

  ngOnInit(): void {
    this.resolutionList.push({ name: "240p", value: "240p" }, { name: "360p", value: "360p" }, { name: "480p", value: "480p" },
      { name: "720p", value: "720p" }, { name: "1080p", value: "1080p" }, { name: "1440p", value: "1440p" }, { name: "2160p", value: "2160p" }, { name: "variable", value: "variable" });

    this.latencyList.push({ name: "normal", value: "normal" }, { name: "low", value: "low" }, { name: "ultraLow", value: "ultraLow" });
    this.frameRateList.push({ name: "30fps", value: "30fps" }, { name: "60fps", value: "60fps" }, { name: "variable", value: "variable" });
    this.privacyModeList.push({ name: "unlisted", value: "unlisted" }, { name: "private", value: "private" }, { name: "public", value: "public" });
    // this.validateItem();
  }

  validateItem(): void {
    this.errors = [];

    let resolution = Number(this.youtubeConfiguration.resolution!.replace('p', ''));
    if (this.youtubeConfiguration.frameRate != '30fps' && resolution < 720) {
      this.errors.push('choose 30fps or variable frame rate');
    }
    if (this.youtubeConfiguration.frameRate == 'variable' || this.youtubeConfiguration.resolution == 'variable') {
      if (this.youtubeConfiguration.frameRate != this.youtubeConfiguration.resolution) {
        this.errors.push('choose variable frame rate and variable resolution');
      }
    }

    if (!this.youtubeConfiguration.streamName) {
      this.errors.push('stream title is required');
    }
    else if (this.youtubeConfiguration.streamName.length >= 100) {
      this.errors.push('stream title max size is 100 chars');
    }

    if (this.youtubeConfiguration.description)
      if (new Blob([this.youtubeConfiguration.description]).size >= 5000) {
        this.errors.push('stream description max size is 5000 chars');
      }

    if (this.youtubeConfiguration.frameRate == '30fps' && resolution > 1080) {
      this.errors.push('choose 60fps or variable frame rate');
    }

    if (this.youtubeConfiguration.latencyPreference != 'ultraLow' && resolution > 1080) {
      this.errors.push('choose UltraLow latency for current frame rate');
    }
  }

  saveConfigurationUpdate(): void {
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.validateItem();

      this.subscriptions.add(
        this.setupStreamService.updateSettings(this.youtubeConfiguration).subscribe({
          next: (data) => {
             this.validateDestinations.emit();
            this.dialogRef.close(false);
          },
          error: (error) => {
            console.log(error);
          }
        })
      );
    }, 500);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  changeResolution(selectedItem: any) {
    this.youtubeConfiguration.resolution = selectedItem.value;
  }

  changeFrameRate(selectedItem: any) {
    this.youtubeConfiguration.frameRate = selectedItem.value;
  }

  changePrivacyStatus(selectedItem: any) {
    this.youtubeConfiguration.privacyStatus = selectedItem.value;
  }

  changeLatency(selectedItem: any) {
    this.youtubeConfiguration.latencyPreference = selectedItem.value;
  }

  openManageAccountDialig() {
    this.dialog.open(ManageAccountDialogComponent, {
      maxWidth: '430px',
      width: 'calc(100% - 40px)',
      data: {}
    });
  }

  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }
}
