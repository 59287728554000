import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StreamInstance } from 'src/app/models/stream-instance';
import { environment } from 'src/environments/environment';
import { ProfileType } from 'src/app/models/enums/ProfileType';
import { RestreamItem } from 'src/app/models/restream-item';
import { UserPlayerSettings } from 'src/app/models/user-player-settings-model';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SetupStreamService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getStreamById(id: any): Observable<StreamInstance> {
    return this.http.get<StreamInstance>(environment.BLUSTER_API_URL + 'Restream/instance/' + id);
  }

    public updateStreamById(id: any, data: any): Observable<StreamInstance> {
    return this.http.put<StreamInstance>(environment.BLUSTER_API_URL + 'Restream/streamInstance/' + id + '/update', data);
  }


  public getRestreamItemsByInstanceId(streamInstanceId: any): Observable<Array<RestreamItem>> {
    return this.http.get<Array<RestreamItem>>(environment.BLUSTER_API_URL + 'Restream/instance/' + streamInstanceId + '/restreamItems');
  }

  public addDestination(profileType: ProfileType, instanceId: any, profileId: number): Observable<any> {
    if (instanceId) {
      instanceId = parseInt(instanceId);
    }
    return this.http.post(environment.BLUSTER_API_URL + 'Restream/add-destination', {
      'ProfileType': profileType,
      'StreamInstanceId': instanceId,
      'UserProfileId': profileId
    });
  }

  public addCustomRtmp(streamInstanceId: number, isSecure: boolean): Observable<RestreamItem> {
    return this.http.post<RestreamItem>(environment.BLUSTER_API_URL + 'Restream/instance/' + streamInstanceId + '/custom-rtmp/add?isSecure=' + isSecure, null);
  }

  public removeRestreamItem(restreamItemId: number): Observable<any> {
    return this.http.delete(environment.BLUSTER_API_URL + 'Restream/restreamItem/' + restreamItemId + '/remove')
  }

  public resetPlayerSettings(streamInstanceId: number): Observable<any> {
    return this.http.put<UserPlayerSettings>(environment.BLUSTER_API_URL + 'Restream/instance/' + streamInstanceId + '/reset-player-settings', {});
  }

  public setUserPlayerSettings(streamInstanceId: number, data: UserPlayerSettings): Observable<UserPlayerSettings> {
    return this.http.put<UserPlayerSettings>(environment.BLUSTER_API_URL + 'Restream/instance/' + streamInstanceId + '/set-player-settings', data);
  }

  public getFacebookUserGroups(profileId: any): Observable<any> {
    return this.http.get<any>(environment.BLUSTER_API_URL + 'restream/userProfile/' + profileId + '/facebook-groups');
  }

  public getFacebookUserAccounts(profileId: any): Observable<any> {
    return this.http.get<any>(environment.BLUSTER_API_URL + 'restream/userProfile/' + profileId + '/facebook-accounts');
  }

  public getFacebookGroupEvents(profileId: any, groupId: any): Observable<any> {
    return this.http.get<any>(environment.BLUSTER_API_URL + `restream/userProfile/${profileId}/group/${groupId}/facebook-events`);
  }

  public changeFacebookStreamType(restreamItemId: any, model: any): Observable<any> {
    return this.http.put<any>(environment.BLUSTER_API_URL + 'restream/restreamItem/' + restreamItemId + '/change-fb-streamType', model);
  }

  public updateSettings(model: any): Observable<any> {
    return this.http.put<boolean>(environment.BLUSTER_API_URL + 'Restream/restreamItem/' + model.id + '/update-configuration', model);
  }

  getTwitchGames(restreamItemId: number): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Restream/get-twitch-games?restreamItemId=${restreamItemId}`);
  }

  getTwitchInfo(restreamItemId: number): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Restream/get-twitch-info?restreamItemId=${restreamItemId}`);
  }

  getTwitchUser(restreamItemId: number): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Restream/get-twitch-user?restreamItemId=${restreamItemId}`);
  }

  updateTwitchStream(updateModel: any): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Restream/update-twitch-info', updateModel);
  }

  public updateCustomRtmpSettings(model: any): Observable<RestreamItem> {
    if (model.serviceConfigurationType == 5) {
      //rtmps
      return this.http.put<RestreamItem>(environment.BLUSTER_API_URL + 'Restream/custom-rtmps/update', model);
    } else {
      //rtmp
      return this.http.put<RestreamItem>(environment.BLUSTER_API_URL + 'Restream/custom-rtmp/update', model);
    }
  }

  public validteRTMP(rtmpUrl: string): Observable<any> {
      return this.http.get<any>(environment.BLUSTER_API_URL + `Restream/custom-rtmp/validate?rTMPUrl=${rtmpUrl}`);
  }

  public stopVmInstance(instanceId: number, fosrceStop: boolean){
    return this.http.post(environment.BLUSTER_API_URL + 'Restream/StopStreamInstance/'+instanceId, {
      "streamInstanceId": instanceId,
      "ForceStop":fosrceStop
    });
  }

  public stopRestreamItem(restreamId: number, instanceId: number){
    return this.http.put(environment.BLUSTER_API_URL + `Restream/instance/${instanceId}/stopRestreamItem/${restreamId}`, {

    });
  }

  public createBroadcast(restreamItemIds:Array<number>, instanceId:number):Observable<Array<RestreamItem>>{
    return this.http.post<Array<RestreamItem>>(environment.BLUSTER_API_URL + `Restream/instance/${instanceId}/create-broadcasts`, restreamItemIds);
  }

  public createBroadcastItem(restreamItemId:number, instanceId:number):Observable<RestreamItem>{
    return this.http.post<RestreamItem>(environment.BLUSTER_API_URL + `Restream/instance/${instanceId}/create-broadcast/${restreamItemId}`, {});
  }

  public startVm(instanceId:number):Observable<RestreamItem>{
    return this.http.post<any>(environment.BLUSTER_API_URL + `Restream/streamInstance/${instanceId}/startVm`, {});
  }
}
