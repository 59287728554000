import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})
export class CustomSelectComponent implements OnInit {

    @Input() options: any;
    @Input() title: string = "";
    @Input() selectedElementValue: any;
    @Output() currentValueChange = new EventEmitter();

    public currentValue:any;
    public dropdownOpen: boolean = false;
    public get dropdownElement(): Element {return this.elem.nativeElement.querySelector('.dropdown-list')}

    private currentIndex = -1;

    constructor(
        private elem: ElementRef
    ) { }

    ngOnInit(): void {
        var self = this;
        window.onclick = function(event:any) {
            if (!event.target.matches('.dropdown-list') && !event.target.matches('.form-select__selected-value') && !event.target.matches('.arrow-down')) {
                self.closeOpenedSelects();
            }
        }
        this.currentValue = this.selectedElementValue ? this.options.find((e:any) => e.value == this.selectedElementValue) : this.options[0];
    }

    handleKeyboardEvents($event: KeyboardEvent) {
        if (this.dropdownOpen) {
            $event.preventDefault();
        } else {
            return;
        }
        if ($event.code === 'ArrowUp') {
            if (this.currentIndex < 0) {
                this.currentIndex = 0;
            } else if (this.currentIndex > 0) {
                this.currentIndex--;
            }
            this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
        } else if ($event.code === 'ArrowDown') {
            if (this.currentIndex < 0) {
                this.currentIndex = 0;
            } else if (this.currentIndex < this.options.length-1) {
                this.currentIndex++;
            }
            this.elem.nativeElement.querySelectorAll('li').item(this.currentIndex).focus();
        } else if (($event.code === 'Enter' || $event.code === 'NumpadEnter') && this.currentIndex >= 0) {
            this.selectByIndex(this.currentIndex);
        } else if ($event.code === 'Escape') {
            this.closeDropdown();
        }
    }

    closeDropdown() {
        this.dropdownElement.setAttribute('aria-expanded', "false");
        this.currentIndex = -1;
        this.dropdownOpen = false;
    }

    selectByIndex(i: number) {
        let value = this.options[i];
        this.select(value);
    }

    select(value:any) {
        this.currentValue = value;
        this.closeDropdown();
        this.currentValueChange.emit(this.currentValue);
    }

    toggleDropdown() {
        this.closeOpenedSelects();
        this.dropdownOpen = !this.dropdownOpen;
        this.dropdownElement.setAttribute('aria-expanded', this.dropdownOpen ? "true" : "false");
    }

    closeOpenedSelects(){
        var dropdowns = document.getElementsByClassName("dropdown-list");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.getAttribute("aria-expanded") == "true") {
                //openDropdown.setAttribute("aria-expanded", "false");
                var button =  <HTMLScriptElement>openDropdown.previousElementSibling;
                button.click();
            }
        }
    }
}
