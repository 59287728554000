import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  error: string = '';
  errorStringify: string = "";
  redirectUrl: string = "";
  errorType: string = "string";
  siteUrl: string = environment.ROOT_SITE_URL;

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // this.error = JSON.stringify(data.error);
    this.error = data.error;
    this.errorStringify = JSON.stringify(data.error);

    if (typeof data.error == "object") {
      this.errorType = "object";
    }
    console.log("ErrorType:" + typeof data.error);
    this.redirectUrl = data.redirectUrl ? data.redirectUrl : "";
  }

  ngOnInit(): void {
  }

  onClose(href: string = ''): void {
    if (href) {
      let accessToken = this.cookieService.get('AccessToken');
      const parsedToken = this.authService.parseAccessToken(accessToken!);

      if (parsedToken.redirectUrl) {
        window.open(href, '_self');
        return;
      }
      else {
        window.open(href, '_blank');
      }
    }

    this.dialogRef.close(false);
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    }
    if (this.error == "FacebookDuplicate" || this.error == "GoogleDuplicate" || this.error == "TwitchDuplicate") {
      window.close();
    }
  }
}
