import { ChatDestination, ChatDestinationControlName } from "../models/chat";
import { Destination } from "../models/destination";
import { ServiceConfigurationType } from "../models/enums/ServiceConfigurationType";
import { RestreamItem } from "../models/restream-item";
import { DESTINATIONS, DESTINATIONS_OPTIONS } from "./constants/destinations";

export const getSubDomainName = (): any => {
  let subdomain;
  const domainParts = location.hostname.split('.');

  if (domainParts.length > 2) {
    subdomain = `.${domainParts[domainParts.length - 2]}.${domainParts[domainParts.length - 1]}`;
  } else {
    subdomain = location.hostname;
  }
  console.log(subdomain, "subdomain");
  return subdomain;
};

export const getFingerPrint = (): any => {
  const windowObj: any = window;
  const navigatorObj: Navigator = windowObj.navigator;
  const screenObj: Screen = windowObj.screen;

  function checksum(str: string): number {
    let hash = 5381;
    let i = str.length;

    while (i--) {
      hash = (hash * 33) ^ str.charCodeAt(i);
    }

    return hash >>> 0;
  }

  function map(arr: any[], fn: (item: any) => any): any[] {
    let i = 0;
    const len = arr.length;
    const ret = [];

    while (i < len) {
      ret[i] = fn(arr[i++]);
    }

    return ret;
  }

  return checksum(
    [
      navigatorObj.userAgent,
      [screenObj.height, screenObj.width, screenObj.colorDepth].join('x'),
      new Date().getTimezoneOffset(),
      !!windowObj.sessionStorage,
      !!windowObj.localStorage,
      map(Array.from(navigatorObj.plugins), (plugin: Plugin) => {
        return [
          plugin.name,
          plugin.description,
          map(Array.from(plugin), (mime: MimeType) => {
            return [mime.type, mime.suffixes].join('~');
          }).join(',')
        ].join('::');
      }).join(';')
    ].join('###')
  ).toString();
};

export const generateRandomString = (): any => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const getUrlByDestination = (destination: ChatDestinationControlName, restreamItemId: number) => {
  console.log(destination, "destination");
  switch (destination) {
    case 'twitterMessage':
      return `twitter/${restreamItemId}`;
    case 'twitchMessage':
      return `twitch/${restreamItemId}`;
    case 'linkedInMessage':
      return `linkedIn/${restreamItemId}`;
    case 'youtubeMessage':
      return `youTube/${restreamItemId}`;
    default:
      return '';
  }
}

export const mapRestreamItemToChatDestination = (restreamItems: RestreamItem[], destinations: ChatDestination[], withNoAbilityToSend: boolean = false): any => {
  return restreamItems.map((item) => {
    if (!withNoAbilityToSend && item.restreamItemAbility?.sendChatMessages === false) return {};
    switch (item.serviceConfigurationType) {
      case ServiceConfigurationType.YouTube:
        return {
          ...destinations.find((destination: ChatDestination) => destination.formControlName === 'youtubeMessage'),
          id: item.id,
          streamName: item.streamName
        };
      case ServiceConfigurationType.Twitch:
        return {
          ...destinations.find((destination: ChatDestination) => destination.formControlName === 'twitchMessage'),
          id: item.id,
          streamName: item.streamName
        };
      case ServiceConfigurationType.LinkedIn:
        return {
          ...destinations.find((destination: ChatDestination) => destination.formControlName === 'linkedInMessage'),
          id: item.id,
          streamName: item.streamName
        };
      case ServiceConfigurationType.Facebook:
        return {
          ...destinations.find((destination: ChatDestination) => destination.formControlName === 'facebookMessage'),
          id: item.id,
          streamName: item.streamName
        };
      default:
        return {};
    }
  });
}

export const mapRestreamItemToChannels = (restreamItems: RestreamItem[]): any => {
  const channels = DESTINATIONS_OPTIONS;

  return restreamItems.map((item) => {
    switch (item.serviceConfigurationType) {
      case ServiceConfigurationType.YouTube:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.YouTube),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
          profileIconUrl: item.userProfile?.pictureUrl
        };
      case ServiceConfigurationType.Twitch:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.Twitch),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
          profileIconUrl: item.userProfile?.pictureUrl
        };
      case ServiceConfigurationType.LinkedIn:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.LinkedIn),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
          profileIconUrl: item.userProfile?.pictureUrl
        };
      case ServiceConfigurationType.Facebook:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.Facebook),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
          profileIconUrl: item.userProfile?.pictureUrl
        };
      case ServiceConfigurationType.CustomRTMP:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.CustomRTMP),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
        };
        case ServiceConfigurationType.CustomRTMPS:
        return {
          ...channels.find((destination: Destination) => destination.serviceConfigurationType === ServiceConfigurationType.CustomRTMPS),
          id: item.id,
          streamName: item.streamName,
          isActive: item.isActive,
        };
      default:
        return {};
    }
  });
};

export const filterUniqueObjects = (array: any[], key: string): any[] => {
  const seen = new Set();
  return array.filter(item => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}
