<div class="accordion-component mb-20">
  <button type="button" (click)="toggleAccordion($event)"
    class="accordion-component__btn active d-flex align-items-center justify-content-between block-w-100">
    <span class="fw-600 text-md-2">Instance info</span>
    <i class="icon icon_md arrow arrow-right arrow-color-black"></i>
  </button>
  <!-- accordion-content -->
  <div class="accordion-content mt-10 bg-table rounded-xl">

    <div class="form-group">
      <div class="d-flex justify-content-between c-gap-10">
        <label for="rtmpUrl" class="form-label form-label_sm">RTMP URL:</label>
        <label [ngClass]="rtmpUrlCopied ? 'form-label-hint_showed' : ''"
          class="form-label form-label-hint pr-15 text-small text-right t-color-primary fw-600">Copied to
          clipboard</label>
      </div>

      <div class="input-container">
        <input readonly [(ngModel)]="rtmpUrl" id="rtmpUrl"
          class="form-control form-control_size-sm form-control_bg-white" type="url">
        <button type="button" class="input-tool-btn input-tool-btn_size-sm" (pointerdown)="copyToClipboard(0)">
          <i class="icon icon_sm icon_copy"></i>
        </button>
      </div>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between c-gap-10">
        <label for="rtmpsUrl" class="form-label form-label_sm">RTMPs URL:</label>
        <label [ngClass]="rtmpsUrlCopied ? 'form-label-hint_showed' : ''"
          class="form-label form-label-hint pr-15 text-small text-right t-color-primary fw-600">Copied to
          clipboard</label>
      </div>

      <div class="input-container">
        <input id="rtmpsUrl" readonly [(ngModel)]="rtmpsUrl"
          class="form-control form-control_size-sm form-control_bg-white" type="url">
        <button type="button" class="input-tool-btn input-tool-btn_size-sm"  (pointerdown)="copyToClipboard(1)">
          <i class="icon icon_sm icon_copy"></i>
        </button>
      </div>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between c-gap-10">
        <label for="streamKey" class="form-label form-label_sm">Stream Key:</label>
        <label [ngClass]="sreamKeyCopied ? 'form-label-hint_showed' : ''"
          class="form-label form-label-hint pr-15 text-small text-right t-color-primary fw-600">Copied to
          clipboard</label>
      </div>

      <div class="input-container several-buttons">
        <input id="streamKey" readonly [(ngModel)]="streamKey"
          class="form-control form-control_size-sm form-control_bg-white" [type]="showStreamKey ? 'text' : 'password'">
        <button type="button" class="input-tool-btn input-tool-btn_size-sm" (click)="showStresmKey()"
          [ngClass]="showStreamKey ? 'active' : ''">
          <i class="icon icon_sm icon-pass-off icon_pass-off_secondary"></i>
        </button>
    
        <button type="button" class="input-tool-btn input-tool-btn_size-sm" (click)="copyToClipboard(2)">
          <i class="icon icon_sm icon_copy"></i>
        </button>
      </div>
    </div>

  </div>
</div>