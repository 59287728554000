import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-get-embedded-video-dialog',
  templateUrl: './get-embedded-video-dialog.component.html',
  styleUrls: ['./get-embedded-video-dialog.component.scss']
})
export class GetEmbeddedVideoDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<GetEmbeddedVideoDialogComponent>,
    private clipboard: Clipboard, @Inject(MAT_DIALOG_DATA) public data: any) { }

  public privateStreamKey: string = '';
  showPasswordInput: boolean = false;
  public copiedToClipBoard:boolean = false;
  public passwordCopiedToClipBoard: boolean = false;

  ngOnInit(): void {
    //this.privateStreamKey = localStorage.getItem("privateKey") as string;

    if (this.data.privateKey)
      this.showPasswordInput = true;
  }

  close(): void {
    console.log(this.dialogRef);
    this.dialogRef.close();
  }

  copyToClipboard(): void {
    var copyText = this.data.embedCode;

    this.clipboard.copy(copyText);

    this.copiedToClipBoard = true;
    setTimeout(() => {
      this.copiedToClipBoard = false;
    }, 3000);
    //alert("Code has been copied.");
    // navigator.clipboard.writeText(copyText).then(
    //   () => {
    //     alert("Code has been copied.");
    //   },
    //   () => {
    //     alert("Your browser doesn't support this functionality. Could you please copy manually.");
    //   }
    // );
  }

  copyPasswordToClipboard(): void {
    this.clipboard.copy(this.data.privateKey);

    this.passwordCopiedToClipBoard = true;
    setTimeout(() => {
      this.passwordCopiedToClipBoard = false;
    }, 3000);

    //alert("Code has been copied.");
    // navigator.clipboard.writeText(this.data.privateKey).then(
    //   () => {
    //     alert("Code has been copied.");
    //   },
    //   () => {
    //     alert("Your browser doesn't support this functionality. Could you please copy manually.");
    //   }
    // );
  }
}
