<div class="stream-preview-info p-absolute rounded-md p-block-10 transition-300">
    <table class="t-color-white">
        <caption class="stream-preview-info__title p-inline-10 pb-10 fw-600">
            Incoming stream info
        </caption>

        <tbody class="text-small">
            <tr>
                <th scope="row" class="pl-10 fw-400 text-right">FPS:</th>
                <td class="p-inline-15 fw-400 text-center">-</td>
                <td class="pr-10">{{playerMetaData.fps}}</td>
            </tr>

            <tr>
                <th scope="row" class="pl-10 fw-400 text-right">Codec:</th>
                <td class="p-inline-15 fw-400 text-center">-</td>
                <td class="pr-10">{{playerMetaData.codec}}</td>
            </tr>

            <tr>
                <th scope="row" class="pl-10 fw-400 text-right">Resolution:</th>
                <td class="p-inline-15 fw-400 text-center">-</td>
                <td class="pr-10">{{playerMetaData.resolution}}</td>
            </tr>

            <tr>
                <th scope="row" class="pl-10 fw-400 text-right">Duration:</th>
                <td class="p-inline-15 fw-400 text-center">-</td>
                <td class="pr-10">{{playerMetaData.streamDuration | date:'HH:mm'}}</td>
            </tr>

            <tr>
                <th scope="row" class="pl-10 fw-400 text-right">Started at:</th>
                <td class="p-inline-15 fw-400 text-center">-</td>
                <td class="pr-10">{{playerMetaData.startedAt | date:'HH:mm:ss'}}</td>
            </tr>
        </tbody>
    </table>

</div>