<div class="dialog dialog-confirm">
    <div class="dialog__title">
        {{message}}
    </div>
    <div class="dialog__title">
        {{question}}
    </div>
    <div class="dialog__actions">
        <button class="btn btn--main" (click)="onClose()">{{noButtonText}}</button>
        <button class="btn btn--secondary" (click)="onConfirm()">{{yesButtonText}}</button>
    </div>
</div>