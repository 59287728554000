import { switchMap, catchError, map, subscribeOn } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { getSubDomainName } from 'src/app/common/utils';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  public loginForm: any = {

  };

  public loginErrorMessage: string = "";
  public showPassword: boolean = false;
  public showTwitterPopupInterval: any = null;
  public currentYear: number = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    try {
      const channel = new BroadcastChannel('app-data');
      channel.addEventListener('message', (event) => {
        if (event.data == 'loginFailed') {
          this.loginErrorMessage = localStorage.getItem('failedLogin') ?? '';
          localStorage.removeItem('failedLogin')
        }
        if (event.data == 'loginSuccess') {
          this.router.navigate(['/recent-streams']);
        }
      });
    }
    catch {
      //do some alternative here...
    }

    this.currentYear = moment().year();

    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    let socialNetwork = localStorage.getItem('socialNetwork');
    localStorage.removeItem('socialNetwork');

    localStorage.removeItem("twitterSuccess");

    if (socialNetwork == 'apple') {
      this.route.fragment.subscribe((value: string) => {
        let fragments: any = value.split('&')

        fragments.forEach((x: any) => {
          let y: any = x.split('=')
          fragments[y[0]] = y[1]
        });

        this.authService.loginFromApple(fragments.id_token).pipe(
          switchMap(data => {
            this.cookieService.set('AccessToken', data.Result.AccessToken, 2, '/', getSubDomainName(), true, 'None');
            this.cookieService.set('RefreshToken', data.Result.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
            return this.authService.createBlusterUser(true).pipe(
              map(() => {
                const channel = new BroadcastChannel('app-data');
                channel.postMessage('loginSuccess');
                window.close();
              })
            )
          }),
          catchError(() => {
            localStorage.setItem('failedLogin', 'Failed logging.');
            const channel = new BroadcastChannel('app-data');
            channel.postMessage('loginFailed');
            this.loginErrorMessage = 'Failed logging.';
            window.close();
            return of({});
          })
        )
          .subscribe();
      });
    }
    else if (socialNetwork == 'facebook') {
      console.log(true);
      var facebookFragmentUrl = this.route.snapshot.fragment;
      if (facebookFragmentUrl != "_=_" && facebookFragmentUrl != null) {
        var splitedDragments = facebookFragmentUrl.split('&');
        var routeModel: any = [];
        splitedDragments.forEach(x => { routeModel.push(x.split("=")) });
        var requestModel: any = this.fromEntries(routeModel);

        this.authService.facebookMe(requestModel.access_token).subscribe((res) => {
          let model = {
            email: res.email,
            accessToken: requestModel.access_token,
            remoteUserId: res.id,
            authType: 1
          }
          this.authService.loginFromSocialNetwork(model).pipe(
            switchMap(data => {
              this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
              this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
              return this.authService.createBlusterUser(true).pipe(
                map(() => {
                  const channel = new BroadcastChannel('app-data');
                  channel.postMessage('loginSuccess');
                  window.close();
                })
              )
            }),
            catchError(() => {
              this.loginErrorMessage = 'Failed logging.'
              localStorage.setItem('failedLogin', 'Failed logging.');
              const channel = new BroadcastChannel('app-data');
              channel.postMessage('loginFailed');
              window.close();
              return of({});
            })
          )
            .subscribe();
        },
          () => {
            this.loginErrorMessage = 'Failed logging.';
            localStorage.setItem('failedLogin', 'Failed logging.');
            const channel = new BroadcastChannel('app-data');
            channel.postMessage('loginFailed');
            window.close();
          });
      }
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          console.log(params, 'params');

          if (socialNetwork == 'linkedIn' && params.code) {
            this.authService.getLinkedInAccessToken(params.code, environment.LINKEDIN_OAUTH_REDIRECT_URL).subscribe((res => {
              if (res) {
                this.authService.signInWithLinkedIn(res.accessToken).pipe(
                  switchMap(data => {
                    this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
                    this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
                    return this.authService.createBlusterUser(true).pipe(
                      map(() => {
                        const channel = new BroadcastChannel('app-data');
                        channel.postMessage('loginSuccess');
                        window.close();
                      })
                    )
                  }),
                  catchError(() => {
                    this.loginErrorMessage = 'Failed logging.';
                    localStorage.setItem('failedLogin', 'Failed logging.');
                    const channel = new BroadcastChannel('app-data');
                    channel.postMessage('loginFailed');
                    window.close();
                    return of({});
                  })
                )
                  .subscribe();
              }
            }))
          }
          else if (socialNetwork == 'google' && params.code) {
            this.authService.googleMe(params.code).subscribe((res: any) => {
              let model = {
                email: res.email,
                accessToken: res.access_token,
                remoteUserId: res.sub,
                authType: 2
              }
              this.authService.loginFromSocialNetwork(model).pipe(
                switchMap(data => {
                  this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
                  this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
                  return this.authService.createBlusterUser(true).pipe(
                    map(() => {
                      const channel = new BroadcastChannel('app-data');
                      channel.postMessage('loginSuccess');
                      window.close();
                    })
                  )
                }),
                catchError(() => {
                  this.loginErrorMessage = 'Failed logging.'
                  localStorage.setItem('failedLogin', 'Failed logging.');
                  const channel = new BroadcastChannel('app-data');
                  channel.postMessage('loginFailed');
                  window.close();
                  return of({});
                })
              )
                .subscribe();
            });
          }
          else if (socialNetwork == 'twitter' && params.code) {
            this.authService.loginFromTwitter(params.code).pipe(
              switchMap(data => {
                this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
                this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
                return this.authService.createBlusterUser(true).pipe(
                  map(() => {
                    localStorage.setItem("twitterSuccess", "true");
                    const channel = new BroadcastChannel('app-data');
                    channel.postMessage('loginSuccess');
                    window.close();
                  })
                )
              }),
              catchError(() => {
                localStorage.setItem('failedLogin', 'Failed logging.');
                const channel = new BroadcastChannel('app-data');
                channel.postMessage('loginFailed');
                clearInterval(this.showTwitterPopupInterval);
                this.loginErrorMessage = 'Failed logging.';
                window.close();
                return of({});
              })
            )
              .subscribe();
          }
          else if (socialNetwork == 'zoom' && params.result && params.AccessToken && params.RefreshToken) {
            if (params.result != 'Success') {
              console.log(params.description);
            }
            this.cookieService.set('AccessToken', params.AccessToken, 2, '/', getSubDomainName(), true, 'None');
            this.cookieService.set('RefreshToken', params.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
            this.authService.createBlusterUser(true).subscribe(() => {
              const channel = new BroadcastChannel('app-data');
              channel.postMessage('loginSuccess');
              window.close();
            },
              err => {
                this.loginErrorMessage = 'Failed logging.'
                localStorage.setItem('failedLogin', 'Failed logging.');
                const channel = new BroadcastChannel('app-data');
                channel.postMessage('loginFailed');
                window.close();
              });
          }
        });
    }
  }

  resetPassword(): void {
    //window.open(`${environment.RESET_PASSWORD_URL}`, '_blank');
    this.router.navigate(['/reset-password']);
  }

  get efc() {
    return this.loginForm.controls;
  }

  public login(): void {
    this.spinner.show();
    this.authService.login(this.loginForm.value)
      .pipe(
        switchMap(data => {
          this.cookieService.set('AccessToken', data.accessToken, 2, '/', getSubDomainName(), true, 'None');
          this.cookieService.set('RefreshToken', data.refreshToken, 60, '/', getSubDomainName(), true, 'None');
          return this.authService.createBlusterUser(true).pipe(
            map(() => {
              //this.router.navigate(['/streams']);
              this.router.navigate(['/recent-streams']);
            })
          )
        }),
        catchError(() => {
          this.spinner.hide();
          this.loginErrorMessage = 'Incorrect user password'
          return of({});
        })
      )
      .subscribe();
  }

  loginWithGoogle(): void {
    localStorage.setItem('socialNetwork', 'google');
    this.authService.popupwindow('https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&prompt=consent&redirect_uri=' + environment.GOOGLELOGINREDIRECTURL + '&client_id=' + environment.GOOGLECLIENTID + '&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email');

    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
    //   .then((res) => {
    //     let model = {
    //       email: res.email,
    //       accessToken: res.response.access_token,
    //       remoteUserId: res.id,
    //       authType: 2
    //     }
    //     this.authService.loginFromSocialNetwork(model).pipe(
    //       switchMap(data => {
    //         localStorage.setItem('AccessToken', data.AccessToken);
    //         localStorage.setItem('RefreshToken', data.RefreshToken);
    //         return this.authService.createBlusterUser().pipe(
    //           map(() => {
    //             window.close();
    //             //this.router.navigate(['/streams']);
    //             this.router.navigate(['/recent-streams']);
    //           })
    //         )
    //       }),
    //       catchError(() => {
    //         this.loginErrorMessage = 'Failed logging.'
    //         return of({});
    //       })
    //     )
    //       .subscribe();
    //   })
    //   .catch(() => {
    //     this.loginErrorMessage = 'Failed logging.'
    //   });
  }

  loginWithFacebook(): void {
    localStorage.setItem('socialNetwork', 'facebook');
    this.authService.popupwindow('https://www.facebook.com/v12.0/dialog/oauth?auth_type=rerequest&response_type=token&client_id=' + environment.FACEBOOKCLIENTID + '&redirect_uri=' + environment.FACEBOOKAUTHURL);
  }

  loginWithLinkedIn() {
    localStorage.setItem('socialNetwork', 'linkedIn');
    this.authService.popupwindow(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.LINKEDIN_CLIENTID}&scope=r_liteprofile&state=123456&redirect_uri=${environment.LINKEDIN_OAUTH_REDIRECT_URL}`);
  }

  loginWithTwitter() {
    localStorage.setItem('socialNetwork', 'twitter');
    this.showTwitterPopupInterval = setInterval(() => {
      if (localStorage.getItem("twitterSuccess")) {
        location.reload();
      }
    }, 1000);
    this.authService.popupwindow(`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${environment.TWITTER_CONSUMER_KEY}&redirect_uri=${environment.TWITTER_REDIRECT_URL}&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`);
  }

  loginWithZoom() {
    localStorage.setItem('socialNetwork', 'zoom');
    let zoomSuccess = 'zoomSuccess';
    if(!environment.production){
      zoomSuccess += 'Dev'
    }

    this.authService.popupwindow(`${environment.MA_URL}${zoomSuccess}?appId=${environment.ZOOM_REDIRECT_URL}&DeviceId=bluster&blaster=true`);
  }

  async loginWithApple() {
    localStorage.setItem('socialNetwork', 'apple');
    this.authService.popupwindow(
      `https://appleid.apple.com/auth/authorize?client_id=${environment.APPLE_CLIENTID}&redirect_uri=${encodeURIComponent(environment.APPLE_REDIRECT_URL)}&response_type=code id_token&scope=openid&response_mode=fragment`);

    window.addEventListener('message', async event => {
      const decodedToken = jwt_decode<any>(event.data.id_token);
      let requestData = {}
      if (event.data.user) {
        const userName = JSON.parse(event.data.user);
        requestData = {
          "email": decodedToken.email,
          "name": `${userName.name.firstName} ${userName.name.lastName}`,
          "socialId": decodedToken.sub,
        };
      } else {
        requestData = {
          "email": decodedToken.email,
          "socialId": decodedToken.sub,
        };
      }
      console.log(`User Data : ${requestData}`);

      let model = {
        email: decodedToken.email,
        accessToken: event.data.id_token,
        remoteUserId: decodedToken.sub,
        authType: 6
      };

      this.authService.loginFromSocialNetwork(model).subscribe(data => {
        this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
        this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
        this.authService.createBlusterUser(true).subscribe(() => {
          const channel = new BroadcastChannel('app-data');
          channel.postMessage('loginSuccess');
          window.close();
        },
          error => {
            this.loginErrorMessage = 'Failed logging.'
            const channel = new BroadcastChannel('app-data');
            channel.postMessage('loginFailed');
            window.close();
          });
      },
        error => {
          this.loginErrorMessage = 'Failed logging.'
          const channel = new BroadcastChannel('app-data');
          channel.postMessage('loginFailed');
          window.close();
        });
    })
  }

  showHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

  fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
    return entries.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      <T>{}
    );
  }

  ngOnDestroy(): void {
    if (this.showTwitterPopupInterval) {
      clearInterval(this.showTwitterPopupInterval);
    }
  }
}
