import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { CHAT_DESTINATIONS, MESSAGES_PER_PAGE } from 'src/app/common/constants';
import { mapRestreamItemToChatDestination } from 'src/app/common/utils';
import { ChatDestination, ChatDestinationControlName, ChatMessage, ChatMessageData } from 'src/app/models/chat';
import { ChatService } from 'src/app/services/chat.service';
import { SignalrService } from 'src/app/services/signalr.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() vmIP = '';
  // @Input() restreamItems: RestreamItem[] = [];
  @Input() streamId = '';
  @ViewChild('chatWindowElement') chatWindowElement!: ElementRef;
  @ViewChild('inputField') inputField!: ElementRef;

  public controlPanelForm: FormGroup = new FormGroup({});
  public isMobile = this.deviceService.isMobile();
  public chatDestinations: ChatDestination[] = [];
  private subscriptions: Subscription[] = [];
  public messages: ChatMessage[] = [];
  public messageToReply: ChatMessage | null = this.messages[0];
  public isError = false;
  public isDestinationsVisible = false;
  private _restreamItems: any[] = []; // Assuming _restreamItems is the property containing restreamItems
  private _allDestinations: ChatDestination[] = []; // Assuming _allDestinations is the property containing all destinations
  public chatDestinationsControlNames: string[] = ["youtubeMessage", "twitterMessage", "twitchMessage", "linkedInMessage"]; // without facebookMessage
  get restreamItems(): any[] {
    return this._restreamItems;
  }

  @Input() set restreamItems(value: any[]) {
    this._restreamItems = value;
    this.updateChatDestinations();
  }

  getChatDestinations(destinations: ChatDestination[] = CHAT_DESTINATIONS): void {
    this._allDestinations = mapRestreamItemToChatDestination(this.restreamItems, destinations);
    this._allDestinations = this._allDestinations.filter(obj => Object.keys(obj).length !== 0);
    this.updateChatDestinations();
  }

  getDestinationByControlName(controlName: string): ChatDestination | undefined {
    return this.chatDestinations.find((destination) => destination.formControlName === controlName);
  }

  updateChatDestinations(): void {
    const activeStreams = this.restreamItems.filter((item) => item.isActive);
    if (activeStreams.length === 0) {
      this.chatDestinations = this._allDestinations.filter((destination) => destination);
    } else {
      this.chatDestinations = mapRestreamItemToChatDestination(activeStreams, this._allDestinations);
    }
    this.chatDestinations = this.chatDestinations.filter(obj => Object.keys(obj).length !== 0);
  }

  updateFormArray(): void {
    this.chatDestinations.forEach((destination) => {
      this.addFormItem(destination.formControlName, destination?.id?.toString() || '', destination.streamName || '');
    });
    const allSelected = this.chatDestinations.every((destination) => this.controlPanelForm.get(destination.formControlName)?.value.every((item: any) => item.selected));
    if (allSelected) {
      this.controlPanelForm.get('allDestinationMessage')?.setValue(true);
    }
    console.log(this.controlPanelForm, 'CONTROL PANEL FORM')
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const element = this.elementRef.nativeElement.querySelector('.chat-container');
    if (element.scrollTop === 0) {
      this.loadMessages();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
    private chatService: ChatService,
    private signalrService: SignalrService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.buildControlPanelForm();
    this.getChatDestinations();
    this.getChatMessages();
    this.loadMessages();
    this.updateFormArray();
    setTimeout(() => {
      this.scrollChatWindowToBottom();
    }, 1000);
  }

  ngAfterViewInit() {
    this.chatWindowElement.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  loadMessages(skipOnAddingNew?: number): void {
    const skip = skipOnAddingNew !== undefined ? skipOnAddingNew : this.messages.length >= 10 ? this.messages.length : 0;
    const take = skipOnAddingNew !== undefined && this.messages.length ? '1' : MESSAGES_PER_PAGE;
    this.subscriptions.push(
      this.chatService.getAllMessages(this.streamId, skip, take).subscribe(
        (data: ChatMessage[]) => {
          this.messages = skipOnAddingNew !== undefined ? [...this.messages, ...data] : this.messages.length >= 10 ? [...data, ...this.messages] : [...data]
            .sort((a: ChatMessage, b: ChatMessage) => {
              const dateA = new Date(a.messageDate).getTime();
              const dateB = new Date(b.messageDate).getTime();
              return dateA - dateB;
            });
          console.log(this.messages, 'LOAD MESSAGES');
          setTimeout(() => {
            this.scrollToBottomWithOffset(20);
          }, 500);
        },
        (error) => {
          console.error(error, 'ERROR ON LOAD MESSAGES');
          this.isError = true;
        }
      )
    );
  }

  focusInputField(): void {
    this.inputField.nativeElement.focus();
  }

  buildControlPanelForm(): void {
    this.controlPanelForm = this.formBuilder.group({
      allDestinationMessage: [false],
      message: ['', Validators.maxLength(500)],
      youtubeMessage: new FormArray([]),
      twitterMessage: new FormArray([]),
      twitchMessage: new FormArray([]),
      linkedInMessage: new FormArray([]),
    });
  }

  getActiveDestinationsFormControlNames(): string[] {
    return this.chatDestinationsControlNames.filter((destination) => this.chatDestinations.find((dest) => dest.formControlName === destination));
  }

  getFormArray(controlName: string): FormArray {
    return this.controlPanelForm.get(controlName) as FormArray;
  }

  createFormGroup(id: string, streamName: string): FormGroup {
    return new FormGroup({
      id: new FormControl(id, Validators.required as any),
      selected: new FormControl(true),
      streamName: new FormControl(streamName, Validators.required as any),
    });
  }

  addFormItem(controlName: string, id: string, streamName: string): void {
    const formArray = this.getFormArray(controlName);
    formArray.push(this.createFormGroup(id, streamName));
  }

  scrollChatWindowToBottom(): void {
    this.cdr.detectChanges();
    if (this.chatWindowElement) {
      this.chatWindowElement.nativeElement.scrollTop =
        this.chatWindowElement.nativeElement.scrollHeight;
    }
  }

  scrollToBottomWithOffset(offset: number) {
    const element = this.chatWindowElement.nativeElement;
    if (element) {
      element.scrollTop = offset;
    }
  }

  toggleAllDestinations(): void {
    const allSelected = this.controlPanelForm.get('allDestinationMessage')?.value;
    this.getActiveDestinationsFormControlNames().forEach((activeDestination) => {
      const formArray = this.getFormArray(activeDestination);
      formArray.controls.forEach((control) => {
        control.setValue({ selected: allSelected, id: control.value.id, streamName: control.value.streamName });
      });
    });
  }

  onDestinationChange(event: any, controlName: string, i: number): void {
    const destination = this.getDestinationByControlName(controlName);
    if (destination?.formControlName === 'allDestinationMessage') {
      this.toggleAllDestinations();
    } else {
      // @ts-ignore
      const allSelected = this.controlPanelForm.get(destination?.formControlName)?.value.every((item: any) => item.selected);
      this.controlPanelForm.get('allDestinationMessage')?.setValue(allSelected);
    }
  }

  toggleDestinationsVisibility(value: boolean): void {
    if (!value) {
      this.messageToReply = null;
      this.controlPanelForm.get('message')?.setValue('');
    }
    this.isDestinationsVisible = value;
    setTimeout(() => {
      this.scrollChatWindowToBottom();
    }, 500);
  }

  getDestinationsForReply(): ChatDestinationControlName[] {
    const currentDestinations = this.chatDestinations
      .filter(
        (destination) =>
          this.controlPanelForm.value[destination.formControlName]
      )
      .map((destination) => destination.formControlName);
    return currentDestinations;
  }

  getChatMessages(): void {
    this.signalrService
      .getChatMessages(this.vmIP)
      .subscribe((messages: any) => {
        console.log(messages, 'MESSAGES FROM SOCKET');
        if (messages?.length > 0 && messages[0]) {
          this.messages = [...this.messages, ...messages[0]];
          console.log(this.messages, 'MESSAGES');
          setTimeout(() => {
            this.scrollChatWindowToBottom();
          }, 500);
        }
      });
  }

  getSelectedDestinationsIds() {
    let selectedIds: any[] = [];
    for (let controlName in this.controlPanelForm.controls) {
      const control = this.controlPanelForm.get(controlName);
      if (control instanceof FormArray) {
        control.controls.forEach(group => {
          if (group.get('selected')?.value) {
            selectedIds.push(group.get('id')?.value);
          }
        });
      }
    }
    // @ts-ignore
    return selectedIds.flat();
  }

  sendMessage(parentMessage?: ChatMessage | null): void {
    const currentDestinationsIds = this.getSelectedDestinationsIds();
    const allUserDestinationIds = this.restreamItems.map((item) => item.id);
    // @ts-ignore
    const data: ChatMessageData = parentMessage?.isDirect ? {
      message: this.controlPanelForm.value.message,
      restreamItemIds: [parentMessage?.restreamItemId]
    } : {
      message: this.controlPanelForm.value.message,
      parentMessageId: parentMessage?.messageId,
      restreamItemIds: currentDestinationsIds || allUserDestinationIds,
    };
    this.controlPanelForm.get('message')?.setValue('');
    this.chatService.sendMessage(data, this.vmIP).subscribe(
      (res) => {

        this.scrollChatWindowToBottom();
        if (parentMessage?.messageId) {
          this.messageToReply = null;
          this.toggleDestinationsVisibility(false);
        }
      },
      (error) => {
        this.isError = true;
        console.error(error);
      }
    );
  }

  sendReplyOrDirectMessage(message: ChatMessage): void {
    this.isDestinationsVisible = true;
    this.messageToReply = message;
    this.controlPanelForm.get("allDestinationMessage")?.setValue(false);
    const restreamItemIdToReply = message.restreamItemId;
    this.focusInputField();
    const fromFieldsArray = Object.keys(this.controlPanelForm.controls) || []
    fromFieldsArray.forEach(controlName => {
      const formArray = this.getFormArray(controlName);
      if (controlName === message.formControlName) {
        formArray.controls.forEach(control => {
          if (control.value.id == restreamItemIdToReply) {
            control.setValue({ selected: true, id: control.value.id, streamName: control.value.streamName })
          } else {
            control.setValue({ selected: false, id: control.value.id, streamName: control.value.streamName })
          }
        });

      } else if (controlName !== message.formControlName && controlName !== 'message' && controlName !== 'allDestinationMessage') {
        // @ts-ignore
        this.controlPanelForm.get(controlName)?.controls.forEach((control: any) => {
          control.setValue({ selected: false, id: control.value.id, streamName: control.value.streamName });
        });
      }
    });
  }

  getParentMessage(message: ChatMessage): ChatMessage | undefined {
    return this.messages.find((messageFromList: ChatMessage) => messageFromList.messageId === message.parentMessageId);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
