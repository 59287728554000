<section>
    <h2 class="mb-15">Destinations</h2>
    <div class="stream-section__wrapper">

        <!-- add new destination -->
        <div class="p-relative mb-10">
            <button
                class="btn btn_icon btn_size-md btn_rounded-xs btn_outline-orange justify-content-center btn_fluid gap-10 openedDestinationDropdown">
                <i class="icon icon_md icon_plus openedDestinationDropdown"></i>
                Add destination
            </button>

            <!-- dropdown menu -->
            <ul class="stream-destinations__dropdown-list dropdown border text-small fw-600"
                [ngClass]="{'dropdown_showed': isOpenedDestinationDropdown}">
                <li *ngFor="let destination of destinations">
                    <button *ngIf="destination.alt != 'Web Player' || (destination.alt == 'Web Player' && !streamInstance.isPlayerSettingsEnabled)" (click)="handleSelectDestination(destination.title)" id="0"
                        class="d-flex align-items-center c-gap-15">
                        <img [src]="destination.iconUrl" [alt]="destination.alt" width="18" height="18" />
                        {{destination.title}}
                    </button>
                </li>
            </ul>
        </div>

        <!-- active destinations -->
        <ul class="active-streamDestinations d-flex flex-col gap-10 fw-600">
            <li #label id="{{streamInstance.id}}" *ngIf="streamInstance.isPlayerSettingsEnabled"
                (click)="getRestreamItemSettings(streamInstance)">
                <button class="d-grid align-items-center c-gap-15 rounded-md text-left block-w-100">

                    <div class="avatar-container">
                        <img class="avatar" [src]="getDestinationByServiceConfigurationType(6).src"
                        [alt]="getDestinationByServiceConfigurationType(6).alt">
                    </div>
                    <span class="text-overflow">{{getDestinationByServiceConfigurationType(6).title}}</span>

                    <span (click)="resetPlayerSettings($event, streamInstance.id)" class="stream-delete">
                        <img src="/assets/images/svg/close.svg" alt="" width="24" height="24">
                    </span>

                </button>
            </li>
            <li #label
                *ngFor="let item of streamInstance.restreamItems" id="{{item.id}}"
                (click)="getRestreamItemSettings(item)">
                <button class="d-grid align-items-center c-gap-15 rounded-md text-left block-w-100">
                    <div class="avatar-container"
                        *ngIf="getDestinationByServiceConfigurationType(item.serviceConfigurationType)">
                        <img class="avatar" [src]="getUserAvatarByRestreamItems(item.id) || getDestinationByServiceConfigurationType(item.serviceConfigurationType).src" alt="user">
                        <div class="destination-icon">
                            <img *ngIf="getDestinationByServiceConfigurationType(item.serviceConfigurationType).src" class="rounded-icon"
                                [src]="getDestinationByServiceConfigurationType(item.serviceConfigurationType).src"
                                [alt]="getDestinationByServiceConfigurationType(item.serviceConfigurationType).alt"
                                width="32" height="32">
                        </div>
                    </div>
                    <div class="d-flex flex-col">
                    <span class="title">{{getUserNameByRestreamItems(item.id) || getDestinationByServiceConfigurationType(item.serviceConfigurationType).title}}</span>
                    <span class="error">{{getValidationError(item)}}</span>
                    </div>
                    <span (click)="deleteRestreamItem($event, item.id)" class="stream-delete">
                        <img src="/assets/images/svg/close.svg" alt="" width="24" height="24">
                    </span>

                </button>
            </li>
        </ul>
    </div>
</section>
