import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { OPENED_STATISTICS } from 'src/app/common/constants/woopra';
import { CustomSelectOption } from 'src/app/models/custom-select-option.model';
import { StreamInstance } from 'src/app/models/stream-instance';
import { RecentStreamService } from 'src/app/services/recent-stream/recent-stream.service';
import { WoopraService } from 'src/app/services/woopra/woopra.service';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {

  public isOpenedDropdown: boolean = false;
  public filetrList: Array<CustomSelectOption> = new Array<CustomSelectOption>();

  constructor(
    private route: ActivatedRoute,
    private recentStreamsService: RecentStreamService,
    private woopraService: WoopraService
  ) { }

  private subscriptions = new Subscription();
  streamInstances: Array<StreamInstance> = [];
  selectedId?: number = undefined;
  searchModel: any = {
    Search: '',
    OrderField: 'StartDate',
    SortType: 'desc'
  };


  ngOnInit(): void {
    this.woopraService.trackInWoopra({
      actionName: OPENED_STATISTICS
    }).subscribe((x: any) => { });

    this.filetrList.push({ name: "Date", value: "StartDate" }, { name: "Stream name", value: "VmId" }, { name: "Status", value: "status" },);
    this.searchStreams();

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get('id');
      if (id && parseInt(id) && this.selectedId == undefined) {
        this.selectedId = parseInt(id);
      }
    });
  }

  searchStreams() {
    this.subscriptions.add(
      this.recentStreamsService.searchStreams(this.searchModel).subscribe(x => {
        this.streamInstances = x;
      }));
  }

  changeActive(selectedId: number) {
    if (this.selectedId == selectedId) {
      this.selectedId = undefined;
      return;
    }

    this.selectedId = selectedId;
  }

  changeOrder(event: any) {
    this.searchModel.OrderField = event.value;
    this.searchStreams();
  }

  downloadVideo(item: StreamInstance) {
    this.subscriptions.add(
      this.recentStreamsService.generateUrlForDownloadVideo(item).subscribe(res => {
        //window.open(res.presignedUrl);

        var link = document.createElement('a');
        link.href = res.presignedUrl;
        link.download = res.presignedUrl.substr(res.presignedUrl.lastIndexOf('/') + 1);
        link.click();
        console.log(res)
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
