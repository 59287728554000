import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DESTINATIONS } from 'src/app/common/constants/destinations';
import { RestreamItem } from 'src/app/models/restream-item';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent implements OnInit {
  @Output() onSelectDestination: EventEmitter<any> = new EventEmitter();
  @Output() onGetRestreamItemSettings: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteRestreamItem: EventEmitter<any> = new EventEmitter();
  @Output() onResetPlayerSettings: EventEmitter<any> = new EventEmitter();
  @Input() streamInstance: any;
  @Input() validationDestinationErrors: any[] = [];
  @Input() isOpenedDestinationDropdown: boolean = false;
  public destinations = [...DESTINATIONS];

  constructor() { }

  ngOnInit(): void {}

  handleSelectDestination(destinationTitle: string) {
    console.log('handleSelectDestination', destinationTitle)
    this.onSelectDestination.emit(destinationTitle);
  }

  getRestreamItemSettings(item: any) {
    this.onGetRestreamItemSettings.emit(item);
  }

  deleteRestreamItem(event: any, itemId: number) {
    event.stopPropagation();
    this.onDeleteRestreamItem.emit(itemId);
  }

  resetPlayerSettings(event: any, instanceId: number){
    event.stopPropagation();
    this.onResetPlayerSettings.emit(instanceId);
  }

  getUserAvatarByRestreamItems(restreamItemId: number): string {
    const restreamItem = this.streamInstance.restreamItems.find((item: RestreamItem) => item.id === restreamItemId);
    if(restreamItem.serviceConfigurationType === 3){
      return 'assets/images/svg/destinations-channels-icons/rtmp-icon.svg';
    }
    else if(restreamItem.serviceConfigurationType === 5){
      return 'assets/images/svg/destinations-channels-icons/RTMPs-icon.svg';
    } else {
    return restreamItem?.userProfile?.pictureUrl || '';
    }
   }

   getUserNameByRestreamItems(restreamItemId: number): string {
    const restreamItem = this.streamInstance.restreamItems.find((item: RestreamItem) => item.id === restreamItemId);
    return restreamItem?.userProfile?.login || restreamItem?.userProfile?.email || '';
  }

    getValidationError(restreamItem: any): string {
    const errors = this.validationDestinationErrors.filter((item:any) => item.serviceConfigurationType === restreamItem.serviceConfigurationType);
    return errors?.length ? errors[0].message : '';
  }


  getDestinationByServiceConfigurationType(serviceConfigurationType: number | undefined) {
    switch (serviceConfigurationType) {
      case 0:
        return {
          src: "/assets/images/svg/destinations-without-border/youtube-icon.svg",
          alt: "YouTube",
          title: "YouTube"
        };
      case 1:
        return {
          src: "/assets/images/svg/destinations-without-border/facebook-icon.svg",
          alt: "facebook",
          title: "Facebook"
        }
      case 2:
        return {
          src: "/assets/images/svg/destinations-without-border/linkedin-icon.svg",
          alt: "linkedin",
          title: "LinkedIn"
        }
      case 3:
        return {
          src: "",
          alt: "CustomRTMP",
          title: "CustomRTMP"
        }
      case 4:
        return {
          src: "/assets/images/svg/destinations-without-border/twitch-icon.svg",
          alt: "twitch",
          title: "Twitch"
        };
      case 5:
        return {
          src: "",
          alt: "CustomRTMPs",
          title: "CustomRTMPs"
        };
      case 6:
        return {
          src: "/assets/images/svg/destinations-channels-icons/play-circle-icon.svg",
          alt: "Web Player",
          title: "Web Player"
        };
      default:
        return {
          src: "",
          alt: "",
          title: ""
        };
    }
  }

}
