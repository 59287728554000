<div class="d-flex">
  <div class="error-icon-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M20 36.6668C29.2047 36.6668 36.6666 29.2049 36.6666 20.0002C36.6666 10.7954 29.2047 3.3335 20 3.3335C10.7952 3.3335 3.33331 10.7954 3.33331 20.0002C3.33331 29.2049 10.7952 36.6668 20 36.6668Z" stroke="#F32828" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 26.6665H20.0163" stroke="#F32828" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 13.3335V20.0002" stroke="#F32828" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="error-content">
    <p class="error-title">Error title</p>
    <p class="error-text">Error text</p>
  </div>
</div>
