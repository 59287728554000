<div class="facebook-settings">
  <h4 class="mb-20">Destination settings:</h4>

  <div class="form-group">
    <label class="form-label form-label_md">Account:</label>

    <div class="input-container">
      <figure class="input-account account d-flex align-items-center c-gap-10">
        <span class="account-avatar account-avatar_size-md flex-shrink-0 rounded-circle">
          <!-- <img src="../../../assets/images/profile-image.jpg" alt="profile picture" width="38" height="38"> -->
          <img src="{{facebookConfiguration.userProfile?.pictureUrl}}" alt="profile picture" width="38" height="38">
        </span>
        <figcaption>
          <span class="d-block fw-600">{{facebookConfiguration.userProfile?.login}}</span>
          <span class="d-block text-xs t-color-gray-light">{{facebookConfiguration.userProfile?.email}}</span>
        </figcaption>
      </figure>

      <input class="form-control form-control_size-md" type="text" disabled>
      <!-- <button type="button" class="input-tool-btn input-tool-btn_profile">
        <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.167 17.501v-1.667a3.333 3.333 0 00-2.5-3.225M14.167 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H4.167a3.333 3.333 0 00-3.334 3.333V17.5M13.333 2.61a3.333 3.333 0 010 6.458M7.5 9.167a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
            stroke="#fff" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button> -->

    </div>
  </div>

  <div class="form-group">
    <label class="form-label form-label_md" for="stream-name">Stream name:</label>
    <input class="form-control form-control_size-md"
      [(ngModel)]="this.facebookConfiguration.streamName" id="stream-name" type="text" name="stream-name"
      placeholder="Enter name here...">
  </div>

  <div class="form-group">
    <label class="form-label form-label_md" for="description">Description:</label>
    <textarea class="form-control form-control_size-md"
      [(ngModel)]="this.facebookConfiguration.description" name="description" id="description" rows="4"
      placeholder="Enter description here..."></textarea>
  </div>

  <div *ngIf="facebookConfiguration.streamType == 0" class="d-flex justify-content-between mb-20 c-gap-10">
    <div>
      <label class="form-label form-label_md mb-5">Privacy status:</label>
      <span class="t-color-gray-light">Allows you to restrict access to the broadcast.</span>
    </div>
    <app-custom-select [options]="privacyModeList"
      [selectedElementValue]="this.facebookConfiguration.privacyStatus"
      (currentValueChange)="changePrivacyStatus($event)"></app-custom-select>
  </div>

  <div>
    <div class="d-flex align-items-center justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Stream to:</label>
        <span class="t-color-gray-light">Stream quality. The higher the better.</span>
      </div>

      <div class="form-select p-relative">
        <app-custom-select [options]="userGroups" [selectedElementValue]="this.facebookConfiguration.streamType"
          (currentValueChange)="dropdownValueChanged($event)"></app-custom-select>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="groupsToSearchEvent.length > 0"
      class="d-flex align-items-center justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Select group with events:</label>
      </div>
      <div class="form-select p-relative">
        <app-custom-select [options]="groupsToSearchEvent" [selectedElementValue]="groupWithEvents"
          (currentValueChange)="getEvents($event)"></app-custom-select>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="streamTypevalues.length > 0" class="d-flex align-items-center justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Select one:</label>
      </div>
      <div class="form-select p-relative">
        <app-custom-select [options]="streamTypevalues" [selectedElementValue]="streamToValue"
          (currentValueChange)="streamTypeSelected($event)"></app-custom-select>
      </div>
    </div>
  </div>
  <div class="error" *ngIf="error">
    <p>{{error}}</p>
  </div>

  <div class="btw-wrapper d-flex align-items-center gap-15 mt-40">
    <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>

    <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" (click)="saveConfigurationUpdate()">Save</button>  
  </div>  

</div>