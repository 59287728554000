import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements DateFormatPipe {
  transform(date: Date, type: string): string {

    if (!date) {
      return '';
    }

    // var dateForTimezone = new Date('2016-08-25T00:00:00')
    // var userTimezoneOffset = dateForTimezone.getTimezoneOffset() * 60000;

    //Moment js can convert date from utc to local wothout addition calcuations.
    let localFromUtc = moment.utc(date);
    let localDate = localFromUtc.toDate();

    // let dateObj = new Date(date);
    // let newDate = new Date(dateObj.getTime() - userTimezoneOffset);

    if(type == 'date')
    {
      return localDate.toLocaleDateString(Intl.DateTimeFormat().resolvedOptions().locale);
    }
    else if(type == 'dateTime'){
      return localDate.toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale);
    } 

    else return '';
  }
}