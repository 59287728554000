import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filterUniqueObjects, getSubDomainName } from 'src/app/common/utils';
import { ProfileType } from 'src/app/models/enums/ProfileType';
import { ServiceConfigurationType } from 'src/app/models/enums/ServiceConfigurationType';
import { StreamType } from 'src/app/models/enums/StreamType';
import { VmStatus } from 'src/app/models/enums/VmStatus';
import { RestreamItem } from 'src/app/models/restream-item';
import { StreamInstance } from 'src/app/models/stream-instance';
import { UserProfile } from 'src/app/models/user-profile';
import { AuthService } from 'src/app/services/auth.service';
import { CloudCoreServiceService } from 'src/app/services/cloud-core-service/cloud-core-service.service';
import { ErrorService } from 'src/app/services/error.service';
import { PurchaseService } from 'src/app/services/purchase-service/purchase.service';
import { RecentStreamService } from 'src/app/services/recent-stream/recent-stream.service';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import { SignalrService } from 'src/app/services/signalr.service';
import { environment } from 'src/environments/environment';
import videojs from 'video.js';
import { DestinationErrors, STREAM_NAME_LENGHT, STREAM_NAME_REQUIRED } from '../../common/constants/stream';
import { ChatService } from '../../services/chat.service';
import { DebounceService } from '../../services/debounce.service';
import { CustomRtmpSettingsComponent } from '../custom-rtmp-settings/custom-rtmp-settings.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { PlayerSettingsComponent } from '../player-settings/player-settings.component';
import { FacebookSettingsComponent } from '../streams/facebook-settings/facebook-settings/facebook-settings.component';
import { LinkedInSettingsComponent } from '../streams/linked-in-settings/linked-in-settings.component';
import { TwitchSettingsComponent } from '../streams/twitch-settings/twitch-settings/twitch-settings.component';
import { YoutubeSettingsComponent } from '../streams/youtube-settings/youtube-settings/youtube-settings.component';
import { SetupSourceComponent } from './setup-source/setup-source.component';
import log = videojs.log;

@Component({
  selector: 'app-setup-stream',
  templateUrl: './setup-stream.component.html',
  styleUrls: ['./setup-stream.component.scss']
})
export class SetupStreamComponent implements OnInit, OnDestroy {
  @ViewChild(SetupSourceComponent) setupSourceComponent!: SetupSourceComponent;
  @Input()
  private subscriptions = new Subscription();

  public userProfiles: Array<UserProfile> = [];
  public streamId: any = '';
  public streamData = {} as any;
  public streamInstance: StreamInstance = new StreamInstance();
  public isOpenedDestinationDropdown: boolean = false;
  public isSelectUp: boolean = false;
  public selectedRestreamItem: any = 0;
  public broadcastWithStatuses: Array<any> = [];
  public broadcastErrors: Array<any> = [];
  public showCreateBroadcastStatusInfo: boolean = false;
  public initStreamStatus: VmStatus = VmStatus.NotSet;
  public showVmStatusPopup: boolean = false;
  public validationDestinationErrors: Array<any> = [];
  public opener: any;
  public streamUploadStatus: any = 0;

  public isLinkDisabled = false;

  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private setupStreamService: SetupStreamService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private signalrService: SignalrService,
    private recentStreamService: RecentStreamService,
    private purchaseService: PurchaseService,
    private cookieService: CookieService,
    private chatService: ChatService,
    private errorService: ErrorService,
    private debounceService: DebounceService,
    private cloudCoreService: CloudCoreServiceService) { }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (event?.target) {
      const target = event.target as Element;
      if (target.classList.contains('openedDestinationDropdown')) {
        this.isOpenedDestinationDropdown = !this.isOpenedDestinationDropdown;
      } else {
        this.isOpenedDestinationDropdown = false;
      }

      if (target.classList.contains('stream-destinations__add-btn')) {
        const selectElement = document.querySelector('.stream-destinations__dropdown-list') as HTMLElement;

        function isSpaceBelow(element: any) {
          const elementRect = element.getBoundingClientRect();
          const spaceBelow = window.innerHeight - elementRect.y;
          const spaceBelow2 = window.innerHeight - elementRect.bottom - elementRect.x - 10;

          if ((spaceBelow < elementRect.height && !element.classList.contains("dropdownUp")
            || (element.classList.contains("dropdownUp") && spaceBelow2 < elementRect.height))) {
            return true;
          }

          return false;
        }

        if (isSpaceBelow(selectElement)) {
          selectElement.classList.add("dropdownUp");
        } else {
          selectElement.classList.remove("dropdownUp");
        }

      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.isOpenedDestinationDropdown = false;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (params.ott && params.redirectUrl) {

          localStorage.setItem('CDSRedirectUrl', params.redirectUrl);
          this.authService.authorizeWithOTT(params.ott, params.redirectUrl).subscribe(data => {
            this.cookieService.set('AccessToken', data.AccessToken, 2, '/', getSubDomainName(), true, 'None');
            this.cookieService.set('RefreshToken', data.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
            this.authService.createBlusterUser(false).subscribe(() => {
              this.intiNewStream();
            });
          },
            (error) => {
              this.router.navigate(['/login']);
            })
        }
        else {

          const selectElement = document.querySelector('.stream-destinations__dropdown-list') as HTMLElement;

          const selectFunction = function () {
            const selectRect = selectElement.getBoundingClientRect();
            const spaceBelow = window.innerHeight - selectRect.bottom;
            const spaceAbove = selectRect.top;
            const selectHeight = selectElement.offsetHeight;

            console.log(selectRect, spaceBelow);

            if (spaceBelow < selectHeight && spaceAbove >= selectHeight) {
              selectElement.classList.add('dropdownUp');
            } else {
              selectElement.classList.remove('dropdownUp');
            }
          };
          // selectFunction();

          const channel = new BroadcastChannel('app-data');
          channel.addEventListener('message', (event) => {
            if (event.data == 'facebook') {
              this.getInstanceWithDestinations(true);
            }
            else if (event.data.name == 'Duplicate') {
              this.opener.close();
              this.dialog.open(ErrorDialogComponent, {
                disableClose: true,
                data: {
                  error: event.data.error
                }
              });
            }
            else if (event.data == 'redirectToStreamPreview') {
              this.router.navigate(["/stream-preview/" + this.streamId]);
            }
            else if (event.data.name == 'startCreateBroadcastStep1') {
              this.showCreateBroadcastStatusInfo = event.data.showCreateBroadcastStatusInfo;
              this.broadcastWithStatuses = event.data.broadcastWithStatuses;
            }
            else if (event.data.name == 'startCreateBroadcastStep2') {
              this.showCreateBroadcastStatusInfo = event.data.showCreateBroadcastStatusInfo;
              this.showVmStatusPopup = event.data.showVmStatusPopup;
            }
            else if (event.data.name == 'startCreateBroadcastError') {
              this.broadcastWithStatuses = filterUniqueObjects(event.data.broadcastWithStatuses, 'id');
              this.broadcastErrors = event.data.broadcastErrors;
            }
            else if (event.data.name == 'startWithoutCreateBroadcast') {
              this.showVmStatusPopup = event.data.showVmStatusPopup;
            }
            else if (event.data.name == 'vmStatusChanged') {
              this.initStreamStatus = event.data.initStreamStatus;
            }
            else if (event.data.name == 'startVmAnyWay') {
              this.showCreateBroadcastStatusInfo = event.data.showCreateBroadcastStatusInfo;
              this.showVmStatusPopup = event.data.showVmStatusPopup;
            }
            else if (event.data.name == 'cancelCreateBroadcasts') {
              this.showCreateBroadcastStatusInfo = event.data.showCreateBroadcastStatusInfo;
              this.broadcastWithStatuses = filterUniqueObjects(event.data.broadcastWithStatuses, 'id');
              this.broadcastErrors = event.data.broadcastErrors;
            }
          });

          this.route.paramMap.subscribe((params: ParamMap) => {
            this.streamId = params.get('id');
            this.signalrService.addConnectionToGroup(Number(this.streamId));

            this.selectedRestreamItem = params.get("itemId") == null ? 0 : params.get("itemId");
          });

          this.subscriptions.add(
            this.signalrService.initVmStatus.subscribe(status => {
              const channel = new BroadcastChannel('app-data');
              this.initStreamStatus = status;
              let vmStatusChangedObj = {
                name: 'vmStatusChanged',
                initStreamStatus: this.initStreamStatus
              }
              channel.postMessage(vmStatusChangedObj);

              if (this.initStreamStatus === VmStatus.Created) {
                channel.postMessage('redirectToStreamPreview');
                this.router.navigate(["/stream-preview/" + this.streamId]);
              }
              if (this.initStreamStatus === VmStatus.NotSet) {
                this.showVmStatusPopup = false;
              }
            })
          );

          this.subscriptions.add(
            this.signalrService.createVmError.subscribe(error => {
              //alert(error);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  error: error
                },
                maxWidth: '500px',
                width: 'calc(100% - 40px)'
              });
            })
          );

          this.authWithDestination();

          this.getInstanceWithDestinations();
        }
      });
  }

  getInstanceWithDestinations(redirect: boolean = false) {

    this.subscriptions.add(this.setupStreamService.getStreamById(this.streamId).subscribe({
      next: (instance: StreamInstance) => {
        this.streamInstance = instance;
        this.initStreamStatus = instance.instanceStatus;
        if (this.streamInstance.instanceStatus == VmStatus.Created) {
          const channel = new BroadcastChannel('app-data');
          channel.postMessage('redirectToStreamPreview');
          this.router.navigate(['/stream-preview/' + this.streamInstance.id]);
        }
        if (this.initStreamStatus === VmStatus.NotSet) {
          this.showVmStatusPopup = false;
        }

        if (this.initStreamStatus != VmStatus.NotSet) {
          this.showVmStatusPopup = true;
        }

        setTimeout(() => {
          if (this.selectedRestreamItem) {
            let element = document.getElementById(this.selectedRestreamItem.toString());
            element!.classList.add("active-destination");
          }
        }, 500);
      },
      error: (error: any) => {
        console.log(error);

      }
    }));
  }

  authWithDestination(): void {
    let connectType = localStorage.getItem('ConnectedChannel');
    console.log(true);
    var facebookFragmentUrl = this.route.snapshot.fragment;

    if (connectType != 'facebook') {
      this.route.queryParams
        .subscribe(params => {
          if (params.error) {
            this.router.navigate(['/setup-stream/' + params.state + "/0"]);
          }
          else if (params.scope && params.code && connectType == 'youTube') {
            this.streamId = params.state;

            this.subscriptions.add(this.authService.addGoogleToUser(params.code).subscribe({
              next: (userProfile: UserProfile) => {
                this.addStreamDestination(ProfileType.Google, userProfile.id);
              },
              error: (err) => {
                console.log(err);

              }
            }));
          }
          else if (params.code && params.scope && params.state && connectType == 'twitch') {
            this.streamId = params.state;

            this.subscriptions.add(this.authService.addTwitchToUser(params.code).subscribe({
              next: (userProfile: UserProfile) => {
                this.addStreamDestination(ProfileType.Twitch, userProfile.id);
              },
              error: (err) => {
                console.log(err);

              }
            }));
          }
          else if (params.code && connectType == 'linkedIn') {
            this.streamId = params.state;

            this.subscriptions.add(this.authService.addLinkedInToUser(params.code).subscribe({
              next: (userProfile: UserProfile) => {
                this.addStreamDestination(ProfileType.LinkedIn, userProfile.id);
              },
              error: (err) => {
                console.log(err);

              }
            }));
          }
        });
    }
    else {
      if (facebookFragmentUrl != "_=_" && facebookFragmentUrl != null) {
        var splitedDragments = facebookFragmentUrl.split('&');
        var model: any = [];
        splitedDragments.forEach(x => { model.push(x.split("=")) });
        var requestModel: any = this.fromEntries(model);

        this.streamId = requestModel.state;
        this.subscriptions.add(this.authService.addFacebookToUser(requestModel).subscribe({
          next: (userProfile: UserProfile) => {
            this.addStreamDestination(ProfileType.Facebook, userProfile.id);
          },
          error: (err) => {
            console.log(err);
          }
        }));
      }
    }
  }

  loginOnFacebook() {
    let url = 'https://www.facebook.com/v12.0/dialog/oauth?auth_type=rerequest&response_type=token&client_id=' + environment.FACEBOOKCLIENTID + '&redirect_uri=' + environment.FACEBOOKREDIRECTURL + '&state=' + this.streamId + '&scope=publish_video,email,publish_to_groups,pages_manage_cta,pages_manage_ads,page_events,pages_read_engagement,pages_manage_engagement,pages_manage_posts,user_videos';

    localStorage.setItem('ConnectedChannel', 'facebook');

    let accessToken = this.cookieService.get('AccessToken');
    const parsedToken = this.authService.parseAccessToken(accessToken!);

    if (parsedToken.redirectUrl) {
      window.open(url, '_self');
    }
    else {
      this.opener = this.authService.popupwindow(url);
    }
  }

  loginOnYouTube() {
    let url = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&prompt=consent&redirect_uri=' + environment.GOOGLEREDIRECTURL + '&client_id=' + environment.GOOGLECLIENTID + '&state=' + this.streamId + '&scope=https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
    localStorage.setItem('ConnectedChannel', 'youTube');

    let accessToken = this.cookieService.get('AccessToken');
    const parsedToken = this.authService.parseAccessToken(accessToken!);

    if (parsedToken.redirectUrl) {
      window.open(url, '_self');
    }
    else {
      this.opener = this.authService.popupwindow(url);
    }
  }

  loginWithLinkedIn() {
    localStorage.setItem('ConnectedChannel', 'linkedIn');
    let url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.LINKEDIN_CLIENTID}&scope=r_liteprofile,r_emailaddress,w_member_social,r_member_live,w_member_live&state=${this.streamId}&redirect_uri=${environment.LINKEDIN_OAUTHCONNECT_REDIRECT_URL}`;

    let accessToken = this.cookieService.get('AccessToken');
    const parsedToken = this.authService.parseAccessToken(accessToken!);

    if (parsedToken.redirectUrl) {
      window.open(url, '_self');
    }
    else {
      this.opener = this.authService.popupwindow(url);
    }
  }

  loginOnTwitch() {
    localStorage.setItem('ConnectedChannel', 'twitch');

    let url = `https://id.twitch.tv/oauth2/authorize?response_type=code&force_verify=true&client_id=${environment.TWITCH_CLIENT_ID}&redirect_uri=${environment.TWITCH_REDIRECT_URI}&state=${this.streamId}&scope=channel%3Amanage%3Apolls%20channel%3Aread%3Apolls%20channel%3Aread%3Astream_key%20user%3Aedit%3Abroadcast%20user%3Aread%3Aemail%20chat%3Aread%20chat%3Aedit&state=c3ab8aa609ea11e793ae92361f002671`;
    let accessToken = this.cookieService.get('AccessToken');
    const parsedToken = this.authService.parseAccessToken(accessToken!);

    if (parsedToken.redirectUrl) {
      window.open(url, '_self');
    }
    else {
      this.opener = this.authService.popupwindow(url);
    }
  }

  intiNewStream() {

    this.purchaseService.checkFunctionalities().subscribe(() => {
      this.recentStreamService.initNewStream()
        .subscribe({
          next: (newStreamId) => {
            this.router.navigate(['/setup-stream/' + newStreamId + "/0"]);
          },
          error: (e) => {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                error: e.error
              },
              maxWidth: '500px',
              width: 'calc(100% - 40px)'
            });

          }
        })
    });
  }

  addStreamDestination(profileType: ProfileType, userProfileId: number): void {

    this.setupStreamService.addDestination(profileType, this.streamId, userProfileId).subscribe({
      next: (data: RestreamItem) => {
        const channel = new BroadcastChannel('app-data');
        channel.postMessage('facebook');
        window.close();
      },
      error: (error) => {
        console.log(error);

        this.router.navigate(['/setup-stream/' + this.streamId + "/0"]);
      }
    })
  }

  addCustomRtmp(isSecure: boolean) {

    this.subscriptions.add(this.setupStreamService.addCustomRtmp(this.streamId, isSecure).subscribe({
      next: (data: RestreamItem) => {
        this.streamInstance.restreamItems.push(data);
        this.selectedRestreamItem = data.id;
        this.router.navigate(['/setup-stream/' + this.streamId + "/" + this.selectedRestreamItem]);
        setTimeout(() => {
          var elements = document.getElementsByClassName('active-destination');
          while (elements.length > 0) {
            elements[0].classList.remove('active-destination');
          }
          let element = document.getElementById(this.selectedRestreamItem.toString());
          if (element) {
            element!.classList.add("active-destination");
          }
        }, 100);


      },
      error: (error: any) => {
        console.log(error);

      }
    }));
  }

  updateCustomRtmpSettingsFromChild(restreamItem: any) {
    this.streamInstance.restreamItems = this.streamInstance.restreamItems.map(i => i.id != restreamItem.id ? i : restreamItem);
    this.validateDestinations();
  }

  resetPlayerSettings(id: number) {

    this.subscriptions.add(this.setupStreamService.resetPlayerSettings(id).subscribe({
      next: () => {
        this.selectedRestreamItem = 0;
        let element = document.getElementById(this.selectedRestreamItem.toString());
        if (element) {
          element!.classList.add("active-destination");
        }

        this.streamInstance.restreamItems = this.streamInstance.restreamItems.filter((x) => {
          return x.id != id;
        });

        this.router.navigate(['/setup-stream/' + this.streamId + "/" + this.selectedRestreamItem]);

        this.validateDestinations();

        this.getStreamById();


      },
      error: (error) => {
        console.log(error);

      }
    }));
  }

  deleteRestreamItem(id: number): void {
    this.subscriptions.add(this.setupStreamService.removeRestreamItem(id).subscribe({
      next: () => {
        this.selectedRestreamItem = 0;
        let element = document.getElementById(this.selectedRestreamItem.toString());
        if (element) {
          element!.classList.add("active-destination");
        }

        this.streamInstance.restreamItems = this.streamInstance.restreamItems.filter((x) => {
          return x.id != id;
        });

        // this.router.navigate(['/setup-stream/' + this.streamId + "/" + this.selectedRestreamItem]);


      },
      error: (error) => {
        console.log(error);

      }
    }));
  }

  getRestreamItems(instanceId: number) {

    this.subscriptions.add(this.setupStreamService.getRestreamItemsByInstanceId(instanceId).subscribe({
      next: (items: Array<RestreamItem>) => {
        this.streamInstance.restreamItems = items;

      },
      error: (error) => {
        console.log(error);

      }
    }));
  }

  mapDestinationToHandler(title: string) {
    console.log(title, 'title');
    switch (title) {
      case 'Web Player':
        this.addPlayerToDestinations();
        break;
      case 'YouTube':
        this.loginOnYouTube();
        break;
      case 'Facebook':
        this.loginOnFacebook();
        break;
      case 'LinkedIn':
        this.loginWithLinkedIn();
        break;
      case 'Twitch':
        this.loginOnTwitch();
        break;
      case 'RTMP':
        this.addCustomRtmp(false);
        break;
      case 'RTMPs':
        this.addCustomRtmp(true);
        break;
    }

  }

  addPlayerToDestinations() {

    this.setupStreamService.setUserPlayerSettings(this.streamInstance.id, this.streamInstance.embeddedPlayerSettings).subscribe({
      next: (data: any) => {
        this.getStreamById();

      },
      error: (error) => {

        console.log(error);
      }
    });
  }

  validateDestinations(): boolean {
    console.log(this.streamInstance.restreamItems, 'VALIDATE DESTINATIONS')
    this.validationDestinationErrors = [];
    this.streamInstance.restreamItems.forEach(e => {

      let isHasError = false;

      switch (e.serviceConfigurationType) {
        case ServiceConfigurationType.CustomRTMP:
          console.log(true);
          if (!e.rtmpUrl || !e.streamName) {
            isHasError = true;
            let error = {
              message: "Please add stream name for Custom RTMP",
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.rtmpUrl && !/^((http|rtmp):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(e.rtmpUrl!)) {
            isHasError = true;
            let error = {
              message: `Please add a valid rtmp url for ${e.streamName} Custom RTMP`,
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.rtmpUrl)
            this.setupStreamService.validteRTMP(e.rtmpUrl).subscribe((validateResult: any) => {
              console.log(validateResult);
              if (validateResult.error) {
                isHasError = true;
                let error = {
                  message: validateResult.error,
                  serviceConfigurationType: e.serviceConfigurationType
                }
                if (!this.validationDestinationErrors.includes(error))
                  this.validationDestinationErrors.push(error);
              }
            });
          break;
        case ServiceConfigurationType.CustomRTMPS:
          if (!e.rtmpUrl || !e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.RTMPS_NAME_EMPTY,
              serviceConfigurationType: e.serviceConfigurationType
            };
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.rtmpUrl && !/^((https|rtmps):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(e.rtmpUrl!)) {
            isHasError = true;
            const error = {
              message: DestinationErrors.INVALID_RTMP_URL(e.streamName!),
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.rtmpUrl)
            this.setupStreamService.validteRTMP(e.rtmpUrl).subscribe((validateResult: any) => {
              console.log(validateResult);
              if (validateResult.error) {
                isHasError = true;
                const error = {
                  message: validateResult.error,
                  serviceConfigurationType: e.serviceConfigurationType
                }
                if (!this.validationDestinationErrors.includes(error))
                  this.validationDestinationErrors.push(error);
              }
            });
          break;
        case ServiceConfigurationType.YouTube:
          if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.YT_NAME_EMPTY,
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          let resolution = Number(e.resolution!.replace('p', ''));
          if (e.frameRate != '30fps' && resolution < 720) {
            isHasError = true;
            let error = {
              message: DestinationErrors.WRONG_FPS_YT(e.streamName!),
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.frameRate == 'variable' || e.resolution == 'variable') {
            if (e.frameRate != e.resolution) {
              isHasError = true;
              let error = {
                message: DestinationErrors.WRONG_RESOLUTION_YT(e.streamName!),
                serviceConfigurationType: e.serviceConfigurationType
              }
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }
          }

          if (e.streamName)
            if (e.streamName.length >= 100) {
              isHasError = true;
              let error = {
                message: DestinationErrors.INVALID_YT_TITLE_SIZE(e.streamName),
                serviceConfigurationType: e.serviceConfigurationType
              }
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }

          if (e.description)
            if (new Blob([e.description]).size >= 5000) {
              isHasError = true;
              let error = {
                message: DestinationErrors.INVALID_YT_description_SIZE(e.streamName!),
                serviceConfigurationType: e.serviceConfigurationType
              }
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }

          if (e.frameRate == '30fps' && resolution > 1080) {
            isHasError = true;
            let error = {
              message: DestinationErrors.WRONG_FPS_YT_PART2(e.streamName!),
              serviceConfigurationType: e.serviceConfigurationType
            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }

          if (e.latencyPreference != 'ultraLow' && resolution > 1080) {
            isHasError = true;
            let error = {
              message: DestinationErrors.WRONG_LATENCY_YT(e.streamName!),
              serviceConfigurationType: e.serviceConfigurationType

            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          break;
        case ServiceConfigurationType.Twitch:
          if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.TW_NAME_EMPTY,
              serviceConfigurationType: e.serviceConfigurationType

            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          else if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.INVALID_TW_TITLE_SIZE,
              serviceConfigurationType: e.serviceConfigurationType

            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          break;
        case ServiceConfigurationType.Facebook:
          if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.INVALID_FB_TITLE_SIZE,
              serviceConfigurationType: e.serviceConfigurationType

            }
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          if (e.streamName)
            if (e.streamName.length >= 254) {
              isHasError = true;
              let error = {
                message: DestinationErrors.INVALID_FB_TITLE_SIZE,
                serviceConfigurationType: e.serviceConfigurationType
              }
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }

          if (e.description)
            if (new Blob([e.description]).size >= 5000) {
              isHasError = true;
              let error = {
                message: DestinationErrors.INVALID_FB_description_SIZE(e.streamName!),
                serviceConfigurationType: e.serviceConfigurationType

              }
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }
          if (e.streamType != StreamType.SelfPage) {
            if (!e.facebookTypeId) {
              isHasError = true;
              let error = {
                message: DestinationErrors.FB_EMPTY_STREAMTYPE(e.streamName!, StreamType[e.streamType!]),
                serviceConfigurationType: e.serviceConfigurationType

              };
              if (!this.validationDestinationErrors.includes(error))
                this.validationDestinationErrors.push(error);
            }
          }
          break;
        case ServiceConfigurationType.LinkedIn:
          if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.LN_NAME_EMPTY,
              serviceConfigurationType: e.serviceConfigurationType
            };
            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          else if (!e.streamName) {
            isHasError = true;
            let error = {
              message: DestinationErrors.INVALID_LN_TITLE_SIZE,
              serviceConfigurationType: e.serviceConfigurationType
            };

            if (!this.validationDestinationErrors.includes(error))
              this.validationDestinationErrors.push(error);
          }
          break;
      }
      e.isHasError = isHasError;
    });

    if (this.validationDestinationErrors.length > 0) {
      return false;
    }
    return true;
  }

  startBroadcasts(): void {
    if (this.validateDestinations()) {
      var itemsForCreateBroadcast = this.streamInstance.restreamItems
        .filter(x => x.serviceConfigurationType != ServiceConfigurationType.CustomRTMP &&
          x.serviceConfigurationType != ServiceConfigurationType.CustomRTMPS);

      if (itemsForCreateBroadcast.length > 0) {
        itemsForCreateBroadcast.forEach(e => {
          this.broadcastWithStatuses.push({
            type: ServiceConfigurationType[e.serviceConfigurationType ?? 0],
            id: e.id,
            name: e.streamName,
            isCreated: false,
            error: ""
          });
        });

        this.showCreateBroadcastStatusInfo = true;
        const channel = new BroadcastChannel('app-data');
        let createdBroadcastObj = {
          name: 'startCreateBroadcastStep1',
          showCreateBroadcastStatusInfo: this.showCreateBroadcastStatusInfo,
          broadcastWithStatuses: this.broadcastWithStatuses
        }
        channel.postMessage(createdBroadcastObj);

        for (let index = 0; index < itemsForCreateBroadcast.length; index++) {
          const element = itemsForCreateBroadcast[index];

          this.setupStreamService.createBroadcastItem(element.id, this.streamInstance.id).subscribe({
            next: (item: RestreamItem) => {
              this.broadcastWithStatuses.map(e => {
                console.log(e);
                if (e.id == item.id) { e.isCreated = true; }
              });

              this.streamInstance.restreamItems.map(i => i.id != item.id ? i : item)

              if (this.broadcastWithStatuses.every((e) => e.isCreated)) {
                this.showCreateBroadcastStatusInfo = false;
                this.showVmStatusPopup = true;

                let createdBroadcastObjStep2 = {
                  name: 'startCreateBroadcastStep2',
                  showCreateBroadcastStatusInfo: this.showCreateBroadcastStatusInfo,
                  showVmStatusPopup: this.showVmStatusPopup
                }

                channel.postMessage(createdBroadcastObjStep2);
                if (this.streamData.streamSource == 1 && this.streamData.fileFromStream != null) {
                  this.uploadVideoAndStartVm(this.streamData.fileFromStream, this.streamInstance.id);
                }
                else {
                  this.setupStreamService.startVm(this.streamInstance.id).subscribe(() => { })
                }
              }
            },
            error: (error) => {
              console.log(error.error);
              let splitedUrlRequest = error.url.split('/');
              if (splitedUrlRequest.length > 3) {
                this.broadcastWithStatuses.map(e => {
                  if (e.id == splitedUrlRequest[splitedUrlRequest.length - 1]) {
                    e.isCreated = false;
                    e.error = error?.error?.error?.message ?? error?.error?.message ?? error?.error;

                    this.broadcastErrors.push(e);
                  }
                });

                let startCreateBroadcastError = {
                  name: 'startCreateBroadcastError',
                  broadcastWithStatuses: filterUniqueObjects(this.broadcastWithStatuses, 'id'),
                  broadcastErrors: this.broadcastErrors
                }

                channel.postMessage(startCreateBroadcastError);
              }


            }
          });
        }
      } else {
        this.showVmStatusPopup = true;
        const channel = new BroadcastChannel('app-data');
        let startWithoutCreateBroadcast = {
          name: 'startWithoutCreateBroadcast',
          showVmStatusPopup: this.showVmStatusPopup
        }
        channel.postMessage(startWithoutCreateBroadcast);
        if (this.streamData.streamSource == 1 && this.streamData.fileFromStream != null) {
          this.uploadVideoAndStartVm(this.streamData.fileFromStream, this.streamInstance.id);
        }
        else {
          this.setupStreamService.startVm(this.streamInstance.id).subscribe(() => { })
        }
      }
    } else {
      //alert("Please fill all required fields for destinations.");
      this.dialog.open(ErrorDialogComponent, {
        data: {
          error: "Some of your destinations have errors or incomplete configuration. Please check and update the configuration and try again."
        }
      });
    }
  }

  uploadVideoAndStartVm(videoFile: File, streamInstaceId: number): void {
    this.cloudCoreService.uploadFile(videoFile).subscribe(result => {
      if (result == true) {
        this.streamUploadStatus = 1;
        this.setupStreamService.startVm(streamInstaceId).subscribe(() => { })
      }
      else {
        this.streamUploadStatus = 0;
        this.showVmStatusPopup = false;
        this.dialog.open(ErrorDialogComponent, {
          data: {
            error: "Something gone wrong while uploading the video. Please try again"
          }
        });
        return;
      }
    });
  }

  handleSetupSourceEvent(eventData: any) {
    this.streamData = eventData;
  }

  validateStreamInstance(isStartAction: boolean = false) {
    this.errorService.instanceErrors = [];
    this.validateDestinations();
    this.setupSourceComponent.onSubmit();
    if (!this.streamData.streamName) {
      if (!this.errorService.instanceErrors.includes(STREAM_NAME_REQUIRED))
        this.errorService.instanceErrors.push(STREAM_NAME_REQUIRED);
    }
    if (this.streamData.streamName.length > 100) {
      if (!this.errorService.instanceErrors.includes(STREAM_NAME_LENGHT))
        this.errorService.instanceErrors.push(STREAM_NAME_LENGHT);
    }

    if (this.streamInstance.restreamItems.length == 0 && !this.streamInstance.isPlayerSettingsEnabled && isStartAction) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          error: DestinationErrors.ZERO_DESTINATIONS
        }
      });

      return false;
    }

    if (this.streamData.streamSource == 1 && this.streamData.fileFromStream == null) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          error: `You didn't pick a file to stream`
        }
      });
      return false;
    }

    if (this.errorService.instanceErrors.length > 0) {
      return false;
    }

    return true;
  }

  getStreamById() {
    this.subscriptions.add(this.setupStreamService.getStreamById(this.streamId).subscribe({
      next: (instance: StreamInstance) => {
        this.setupSourceComponent.onSubmit();
        this.streamInstance = instance;
      },
      error: (error: any) => {
        console.log(error);

      }
    }));
  }

  startStreamInstance(): void {
    this.isLinkDisabled = true;
    this.spinner.show();
    if (!this.validateStreamInstance(true)) {
      this.spinner.hide();
      this.isLinkDisabled = false;
      return;
    }

    this.subscriptions.add(this.setupStreamService.getStreamById(this.streamId).subscribe({
      next: (instance: StreamInstance) => {
        this.setupStreamService.updateStreamById(this.streamId, this.streamData).subscribe({
          next: (res: any) => {
            console.log(res, "RES START STREAM INSTANCE");
            this.streamInstance = instance;

            if (this.streamInstance.instanceStatus == VmStatus.NotSet) {
              this.startBroadcasts();
            } else {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  error: "Sorry, you initiated stream before. Please wait for finish initialization of streaming server."
                }
              });
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      },
      error: (error: any) => {
        console.log(error);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            error: "something went wrong."
          }
        });

      }
    }));
  }

  startVmAnyWay(): void {

    this.subscriptions.add(this.setupStreamService.getStreamById(this.streamId).subscribe({
      next: (instance: StreamInstance) => {
        this.streamInstance = instance;

        if (this.streamInstance.instanceStatus == VmStatus.NotSet) {
          this.showCreateBroadcastStatusInfo = false;
          this.showVmStatusPopup = true;

          const channel = new BroadcastChannel('app-data');
          let vmStatusChangedObj = {
            name: 'startVmAnyWay',
            showCreateBroadcastStatusInfo: this.showCreateBroadcastStatusInfo,
            showVmStatusPopup: this.showVmStatusPopup
          }
          channel.postMessage(vmStatusChangedObj);

          this.setupStreamService.startVm(this.streamInstance.id).subscribe(() => { });
        } else {
          //alert("Sorry, you initiated stream before. Please wait for finish initialization of streaming server.");
          this.dialog.open(ErrorDialogComponent, {
            data: {
              error: "Sorry, you initiated stream before. Please wait for finish initialization of streaming server."
            }
          });
        }
      },
      error: (error: any) => {
        console.log(error);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            error: "something went wrong."
          }
        });

      }
    }));
  }

  cancelCreateBroadcasts(): void {
    this.showCreateBroadcastStatusInfo = false;
    this.broadcastWithStatuses = [];
    this.broadcastErrors = [];
    const channel = new BroadcastChannel('app-data');
    let vmStatusChangedObj = {
      name: 'cancelCreateBroadcasts',
      showCreateBroadcastStatusInfo: this.showCreateBroadcastStatusInfo,
      broadcastWithStatuses: this.broadcastWithStatuses,
      broadcastErrors: this.broadcastErrors
    }
    channel.postMessage(vmStatusChangedObj);
  }

  getRestreamItemSettings(item: any): void {
    var elements = document.getElementsByClassName('active-destination');
    while (elements.length > 0) {
      elements[0].classList.remove('active-destination');
    }
    let element = document.getElementById(item.id.toString());
    if (element) {
      element!.classList.add("active-destination");
      this.selectedRestreamItem = item.id;

      if (item.serviceConfigurationType == 0) {
        const dialogRef = this.dialog.open(YoutubeSettingsComponent, {
          data: {
            item: item
          },
          maxWidth: '850px',
          maxHeight: '90dvh',
          width: 'calc(100% - 40px)'
        });
        dialogRef.componentInstance.validateDestinations.subscribe(() => {
          this.validateDestinations();
        });
      }
      else if (item.serviceConfigurationType == 1) {
        const dialogRef = this.dialog.open(FacebookSettingsComponent, {
          data: {
            item: item
          },
          maxWidth: '850px',
          width: 'calc(100% - 40px)'
        });
        dialogRef.componentInstance.validateDestinations.subscribe(() => {
          this.validateDestinations();
        });
      }
      else if (item.serviceConfigurationType == 2) {
        const dialogRef = this.dialog.open(LinkedInSettingsComponent, {
          data: {
            item: item
          },
          maxWidth: '850px',
          width: 'calc(100% - 40px)'
        });
        dialogRef.componentInstance.validateDestinations.subscribe(() => {
          this.validateDestinations();
        });
      }
      else if (item.serviceConfigurationType == 4) {
        const dialogRef = this.dialog.open(TwitchSettingsComponent, {
          data: {
            item: item
          },
          maxWidth: '850px',
          width: 'calc(100% - 40px)'
        });
        dialogRef.componentInstance.validateDestinations.subscribe(() => {
          this.validateDestinations();
        });
      }
      else if (item.serviceConfigurationType == 3 || item.serviceConfigurationType == 5) {
        const dialogRef = this.dialog.open(CustomRtmpSettingsComponent, {
          data: {
            item: item,
          },
          maxWidth: '850px',
          width: 'calc(100% - 40px)'
        });
        // dialogRef.componentInstance.validateDestinations.subscribe(() => {
        //   this.validateDestinations();
        // });
        dialogRef.componentInstance.updatedRestreamItem.subscribe((updatedRestreamItem: any) => {
          this.updateCustomRtmpSettingsFromChild(updatedRestreamItem);
        });
      }
      else {
        this.dialog.open(PlayerSettingsComponent, {
          data: {
            streamInstaceId: this.streamInstance.id,
            playerSettings: this.streamInstance.embeddedPlayerSettings,
            onSave: () => {
              this.getStreamById();
            },
          },
          maxWidth: '850px',
          width: 'calc(100% - 40px)'
        });
      }

    }

    if (item.id != 0) {
      this.router.navigate(['/setup-stream/' + this.streamId + "/" + this.selectedRestreamItem]);
    }
    else {
      this.router.navigate(['/setup-stream/' + this.streamId + "/" + 0]);
    }
  }

  fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
    return entries.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      <T>{}
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
