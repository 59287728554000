<div class="dialog dialog-error">
    <div class="mb-40 text-center" [ngSwitch]="error">
        <div *ngSwitchCase="'FacebookDuplicate'">
            <h2 class="dialog__title dialog__title_xl mb-20">
                Please note
            </h2>
            <p class="t-color-black">
              To be able to add one more Facebook account for streaming please open Facebook in a new tab and log out from Your Facebook profile. After this you will be able to add another Facebook destination for streaming from Cinamaker blaster.
            </p>
        </div>
        <div *ngSwitchCase="'GoogleDuplicate'">
            <h2 class="dialog__title mb-20">
                Want to add different profile?
            </h2>
            <p class="dialog__text">
                Switch account on Google and try again.
            </p>
        </div>
        <div *ngSwitchCase="'LinkedInDuplicate'">
            <h2 class="dialog__title mb-20">
                Want to add different profile?
            </h2>
            <p class="dialog__text">
                Switch account on LinkedIn and try again.
            </p>
        </div>
        <div *ngSwitchCase="'TrialExpired'">
            <p class="dialog__text">
                You are using free subscription with a limit of 5 hours or 5 restream sessions. Please upgrade your plan to unlimited monthly or annual subscription to start restream.
            </p>
        </div>
        <div *ngSwitchCase="'TwitchDuplicate'">
            <h2 class="dialog__title mb-20">
                Want to add different profile?
            </h2>
            <p class="dialog__text">
                Switch account on Twitch and try again.
            </p>
        </div>
        <div *ngSwitchCase="'TwitterDuplicate'">
            <h2 class="dialog__title mb-20">
                Want to add different profile?
            </h2>
            <p class="dialog__text">
                Switch account on Twitter and try again.
            </p>
        </div>
        <!-- <div *ngSwitchCase="'livePermissionBlocked'">
            <div class="dialog__title">
                The user that authorized the request is unable to stream live video on YouTube at this time. Details explaining why the user cannot stream live video may be available in the user's channel settings at <a href="https://www.youtube.com/features">https://www.youtube.com/features</a>.
            </div>
        </div>
        <div *ngSwitchCase="'liveStreamingNotEnabled'">
            <div class="dialog__title">
                The user that authorized the request is not enabled to stream live video on YouTube. The user can find more information at <a href="https://www.youtube.com/features.">https://www.youtube.com/features</a>.
            </div>
        </div>
        <div *ngSwitchCase="'userBroadcastsExceedLimit'">
            <div class="dialog__title">
                The user has created too many live or scheduled broadcasts and must stop or delete some.
            </div>
        </div>
        <div *ngSwitchCase="'userRequestsExceedRateLimit'">
            <div class="dialog__title">
                The user has sent too many requests in a given timeframe.
            </div>
        </div>
        <div *ngSwitchCase="'liveBroadcastBindingNotAllowed'">
            <div class="dialog__title">
                The current status of the live broadcast does not allow it to be bound to a stream.
            </div>
        </div>
        <div *ngSwitchCase="'errorExecutingTransition'">
            <div class="dialog__title">
                An error occurred while changing the broadcast's status.
            </div>
        </div>
        <div *ngSwitchCase="'liveBroadcastNotFound'">
            <div class="dialog__title">
                The broadcast specified by the id parameter does not exist
            </div>
        </div>
        <div *ngSwitchCase="'liveBroadcastDeletionNotAllowed'">
            <div class="dialog__title">
                The current status of the live broadcast does not allow it to be deleted.
            </div>
        </div>
        <div *ngSwitchCase="'insufficientLivePermissions'">
            <div class="dialog__title">
                The request is not authorized to delete the specified live broadcast. For more information, see Implementing OAuth2 authentication.
            </div>
        </div> -->
        <div *ngSwitchDefault>
            <h2 class="dialog__title mb-20" *ngIf="this.errorType == 'string'">
                {{this.error}}
            </h2>
            <p class="dialog__text" *ngIf="this.errorType == 'object'">
                {{errorStringify}}
            </p>
        </div>
    </div>

    <div class="dialog__actions" [ngSwitch]="error">
        <div *ngSwitchCase="'FacebookDuplicate'"  class="facebook-duplicate-actions d-flex">
            <button class="btn btn_outline btn_outline-main btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>
            <a (click)="onClose('https://www.facebook.com/')" class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md">Open Facebook</a>
        </div>
        <div *ngSwitchCase="'TrialExpired'"  class="facebook-duplicate-actions d-flex">
            <!-- <button class="btn btn_outline btn_outline-main btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button> -->
            <a (click)="onClose()" class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" href="{{siteUrl}}/pricing">Upgrade Your Plan</a>
        </div>
      <button *ngSwitchDefault class="btn btn_outline btn_outline-main btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Go Back</button>
    </div>
</div>
