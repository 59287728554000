import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { StreamInstance } from 'src/app/models/stream-instance';
import { PurchaseService } from 'src/app/services/purchase-service/purchase.service';
import { RecentStreamService } from 'src/app/services/recent-stream/recent-stream.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { TwitchSettingsComponent } from '../streams/twitch-settings/twitch-settings/twitch-settings.component';

@Component({
  selector: 'app-recent-strems',
  templateUrl: './recent-strems.component.html',
  styleUrls: ['./recent-strems.component.scss']
})
export class RecentStremsComponent implements OnInit {

  public recentStreams: Array<any> = [];
  public liveStreams: Array<any> = [];

  private subscriptions = new Subscription();

  constructor(private router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private recentStreamService: RecentStreamService,
    private purchaseService: PurchaseService) { }

  ngOnInit(): void {
    this.getRecentStreams();
  }




  createStream(): void {
    this.router.navigate(['/setup-stream']);
  }

  getRecentStreams(): void {

    this.recentStreamService.getRecentStreams()
      .subscribe({
        next: (data) => {
          this.liveStreams = data.filter((item: any) => item.isLive);
          this.recentStreams = data.filter((item: any) => !item.isLive && item.isFinished)

        },
        error: (e) => {
          console.log(e.error.Message);

        }
      });
  }

  intiNewStream() {
    this.purchaseService.checkFunctionalities().subscribe(() => {
      this.recentStreamService.initNewStream()
        .subscribe({
          next: (newStreamId) => {
            this.router.navigate(['/setup-stream/' + newStreamId + "/0"]);
          },
          error: (e) => {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                error: e.error
              },
              maxWidth: '500px',
              width: 'calc(100% - 40px)'
            });

          }
        })
    });
  }

  goToStatistic(streamId?: number) {
    if (streamId) {
      this.router.navigate(['/statistic/' + streamId]);
    }
    else {
      this.router.navigate(['/statistic']);
    }
  }

  downloadVideo(item: StreamInstance) {
    this.subscriptions.add(
      this.recentStreamService.generateUrlForDownloadVideo(item).subscribe(res => {
        //window.open(res.presignedUrl);

        var link = document.createElement('a');
        link.href = res.presignedUrl;
        link.download = res.presignedUrl.substr(res.presignedUrl.lastIndexOf('/') + 1);
        link.click();
        console.log(res)
      }));
  }
}
