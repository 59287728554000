<div class="message-container">
  <div class="avatar-container">
    <img class="avatar" [src]="message.imageUrl || getUserAvatarByRestreamItems(message.restreamItemId)" alt="user">
    <div class="destination-icon">
      <img class="rounded-icon" width="22" height="22" [src]="getUserDestinationIconByRestreamItems(message.restreamItemId)" alt="">
    </div>
  </div>
  <div class="message-content" *ngIf="!message.parentMessageId">
    <p class="username text-overflow truncate-text">{{message.senderName}}</p>
    <p>{{message.message}}</p>
    <p class="timestamp">{{ message.messageDate | date : 'shortTime' }}</p>
    <div class="social-icons-container">
      <div *ngFor="let destination of getChatDestinationsByNames(message.destinations)" class="social-icon-chip">
        <img *ngIf="destination.icon" class="rounded-icon" [src]="destination.icon" [alt]="destination.name">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6666 3.88867L5.24998 10.3053L2.33331 7.38867" stroke="#FD966F" stroke-width="1.6"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
    <div class="message-content message-content_reply" *ngIf="message.parentMessageId && message.parentMessage">
    <p class="username text-overflow truncate-text">{{message.senderName}}</p>
    <div class="d-flex flex-col reply-message-text" >
      <div class="d-flex">
        <img class="rounded-icon" width="18" height="18" [src]="getUserDestinationIconByRestreamItems(message.parentMessage.restreamItemId)" alt="">
        <span class="reply-message-username">{{message.parentMessage.senderName}}</span>
      </div>
      {{message.parentMessage.message}}
    </div>
    <p>{{message.message}}</p>
    <p class="timestamp">{{ message.messageDate | date : 'shortTime' }}</p>
    <div class="social-icons-container">
      <div *ngFor="let destination of getChatDestinationsByNames(message.destinations)" class="social-icon-chip">
        <img *ngIf="destination.icon" class="rounded-icon" [src]="destination.icon" [alt]="destination.name">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6666 3.88867L5.24998 10.3053L2.33331 7.38867" stroke="#FD966F" stroke-width="1.6"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
  <div>
  <div  class="reply-button" *ngIf="isSendMessageVisible(message.restreamItemId)" [style.min-height]="isReplyMessageVisible(message.restreamItemId) ? '48%' : '100%'" (click)="directMessage(message)">
    DM
  </div>
  <div *ngIf="isReplyMessageVisible(message.restreamItemId)" [style.min-height]="isSendMessageVisible(message.restreamItemId) ? '48%' : '100%'"  class="reply-button" (click)="replyToMessage(message)">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" fill="none">
      <path fill="#757980"
        d="m2 4.5-.566.566L.87 4.5l.565-.566L2 4.5Zm12.8 6a.8.8 0 0 1-1.6 0h1.6ZM5.184 8.816l-3.75-3.75 1.132-1.132 3.75 3.75-1.132 1.132Zm-3.75-4.882 3.75-3.75 1.132 1.132-3.75 3.75-1.132-1.132ZM2 3.7h6v1.6H2V3.7Zm6 0a6.8 6.8 0 0 1 6.8 6.8h-1.6A5.2 5.2 0 0 0 8 5.3V3.7Z" />
    </svg>
  </div>
  </div>
</div>
