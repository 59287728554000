import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subject, of, Observable } from 'rxjs';
import { UserProfileInterface } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router,
  ) { }

  private unsubscribe: Subject<void> = new Subject<void>();
  userMachines: any;
  public userProfile: UserProfileInterface = {
      Email:'',
      FirstName:'',
      LastName:'',
      Login:'',
      MAUserId: 0,
      Purchases: []
    };

  ngOnInit(): void {
    this.getProfileWithPurchases();
  }

  getProfileWithPurchases(){
    this.authService.getUserWithPurchases().subscribe(
      res => {
        this.userProfile.Email = res.Email;
        this.userProfile.FirstName = res.FirstName;
        this.userProfile.LastName = res.LastName;
        this.userProfile.Login = res.Login;
        this.userProfile.MAUserId = res.MAUserId;
        this.userProfile.Purchases = res.Purchases.sort(function(a, b){
          return a.EndDate - b.EndDate
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  logout(): boolean {
    this.authService.logout().subscribe(res => {
      this.router.navigate(['/login']);
    },
      err => {
        this.router.navigate(['/login']);
      });
    return true;
  }

  panelOpenState = false;

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
