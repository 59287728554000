import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fpsSlice'
})
export class FpsSlicePipe implements FpsSlicePipe {
    transform(value: string): string {
        if(!value){
            return '';
        }
       return value.slice(0, -3);
    }
}