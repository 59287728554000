<div class="chat-container" #chatWindowElement>
  <div class="chat-messages">
    <div class="chat-messages-scrollable">
      <div class="message" *ngFor="let message of messages" [ngClass]="{'reply': message?.parentMessageId}">
        <app-message [message]="message" [restreamItems]="restreamItems"
          (onDirectOrReply)="sendReplyOrDirectMessage($event)"></app-message>
      </div>
      <!-- <app-chart-error *ngIf="isError"></app-chart-error> -->
    </div>
  </div>
  <div class="chat-control-panel-wrapper" *ngIf="chatDestinations?.length">
    <div class="chat-control-panel" [formGroup]="controlPanelForm">
      <div *ngIf="!messageToReply else replyMessage" class="destinations"
        [ngClass]="{'destinations-focused': isDestinationsVisible}">
        <div class="control-panel-header">
          <p class="reply-text">Specify channels to send to:</p>
          <div (click)="toggleDestinationsVisibility(false)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5L5 15" stroke="#515356" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5 5L15 15" stroke="#515356" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="d-flex mb-10">
          <label class="checkbox-container">
            <input type="checkbox" (change)="toggleAllDestinations()" formControlName="allDestinationMessage">
            <span class="checkbox destination-checkbox"></span>
            <p>All</p>
          </label>
          <div class="checkbox-list">
            <ng-container *ngFor="let controlName of getActiveDestinationsFormControlNames()">
              <ng-container [formArrayName]="controlName">
                <div *ngFor="let control of getFormArray(controlName).controls; index as i">
                  <ng-container [formGroupName]="i">
                    <label class="checkbox-container">
            
                      <input type="checkbox" [checked]="control.value.selected" [value]="control.value.selected"
                        formControlName="selected"
                        (change)="onDestinationChange($event, controlName, i)">
                      <span class="checkbox destination-checkbox"></span>
                      <img *ngIf="getDestinationByControlName(controlName)?.icon" width="22" height="22"
                        class="rounded-icon" [src]="getDestinationByControlName(controlName)?.icon"
                        [alt]="getDestinationByControlName(controlName)?.name">
                      <p class="text-overflow">{{ control.value.streamName }}</p>
                    </label>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </div>
      <ng-template #replyMessage>
        <div class="control-panel-header">
          <div class="reply-title">
            <p class="reply-text">{{messageToReply?.isDirect ? 'Direct message to:' : 'Reply to group chat:'}}</p>
            <div class="destination-icon">
              <img class="rounded-icon" [src]="messageToReply?.iconSrc" alt="">
            </div>
            <p class="reply-sender">{{messageToReply?.senderName}}</p>
          </div>
          <div (click)="toggleDestinationsVisibility(false)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5L5 15" stroke="#515356" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5 5L15 15" stroke="#515356" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="reply-message" *ngIf="!messageToReply?.isDirect">{{messageToReply?.message}}</div>
      </ng-template>
      <div class="chat-input" [ngClass]="{'input-focused': isDestinationsVisible}">
        <input type="text" maxlength="500" #inputField (focus)="toggleDestinationsVisibility(true)"
          (keyup.enter)="getSelectedDestinationsIds().length && controlPanelForm.get('message')?.value ? messageToReply ? sendMessage(messageToReply) : sendMessage() : null" formControlName="message"
          placeholder="Type your message here">
        <button class="button-with-border" [disabled]="getSelectedDestinationsIds().length === 0 || !controlPanelForm.get('message')?.value"
          (click)="messageToReply ? sendMessage(messageToReply) : sendMessage()">Send</button>
      </div>
    </div>
  </div>
</div>