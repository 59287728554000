import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VmStatus } from 'src/app/models/enums/VmStatus';
import { StreamInstance } from 'src/app/models/stream-instance';
import { UserProfile } from 'src/app/models/user-profile';
import { AuthService } from 'src/app/services/auth.service';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import { StreamPreviewService } from 'src/app/services/stram-preview/stream-preview.service';
import { PlayerMetaData } from 'src/app/models/player-meta-data';
import * as signalR from '@aspnet/signalr';
import * as xmlToJs from 'x2js';
import { environment } from 'src/environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GetEmbeddedVideoDialogComponent } from '../streams/get-embedded-video-dialog/get-embedded-video-dialog/get-embedded-video-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ForceStopStreamComponent } from '../streams/force-stop-stream/force-stop-stream.component';
import { WoopraService } from 'src/app/services/woopra/woopra.service';
import { GOT_EMBED_PLAYER_CODE } from 'src/app/common/constants/woopra';
import { ServiceConfigurationType } from 'src/app/models/enums/ServiceConfigurationType';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-stream-preview',
  templateUrl: './stream-preview.component.html',
  styleUrls: ['./stream-preview.component.scss']
})
export class StreamPreviewComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  private rtmpInfoInterval: any;

  public streamInstance: StreamInstance = new StreamInstance();
  public streamId: any = '';
  public userProfiles: Array<UserProfile> = [];

  public rtmpUrl: string = "";
  public rtmpsUrl: string = "";
  public streamKey: string = "";
  public streamSource: number = 0;
  public showStreamInfoBlock: boolean = false;

  public streamUrl: string = "";
  iframeUrlSafe: SafeResourceUrl = '';
  public isRtmps: boolean = false;

  public playerMetaData: PlayerMetaData = new PlayerMetaData();

  private hubConnection!: signalR.HubConnection;
  public mybreakpoint: number = 4;

  public isChatsAllowed = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private setupStreamService: SetupStreamService,
    private authService: AuthService,
    private streamPreviewService: StreamPreviewService,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private woopraService: WoopraService,
    private clipboard: Clipboard,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.mybreakpoint = (window.innerWidth <= 600) ? 1 : 4;

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.streamId = params.get('id');
    });

    this.subscriptions.add(
      this.setupStreamService.getStreamById(this.streamId).subscribe({
        next: (instance: StreamInstance) => {
          this.streamInstance = instance;
          
          if (this.streamInstance.instanceStatus != VmStatus.Created) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                error: "Stream server is not ready for streaming.",
                redirectUrl: "/recent-streams"
              }
            });

            //alert("Stream server is not ready for streaming.");
            //this.router.navigate(['/setup-stream/' + this.streamInstance.id]);
          }
          if (this.streamInstance.isFinished) {
            this.router.navigate(['/recent-streams']);
          }

          this.isChatsAllowed = this.hasRestreamItemWithReceiveChatMessages(this.streamInstance);

          if (this.streamInstance.vmDomainSsl) {
            this.getStreamInfomation(this.streamInstance.vmDomainSsl);
          }

          this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(environment.RESTREAM_ITEM_STATUS_HUB, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
          })
          .build();

        this.hubConnection
          .start()
          .then(() => {
            this.hubConnection!.invoke("SetConnectionId", this.streamInstance.id);
            this.hubConnection!.on("RestreamItemsStatus", (updatedInstance:any) => {
              // console.log(this.streamInstance);
              // let instanceParced = JSON.parse(updatedInstance);

              this.streamInstance = updatedInstance;
            })
          });


          this.rtmpUrl = `rtmp://${this.streamInstance.vmDomainSsl}:1936/live`;
          this.rtmpsUrl = `rtmps://${this.streamInstance.vmDomainSsl}:1935/live`;
          // this.streamKey = `${this.streamInstance.userId}?sk=${this.streamInstance.streamKey}`;
          this.streamKey = `${this.streamInstance.streamKey}`;

          this.streamSource = this.streamInstance.streamSource;

          this.streamUrl = `${environment.ROOT_SITE_URL}/stream-video-player/${this.streamInstance.vmDomainSsl}/${this.streamInstance.id}/${this.streamInstance.userId}/${this.streamInstance.streamKey}`;
          this.iframeUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.streamUrl);

          this.authService.getUserProfiles().subscribe({
            next: (profiles: Array<UserProfile>) => {
              this.userProfiles = profiles;

              if (this.userProfiles.length > 0 && instance.id > 0) {
                instance.restreamItems.forEach(element => {
                  element.userProfile = this.userProfiles.find(x => x.id == element.userProfileId)
                });
              }


            },
            error: (err: any) => {
              console.log(err);

            }
          })
        },
        error: (error: any) => {
          console.log(error);

        }
      })
    );
  }

  handleSize(event: any) {
     this.mybreakpoint = (event.target.innerWidth <= 600) ? 1 : 4;
  }

  checkRestreamItemsAbility(): boolean {
    if (!this.streamInstance.restreamItems.length) {
      return false;
    } else {
      return this.streamInstance.restreamItems.some((restreamItem: any) => !restreamItem?.restreamItemAbility?.sendChatMessages);
    }
  }

hasRestreamItemWithReceiveChatMessages(streamInstance: StreamInstance): boolean {
  return streamInstance.restreamItems.some(item => item.restreamItemAbility?.receiveChatMessages === true);
}

redirectToControl(item: any): void {
  console.log(item, "REDIRECT TO CONTROL")
    const restreamItem = this.streamInstance?.restreamItems.find((x: any) => x.id == item.id) || {} as any;
  switch (item.serviceConfigurationType) {
    case ServiceConfigurationType.Facebook:
      this.redirectToFBControl(restreamItem.id);
      break;
    case ServiceConfigurationType.YouTube:
      this.redirectToYTControl(restreamItem.broadcastId);
      break;
    case ServiceConfigurationType.Twitch:
      this.redirectToTWControl(restreamItem);
      break;
    case ServiceConfigurationType.LinkedIn:
      this.openOnLinkedIn(restreamItem.id);
      break;
    case ServiceConfigurationType.CustomRTMPS:
      this.LinkedInControlRoom(restreamItem.id);
      break;
    default:
      break;
  }
}


  redirectToFBControl(restreamItemId: number): void {
    this.subscriptions.add(
      this.streamPreviewService.getFacebookProducerId(restreamItemId).subscribe({
        next: (producerId) => {
          window.open(`https://www.facebook.com/live/producer/${producerId}/?entry_point=feedx_sprouts`, '_blank');
        },
        error: (err) => {
          console.log(err);
          //
        }
      })
    );
  }

  redirectToYTControl(broadcastId: string | undefined): void {
    window.open(`https://studio.youtube.com/video/${broadcastId}/livestreaming`, '_blank');
  }

  redirectToTWControl(restreamItem: any): void {
    window.open(`https://dashboard.twitch.tv/u/${restreamItem?.userProfile?.login}/stream-manager`, '_blank');
  }

  openOnLinkedIn(itemId: any) {
    this.streamPreviewService.getRestreamItem(itemId).subscribe((res: any) => {
      if (!res || !res.broadcastId) {
        this.dialog.open(ErrorDialogComponent, {
          disableClose: true,
          data: {
            error: "Stream is not ready",
          }
        });
      }
      else {
        window.open(`https://www.linkedin.com/video/live/${res.broadcastId}`, '_blank');
      }
    });
  }

  LinkedInControlRoom(itemId: any) {
    this.streamPreviewService.getRestreamItem(itemId).subscribe((res: any) => {
      if (!res || !res.broadcastId) {
        this.dialog.open(ErrorDialogComponent, {
          disableClose: true,
          data: {
            error: "Stream is not ready",
          }
        });
      }
      else {
        window.open(`https://www.linkedin.com/video/golive/now/streaming/${res.broadcastId}`, '_blank');
      }
    });
  }

  stopRestreamItem(event: any) {
    console.log(event, "STOP RESTREAM ITEM")
    const {itemId: restreamItemId, streamInstanceId: instanceId} = event;
    this.subscriptions.add(
      this.setupStreamService.stopRestreamItem(restreamItemId, instanceId).subscribe({
        next: (data) => {
          for(let restreamItemfromInstance of this.streamInstance.restreamItems){
            if(restreamItemfromInstance.id == restreamItemId){
              restreamItemfromInstance.isActive = false;
            }
          }

          //alert("Stream has been stopped");
          this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            data: {
              error: "Stream has been stopped",
            }
          });
        },
        error: (err) => {
          console.log(err);
        }
      })
    )
  }

  stopStream(): void {

    this.streamPreviewService.getStreamStatistic(this.streamInstance.vmDomainSsl).subscribe({
      next: (data) => {
        var x2js = new xmlToJs();
        var document: any = x2js.xml2js(data);

        let applications = document.rtmp.server.application.filter((y: any) => y.name == 'live');

        let application = applications[0]

        if (!application?.live?.nclients || application.live.nclients > 0){

          this.dialog.open(ForceStopStreamComponent,
            {
              maxWidth: '460px',
              width: 'calc(100% - 40px)',
              data: { streamInstanceId: this.streamInstance.id}
            })
        }
        else{
          this.stopVm();
        }
      },
      error: (error) => {
        this.stopVm();
      }
    })

  }

  stopVm(){
    this.subscriptions.add(
      this.setupStreamService.stopVmInstance(this.streamInstance.id, true).subscribe({        
        next: (data) => {
          localStorage.setItem("privateKey", "");

          //alert("Stream has been stopped");
          //this.router.navigate(['/recent-streams']);
          this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            data: {
              error: "Stream has been stopped",
              redirectUrl: "/recent-streams"
            }
          });
        },
        error: (err) => {
          console.log(err);

        }
      })
    )
  }

  getStreamInfomation(domain: string): void {
    if (this.rtmpInfoInterval) {
      clearInterval(this.rtmpInfoInterval);
    }
  
    let count = 0;
    let errorCount = 0;
    let stramDurationInSec = 0;
    if(this.cookieService.get('streamDurationPrev'+this.streamInstance.id)){
      var value = this.cookieService.get('streamDurationPrev'+this.streamInstance.id);
      stramDurationInSec = value ? parseInt(value) : 0;
    }
  
    this.rtmpInfoInterval = setInterval(() => {
      this.subscriptions.add(
        this.streamPreviewService.getStreamStatistic(domain).subscribe({
          next: (data) => {
            const x2js = new xmlToJs();
            const document: any = x2js.xml2js(data);
  
            const application = document.rtmp.server.application.find((y: any) => y.name === 'live');
  
            if (!application || application.live.nclients < 1) {
              this.cookieService.set('streamDurationPrev' + this.streamInstance.id, stramDurationInSec.toString(), 1, '/');
              return;
            }
  
            if (!application.live.stream.client.length) {
              if (application.live.stream.client.swfurl) {
                if (application.live.stream.client.swfurl.includes('rtmps')) {
                  this.isRtmps = true;
                  localStorage.setItem('isRtmps', this.isRtmps.toString());
                }
                else {
                  this.isRtmps = false;
                  localStorage.setItem('isRtmps', this.isRtmps.toString());
                }
              }
            }
            else {
              let swfurls = application.live.stream.client.filter((x: any) => x.swfurl && x.swfurl.includes('rtmp'));

              if (swfurls.length > 0) {
                if (swfurls[0].swfurl.includes('rtmps')) {
                  this.isRtmps = true;
                  localStorage.setItem('isRtmps', this.isRtmps.toString());
                }
                else {
                  this.isRtmps = false;
                  localStorage.setItem('isRtmps', this.isRtmps.toString());
                }
              }
            }

            if (count == 0) {
              setTimeout(this.reloadIframe, 1000);
            }
  
            const streamClient = Array.isArray(application.live.stream.client) ? application.live.stream.client[0] : application.live.stream.client;
  
            var previousDuration = this.cookieService.get('streamDurationPrev'+this.streamInstance.id);
            var stramDurationPrevInSec = previousDuration ? parseInt(previousDuration) : 0;
            if (streamClient && streamClient.time) {
              stramDurationInSec = stramDurationPrevInSec + streamClient.time / 1000;
            }
  
            this.playerMetaData.streamName = application.name;
            this.playerMetaData.fps = application.live.stream.meta.video.frame_rate;
            this.playerMetaData.resolution = `${application.live.stream.meta.video.width}x${application.live.stream.meta.video.height}`;
  
            const d = new Date(0, 0, 0, 0, 0, 0, 0);
            this.playerMetaData.streamDuration = d.setSeconds(stramDurationInSec);
  
            const startedStreamAt = this.cookieService.get('startedStreamAt' + this.streamInstance.id);
            if (startedStreamAt) {
              this.playerMetaData.startedAt = startedStreamAt;
            } else {
              const localTimestamp = moment().unix();
              this.playerMetaData.startedAt = moment.unix(localTimestamp - Math.floor(stramDurationInSec)).valueOf();
              this.cookieService.set('startedStreamAt' + this.streamInstance.id, this.playerMetaData.startedAt, 1, '/');
            }
  
            this.playerMetaData.codec = application.live.stream.meta.video.codec;
            count++;
          },
          error: (error) => {
            if (error.name == "HttpErrorResponse") {
              errorCount++;
              if (this.rtmpInfoInterval && errorCount > 5) {
                clearInterval(this.rtmpInfoInterval);
              }
            }
            console.log(error);
          }
        })
      )
    }, 10000);
  }

  getEmbeddedVideo(): void {
    var embedCodeStr = "You don't have any connected stream server instances";

    if (this.streamInstance.vmDomainSsl) {
      embedCodeStr = `<iframe style="position: absolute;top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" src="${environment.ROOT_SITE_URL}/stream-video-player/${this.streamInstance.vmDomainSsl}/${this.streamInstance.id}/${this.streamInstance.userId}/${this.streamInstance.streamKey}" title="Video" frameborder="0"></iframe>`;
      //embedCodeStr = `<div style="position: relative;overflow: hidden;width: 100%;padding-top: 56.25%;"><iframe style="position: absolute;top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" src="${environment.ROOT_SITE_URL}/stream-video-player/${this.streamInstance.vmDomainSsl}/${this.streamInstance.id}/${this.streamInstance.userId}" title="Video" frameborder="0"></iframe></div>`;
    } else {
      embedCodeStr = `<div style="position: relative;overflow: hidden;width: 100%;padding-top: 56.25%;"><iframe style="position: absolute;top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" src="${environment.ROOT_SITE_URL}/stream-video-player/x36xhzz/x36xhzz/${this.streamInstance.userId}/${this.streamInstance.streamKey}" title="Video" frameborder="0"></iframe></div>`;
    }

    this.dialog.open(GetEmbeddedVideoDialogComponent,
      {
        maxWidth: '460px',
        width: 'calc(100% - 40px)',
        data: { embedCode: embedCodeStr, privateKey: this.streamInstance.embeddedPlayerSettings.privateStreamKey }
      })

      this.woopraService.trackInWoopra({
        actionName: GOT_EMBED_PLAYER_CODE
      }).subscribe((x: any) => {});
  }

  showStreamInfo() {
    this.showStreamInfoBlock = !this.showStreamInfoBlock;
  }

  reloadIframe() {
    var src = document.getElementById("video-frame")?.getAttribute("src");
    document.getElementById("video-frame")?.setAttribute("src", src ? src : '');
  }

  toggleAccordion(event: any): void {
    var targetElement = event.target;
    var elementForCheckAttribute = null;
    let buttonElement;
    if (targetElement.tagName.toLowerCase() == 'button') {
      elementForCheckAttribute = event.target.nextElementSibling;
      buttonElement = event.target;
    }
    else {
      elementForCheckAttribute = event.target.parentElement.nextElementSibling;
      buttonElement = event.target.parentElement;
    }
    var hiddenAttribute = elementForCheckAttribute.getAttribute("hidden");
    if (hiddenAttribute == null) {
      elementForCheckAttribute.setAttribute("hidden", "");
      buttonElement.classList.remove("active");
    } else {
      elementForCheckAttribute.removeAttribute("hidden");
      buttonElement.classList.add("active");
    }
  }

  ngOnDestroy(): void {
    if (this.rtmpInfoInterval) {
      clearInterval(this.rtmpInfoInterval);
    }
    localStorage.setItem('isRtmps', 'false');
    this.subscriptions.unsubscribe();
  }
}
