import { ChatDestination } from "../models/chat";
import { Destination } from "../models/destination";
import { ServiceConfigurationType } from "../models/enums/ServiceConfigurationType";

export const CHAT_DESTINATIONS: ChatDestination[] = [
    {
        icon: 'assets/images/svg/destinations-channels-icons/youtube-icon.svg',
        name: 'YouTube',
        formControlName: 'youtubeMessage'
    },
    {
        icon: 'assets/images/svg/destinations-channels-icons/twitter-icon.svg',
        name: 'Twitter',
        formControlName: 'twitterMessage'
    },
    {
        icon: 'assets/images/svg/destinations-channels-icons/twitch-icon.svg',
        name: 'Twitch',
        formControlName: 'twitchMessage'
    },
    {
        icon: 'assets/images/svg/destinations-channels-icons/linkedin-icon.svg',
        name: 'LinkedIn',
        formControlName: 'linkedInMessage'
    },
    {
        icon: 'assets/images/svg/destinations-channels-icons/facebook-icon.svg',
        name: 'Facebook',
        formControlName: 'facebookMessage'
    }
];

export const API_VERSION = 'v1';

export const MESSAGES_PER_PAGE = '10';
