<div class="app d-flex flex-col bg-app-main">
  <app-sidebar></app-sidebar>

  <main class="d-flex flex-col">
    <section class="d-flex flex-col flex-1">
      <div class="container container_md" [ngClass]="{'d-flex flex-col flex-1 justify-content-center' :
        liveStreams.length < 1 && recentStreams.length < 1}">

        <div>
          <figure class="start-screen-illustration d-flex flex-col align-items-center mb-15 mt-20">
            <img *ngIf="liveStreams.length >= 1 || recentStreams.length >=1" class="mb-10"
              src="../../../assets/images/svg/start-stream.svg" alt="Start a new steam here." width="120" height="120">

            <img *ngIf="liveStreams.length < 1 && recentStreams.length < 1" class="mb-30"
              src="../../../assets/images/main-screen-picture.svg" alt="Page illustration." width="345" height="241">
            <figcaption>
              <h2 class="mb-5 text-center">Start a new stream here.</h2>
              <p class="text-md text-center">Setup destinations and go. </p>
            </figcaption>
          </figure>
          <a class="btn btn_custom btn_color-main btn_size-primary btn_rounded-xs block-center"
            (click)="intiNewStream()">
            New Stream
          </a>
        </div>

        <div *ngIf="liveStreams.length >= 1 || recentStreams.length >= 1"
          class="streams block mt-50 pt-20 pb-25 p-inline-15">
          <div class="streams-table-container custom-scroll custom-scroll_size-md">
            <table class="streams-table">
              <caption class="streams-table__title text-left">
                <h3>On Air</h3>
              </caption>

              <thead *ngIf="liveStreams.length < 1">
                <tr>
                  <td>
                    <h4 class="text-center t-color-gray-light mt-30 mb-25">No Live streams now</h4>
                  </td>
                </tr>
              </thead>

              <thead *ngIf="liveStreams.length > 0">
                <tr>
                  <th>Stream</th>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Received</th>
                  <th>Sent</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody *ngIf="liveStreams.length > 0">
                <tr *ngFor="let item of liveStreams">
                  <td class="text-overflow">{{(item.restreamItems.length > 0) ? item.restreamItems[0].streamName :
                    item.vmId}}</td>
                  <td>{{item.startDate | date:'MM.dd.yyyy'}}</td>
                  <td>{{item.endDate! | time: item.startDate!}}</td>
                  <td>{{item.streamBytesIn! | byte}}</td>
                  <td>{{item.streamBytesOut! | byte}}</td>
                  <td>
                    <div class="d-flex align-items-center c-gap-20 r-gap-10">
                      <span class="rounded-xl-2 live-label">Live</span>
                      <a class="btn table-btn table-btn_xs btn_outline btn_outline-main"
                        href="/stream-preview/{{item.id}}">Open</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table *ngIf="recentStreams.length >= 1" class="streams-table mt-25">
              <caption class="streams-table__title text-left">
                <h3>Latest Streams</h3>
              </caption>

              <thead>
                <tr>
                  <th>Stream</th>
                  <th>Date</th>
                  <th>Duration</th>
                  <th>Received</th>
                  <th>Sent</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of recentStreams">
                  <td class="text-overflow">{{item.streamName}}</td>
                  <td>{{item.startDate | date: 'MM.dd.yyyy'}}</td>
                  <!-- <td>{{item.rtmpUpTime}}</td> -->
                  <td>{{item.endDate! | time: item.startDate!}}</td>
                  <td>{{item.streamBytesIn! | byte}}</td>
                  <td>{{item.streamBytesOut! | byte}}</td>
                  <td>
                    <div class="flex-center-between c-gap-20">
                      <span class="t-color-gray">Finished</span>
                      <div class="button-container">
                        <button (click)="downloadVideo(item)"
                          [disabled]="!item?.saveStreamToDownloads || item?.concatInstance?.convertStatus !== 2"
                          *ngIf="item?.concatInstance"
                          class=" btn btn_icon table-btn table-btn_md btn_color-main btn_outline-main" mat-button
                          matTooltip="Your stream recording will be ready in several minutes"
                          [matTooltipDisabled]="item?.concatInstance?.convertStatus === 2">Download</button>
                      </div>
                      <a (click)="goToStatistic(item.id)"
                        class="btn table-btn table-btn_md btn_outline btn_outline-main padding-left">Details</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <button *ngIf="recentStreams.length >= 1" (click)="goToStatistic()"
              class=" block-center t-color-primary text-underline-hover mt-10 fw-600">All streams</button>
          </div>
        </div>

      </div>
    </section>
  </main>
</div>


<div class="overlay"></div>