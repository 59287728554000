export class MaUserModel{
    public MAUserId?:number;
    public Email? :string;
    public FirstName? :string;
    public LastName? :string;
    public Login? :string;

    constructor(init?: Partial<MaUserModel>) {
        Object.assign(this, init);
    }
}