import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private httpWithoutInterceptor: HttpClient;
  public instanceErrors: Array<string> = [];

  constructor(private httpBackend: HttpBackend) {
    // It prevents interceptor looping if log method throws error
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  public log(errorResponse: any): Observable<any> { 
    console.log(errorResponse, 'errorResponse')
    const message = typeof errorResponse === 'string' ? errorResponse : errorResponse?.error?.message;       
    return this.httpWithoutInterceptor.post(environment.BLUSTER_API_URL + 'error/error-log', { message });
  }
}
