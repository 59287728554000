import { AppErrorHandler } from './interceptors/app-error-handler';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from './components/auth/auth.component';
import { MainComponent } from './components/main/main.component';
import { ProfileComponent } from './components/profile/profile.component';
import { BillingInfoComponent } from './components/billing-info/billing-info.component';
import { AppSidebarComponent } from './components/app-sidebar/app-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { TimePipe } from './pipes/time.pipe';
import { BytePipe } from './pipes/bytes.pipe';
import { FpsSlicePipe } from './pipes/fpsSlice.pipe';
import { StreamVideoPlayerComponent } from './components/stream-video-player/stream-video-player.component';
import { GetEmbeddedVideoDialogComponent } from './components/streams/get-embedded-video-dialog/get-embedded-video-dialog/get-embedded-video-dialog.component';
import { YoutubeSettingsComponent } from './components/streams/youtube-settings/youtube-settings/youtube-settings.component';
import { FacebookSettingsComponent } from './components/streams/facebook-settings/facebook-settings/facebook-settings.component';
import { TwitchSettingsComponent } from './components/streams/twitch-settings/twitch-settings/twitch-settings.component';
import { LinkedInSettingsComponent } from './components/streams/linked-in-settings/linked-in-settings.component';
import { RecentStremsComponent } from './components/recent-strems/recent-strems.component';
import { SetupStreamComponent } from './components/setup-stream/setup-stream.component';
import { PlayerSettingsComponent } from './components/player-settings/player-settings.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { StreamPreviewComponent } from './components/stream-preview/stream-preview.component';
import { ManageAccountDialogComponent } from './components/manage-account-dialog/manage-account-dialog.component';
import { CustomRtmpSettingsComponent } from './components/custom-rtmp-settings/custom-rtmp-settings.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { StatisticComponent } from './components/statistic/statistic.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { LogoutDialogComponent } from './components/auth/logout-dialog/logout-dialog.component';
import { QualityPipe } from './pipes/quality.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { PaymentsComponent } from './components/payments/payments.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PurchaseNamePipe } from './pipes/purchase-name.pipe';
import { VimePlayerComponent } from './components/vime-player/vime-player.component';
import { VimeModule } from '@vime/angular';
import { ForceStopStreamComponent } from './components/streams/force-stop-stream/force-stop-stream.component';
import { SetupSourceComponent } from './components/setup-stream/setup-source/setup-source.component';
import { DestinationsComponent } from './components/setup-stream/destinations/destinations.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ChatComponent } from './components/chat/chat.component';
import { ChartErrorComponent } from './components/chat/chart-error/chart-error.component';
import { MessageComponent } from './components/chat/message/message.component';
import { ChannelsListComponent } from './components/stream-preview/components/channels-list/channels-list.component';
import { ChannelComponent } from './components/stream-preview/components/channel/channel.component';
import { ChannelMenuComponent } from './components/stream-preview/components/channel-menu/channel-menu.component';
import { InstanceInfoComponent } from './components/stream-preview/components/instance-info/instance-info.component';
import { CopyDirective } from './directives/copy.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { IncomingStreamInfoComponent } from './components/stream-preview/components/incoming-stream-info/incoming-stream-info.component';
import { DownloadVideoPageComponent } from './components/download-video-page/download-video-page.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    MainComponent,
    ProfileComponent,
    BillingInfoComponent,
    AppSidebarComponent,
    ConfirmationDialogComponent,
    TimePipe,
    BytePipe,
    FpsSlicePipe,
    StreamVideoPlayerComponent,
    GetEmbeddedVideoDialogComponent,
    YoutubeSettingsComponent,
    FacebookSettingsComponent,
    TwitchSettingsComponent,
    LinkedInSettingsComponent,
    RecentStremsComponent,
    SetupStreamComponent,
    PlayerSettingsComponent,
    CustomSelectComponent,
    StreamPreviewComponent,
    ManageAccountDialogComponent,
    CustomRtmpSettingsComponent,
    ErrorDialogComponent,
    StatisticComponent,
    FilterDropdownComponent,
    PricingComponent,
    LogoutDialogComponent,
    QualityPipe,
    DateFormatPipe,
    PaymentsComponent,
    ResetPasswordComponent,
    PurchaseNamePipe,
    VimePlayerComponent,
    ForceStopStreamComponent,
    SetupSourceComponent,
    DestinationsComponent,
    TooltipComponent,
    ChatComponent,
    ChartErrorComponent,
    MessageComponent,
    ChannelsListComponent,
    ChannelComponent,
    ChannelMenuComponent,
    InstanceInfoComponent,
    CopyDirective,
    IncomingStreamInfoComponent,
    DownloadVideoPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    MatDialogModule,
    MatTooltipModule,
    NgxSpinnerModule,
    VimeModule,
    ClipboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
