import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { mapRestreamItemToChannels } from 'src/app/common/utils';

@Component({
  selector: 'app-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss']
})
export class ChannelsListComponent implements OnInit, OnChanges {
  @Input() streamInstance: any;
  @Input() playerMetaData: any;
  @Output() onRedirectToControl = new EventEmitter();
  @Output() onStopRestreamItem = new EventEmitter();
  @Output() onToggleAccordion = new EventEmitter();
  public channels: any[] = [];
  constructor() { }

  ngOnInit(): void {
    this.channels = mapRestreamItemToChannels(this.streamInstance.restreamItems);
   }

   ngOnChanges(changes: SimpleChanges): void {
    // Check if the streamInstance input property has changed
    if (changes.streamInstance && !changes.streamInstance.firstChange) {
      this.channels = mapRestreamItemToChannels(this.streamInstance.restreamItems);
    }
  }

   toggleAccordion(event: any): void {
    this.onToggleAccordion.emit(event);
  }

  redirectToControl(event: any): void {
    this.onRedirectToControl.emit(event);
  }

  stopRestreamItem(event: any): void {
    this.onStopRestreamItem.emit(event);
  }

}
