import { ErrorService } from './../services/error.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { getSubDomainName } from 'src/app/common/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refreshTokenInProgress = false;
    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private dialog: MatDialog,
        public http: HttpClient,
        private cookieService: CookieService,
        private authService: AuthService,
        private router: Router,
        private errorService: ErrorService,
        private spinner: NgxSpinnerService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (navigator.cookieEnabled && typeof window.localStorage !== 'undefined') {
            if (this.cookieService.get('AccessToken') && this.cookieService.get('RefreshToken')) { req = this.addToken(req); }
        }

        return next.handle(req).pipe(catchError(err => {
            return this.handleResponseError(err, req, next);
        }));
    }

    private addToken(request: HttpRequest<any>): HttpRequest<any> {
        if (!this.cookieService.get('AccessToken') || request.url.includes('https://graph.facebook.com/') || request.url.includes('https://api.ipify.org/')) {
            return request.clone({

            });
        }

        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${this.cookieService.get('AccessToken')}`
            }
        });
    }

    handleResponseError(error: any, request?: any, next?: any): Observable<any> {
        if (error.status === 401) {
            if (request.url.includes('refresh')) {
                this.refreshTokenInProgress = false;
                this.leaveApp();
                return of<any>();
            }

            return this.refreshToken().pipe(
                switchMap(() => {
                    request = this.addToken(request);
                    return next.handle(request);
                }),
                catchError(e => {
                    if (e.status !== 401) {
                        return this.handleResponseError(e);
                    } else {
                        this.leaveApp();
                        return next.handle(request);
                    }
                }));
        }

        this.refreshTokenInProgress = false;
        return this.handleError(error);
    }

    refreshToken(): Observable<any> {
        const url = new URL(window.location.href);

        const ott = url.searchParams.get('ott');

        if (this.refreshTokenInProgress || ott) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return new Observable<any>(obs => {
                this.authService.refreshTokens(this.cookieService.get('RefreshToken')).subscribe(
                    res => {
                        this.cookieService.set('AccessToken', res.AccessToken, 2, '/', getSubDomainName(), true, 'None');
                        this.cookieService.set('RefreshToken', res.RefreshToken, 60, '/', getSubDomainName(), true, 'None');
                        this.refreshTokenInProgress = false;
                        this.tokenRefreshedSource.next();
                        obs.next();
                        obs.complete();
                    },
                    err => {
                        this.refreshTokenInProgress = false;
                        this.leaveApp();
                        obs.error(err);
                    }
                );
            });
        }
    }

    private leaveApp() {
        const url = new URL(window.location.href);

        const ott = url.searchParams.get('ott');

        if (!ott) {
            console.log();
            this.cookieService.set('AccessToken', '', -1, '/', getSubDomainName(), true, 'None');
            this.cookieService.set('RefreshToken', '', -1, '/', getSubDomainName(), true, 'None');
            this.router.navigate(['/login']);
        }
        return null;
    }

    private handleError(error: HttpErrorResponse) {
        console.log('ErrorInterceptor', error)
        if (error.status !== 401) {
            const errorLogSubscript: Subscription = this.errorService.log(`${error.message} Status code: ${error.status.toString()}`).pipe(
                finalize(() => {
                    errorLogSubscript.unsubscribe();
                }),
            )
                .subscribe();

            let message: string = `Http Error. ${error.statusText}. It has been logged and will be fixed. Start from beginning.`;

            if (error.error && !error.error.type) {
                message = error.error.Message;
            }

            if (error.status == 460) {
                this.dialog.open(ErrorDialogComponent, {
                    disableClose: true,
                    data: {
                        error: error.error
                    }
                });
                if (error.error === 'FacebookDuplicate' || error.error === 'GoogleDuplicate' || error.error === 'TwitchDuplicate') {
                    let accessToken = this.cookieService.get('AccessToken');
                    const parsedToken = this.authService.parseAccessToken(accessToken!);
                    
                    if (!parsedToken.redirectUrl) {
                        const channel = new BroadcastChannel('app-data');
                        let facebookDuplicate = {
                            name: 'Duplicate',
                            error: error.error
                        }

                        channel.postMessage(facebookDuplicate);
                    }
                }
            }


            console.log(error);
            //alert(message);
        }

        return throwError(error);
    }
}
