
<div style="display: block;width:800px;margin:0 auto;">
  <vm-player #player playsinline debug="true" (vPlaybackReady)="onPlaybackReady()">
    <vm-hls version="latest" poster="/media/poster.png">
        <source data-src="{{videoUrl}}" type="application/x-mpegURL" />
      </vm-hls>

    <vm-default-ui>
      <!-- Custom UI component. -->
      <tap-sides-to-seek></tap-sides-to-seek>
    </vm-default-ui>
  </vm-player>
</div>
