import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CHAT_DESTINATIONS } from 'src/app/common/constants';
import { mapRestreamItemToChatDestination } from 'src/app/common/utils';
import { ChatDestination, ChatDestinationControlName, ChatMessage } from 'src/app/models/chat';
import { RestreamItem } from 'src/app/models/restream-item';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() restreamItems: RestreamItem[] = [];
  @Input() message: ChatMessage = {} as ChatMessage;
  @Output() onDirectOrReply = new EventEmitter()

  constructor() { }
  ngOnInit(): void {}

  getUserAvatarByRestreamItems(messageRestreamItemId: number): string {
    const restreamItem = this.restreamItems.find((item) => item.id === messageRestreamItemId);
    return restreamItem?.userProfile?.pictureUrl || '../../../assets/images/svg/header/user-icon.svg';
  }

  getUserDestinationIconByRestreamItems(messageRestreamItemId: number): string {
    const item = this.restreamItems.find((restreamItem: RestreamItem) => restreamItem.id == messageRestreamItemId);
    const restreamItemChatDestination = item ? mapRestreamItemToChatDestination([item], CHAT_DESTINATIONS, true) : [];
    return restreamItemChatDestination[0]?.icon || '';
  }

  getUserDestinationFormControlNameByRestreamItems(messageRestreamItemId: number): string {
    const item = this.restreamItems.find((restreamItem: RestreamItem) => restreamItem.id === messageRestreamItemId);
    const restreamItemChatDestination = item ? mapRestreamItemToChatDestination([item], CHAT_DESTINATIONS) : [];
    return restreamItemChatDestination[0]?.formControlName || '';
  }

  isReplyMessageVisible(messageRestreamItemId: number): boolean {
    const item = this.restreamItems.find((restreamItem: RestreamItem) => restreamItem.id === messageRestreamItemId);
    return Boolean(item?.restreamItemAbility?.replyOnChatMessages);
  }

  isSendMessageVisible(messageRestreamItemId: number): boolean {
    // const item = this.restreamItems.find((restreamItem: RestreamItem) => restreamItem.id === messageRestreamItemId);
    // return Boolean(item?.restreamItemAbility?.sendChatMessages);
    // TODO remove this line after DM will be ready
    return false;
  }

  getChatDestinationsByNames(messageDestinations: ChatDestinationControlName[] = []): ChatDestination[] {
    return CHAT_DESTINATIONS.filter(destination => messageDestinations.includes(destination.formControlName));
  }

  replyToMessage(message: ChatMessage, isDirect: boolean = false): void {
    const iconSrc = this.getUserDestinationIconByRestreamItems(message.restreamItemId);
    this.onDirectOrReply.emit({ ...message, isDirect, iconSrc, formControlName: this.getUserDestinationFormControlNameByRestreamItems(message.restreamItemId ) });
  }

  directMessage(message: ChatMessage): void {
    this.onDirectOrReply.emit({ ...message, isDirect: true, iconSrc: this.getUserDestinationIconByRestreamItems(message.restreamItemId), formControlName: this.getUserDestinationFormControlNameByRestreamItems(message.restreamItemId) });
  }

}
