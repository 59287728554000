<div class="linkedin-settings">
  <h4 class="mb-20">Destination settings:</h4>

  <div class="form-group">
    <label class="form-label form-label_md">Account:</label>

    <div class="input-container">
      <figure class="input-account account d-flex align-items-center c-gap-10">
        <span class="account-avatar account-avatar_size-md flex-shrink-0 rounded-circle">
          <img src="{{linkedInConfiguration.userProfile?.pictureUrl}}" alt="profile picture" width="38" height="38">
        </span>
        <figcaption>
          <span class="d-block fw-600">{{linkedInConfiguration.userProfile?.login}}</span>
          <span class="d-block text-xs t-color-gray-light">{{linkedInConfiguration.userProfile?.email}}</span>
        </figcaption>
      </figure>

      <input class="form-control form-control_size-md" type="text" disabled>
      <!-- <button type="button" class="input-tool-btn input-tool-btn_profile">
        <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.167 17.501v-1.667a3.333 3.333 0 00-2.5-3.225M14.167 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H4.167a3.333 3.333 0 00-3.334 3.333V17.5M13.333 2.61a3.333 3.333 0 010 6.458M7.5 9.167a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
            stroke="#fff" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button> -->

    </div>
  </div>

  <div class="form-group">
    <label class="form-label form-label_md" for="stream-name">Stream name*:</label>
    <input class="form-control form-control_size-md"
      [(ngModel)]="this.linkedInConfiguration.streamName" id="stream-name" type="text" name="stream-name"
      placeholder="Enter name here...">
  </div>

  <div class="d-flex justify-content-between mb-20 c-gap-10">
    <div>
      <label class="form-label form-label_md mb-5">Privacy status:</label>
      <span class="t-color-gray-light">Allows you to restrict access to the broadcast.</span>
    </div>
    <app-custom-select [options]="privacyModeList" [selectedElementValue]="this.linkedInConfiguration.privacyStatus" (currentValueChange)="changePrivacyStatus($event)"></app-custom-select>
  </div>

  <div class="d-flex justify-content-between mb-20 c-gap-10">
    <div>
      <label class="form-label form-label_md mb-5">Region:</label>
      <span class="t-color-gray-light"></span>
    </div>
    <app-custom-select [options]="regionList" [selectedElementValue]="this.linkedInConfiguration.region" (currentValueChange)="changeRegion($event)"></app-custom-select>
  </div>

  <div class="input-wrap error-text error-container">
    <span *ngFor="let error of this.errors">{{error}}<br/></span>
  </div>

  <div class="btw-wrapper d-flex align-items-center gap-15 mt-40">
    <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>

    <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" (click)="saveConfigurationUpdate()">Save</button>
  </div>  
</div>