import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-incoming-stream-info',
  templateUrl: './incoming-stream-info.component.html',
  styleUrls: ['./incoming-stream-info.component.scss']
})
export class IncomingStreamInfoComponent implements OnInit {
  @Input( ) playerMetaData: any;

  constructor() {
    console.log(this.playerMetaData, 'playerMetaData')
   }

  ngOnInit(): void {
  }

}
