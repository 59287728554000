
<header class="app-header p-fixed block-w-100 pt-20 p-inline-20"  [ngClass]="windowOfsetY > 5 ? 'scrolled' : '' ">

  <div class="app-header__container wrapper block d-flex justify-content-between align-items-center c-gap-10 transition-300">
    <button
      class="sandwich-btn"
      (click)="isOpenedNavMenu = !isOpenedNavMenu"
      [ngClass]="isOpenedNavMenu ? 'active' : '' "
    >
      <span></span>
    </button>

    <a href="#" class="app-header__logo">
      <figure>
        <img
          src="./../../../assets/images/logo-header.svg"
          alt="Cinamaker Blaster logo"
          width="143"
          height="39"
        >
      </figure>
    </a>

    <nav
      class="nav-menu"
      [ngClass]="isOpenedNavMenu ? 'active' : '' "
    >
      <ul class="nav-menu-list">
        <li class="nav-menu-item" routerLinkActive="active">
          <a class="nav-menu-item__link d-flex align-items-center p-block-10 c-gap-10" routerLink="/recent-streams">
            <figure>
              <img
                src="../../../assets/images/svg/header/start-screen-icon.svg"
                alt=""
                width="24"
                height="24"
                class="transition-300"
              >
            </figure>
            Start Screen
          </a>
        </li>
        <li class="nav-menu-item" routerLinkActive="active">
          <a class="nav-menu-item__link d-flex align-items-center p-block-10 c-gap-10" routerLink="/statistic">
            <figure>
              <img
                src="../../../assets/images/svg/header/statistic-icon.svg"
                alt=""
                width="24"
                height="24"
                class="transition-300"
              >
            </figure>
            Stream Info
          </a>
        </li>
        <li class="nav-menu-item" routerLinkActive="active">
          <a class="nav-menu-item__link d-flex align-items-center p-block-10 c-gap-10" routerLink="/pricing">
            <figure>
              <img
                src="../../../assets/images/svg/header/pricing-icon.svg"
                alt=""
                width="24"
                height="24"
                class="transition-300"
              >
            </figure>
            Pricing
          </a>
        </li>
      </ul>
    </nav>
    <div class="header-profile p-relative">
      <button
        class="header-profile__btn d-flex align-items-center c-gap-5"
        (click)="isOpenedDropdown = !isOpenedDropdown"
        type="button"
      >
        <span class="profile-avatar profile-avatar_sm">

          <!--Need to add check here, if user uploaded profile photo - hide img with icon and show img with  photo  -->

          <img
            src="../../../assets/images/svg/header/user.svg"
            alt=""
            width="16"
            height="16"
          >

          <!-- img with photo -->
<!--          <img
            class="profile-avatar__photo"
            src="../../../assets/images/svg/header/photo.webp"
            alt="profile photo"
            width="32"
            height="32"
          >-->
        </span>

        <i class="icon icon_md arrow arrow-down arrow-color-black"></i>
      </button>

      <div
        [attr.aria-expanded]="isOpenedDropdown"
        class="profile-menu dropdown p-inline-10 t-color-black"
        [ngClass]="isOpenedDropdown ? 'dropdown_showed' : '' "
      >
        <i class="popover-arrow"></i>
          <figure class="d-flex align-items-center flex-col border-bottom pb-20 mb-15">
            <span class="profile-avatar profile-avatar_md mb-10">
              <img
                src="../../../assets/images/svg/header/user.svg"
                alt=""
                width="16"
                height="16"
              >

              <!-- img with photo -->
                <!-- <img
                  class="profile-avatar__photo"
                  src="../../../assets/images/svg/header/photo.webp"
                  alt="profile photo"
                  width="42"
                  height="42"
                > -->
              </span>
             <figcaption class="fw-700 text-center">
               <span  *ngIf="user.FirstName;then firstLastName else login"></span>

               <ng-template #firstLastName><p class="word-break-all">{{user.FirstName}} {{user.LastName}}</p></ng-template>
               <ng-template #login><p class="word-break-all">{{user.Login}}</p></ng-template>

               <p class="fw-400 text-xs word-break-all mt-2">{{user.Email}}</p>
             </figcaption>
          </figure>

        <div class="text-center border-bottom pb-20 mb-20">
          <span class="fw-400 text-xs">License type:</span>
          <p *ngIf="currentPurchase" class="mt-5 fw-700 text-small mb-15">{{currentPurchase.PurchaseTypeId | purchaseName}}</p>
          <p *ngIf="!currentPurchase" class="mt-5 fw-700 text-small mb-15">Free</p>
          <a href="/payments" class="profile-menu__btn btn table-btn table-btn_md btn_outline btn_outline-main rounded-xl-2 text-xs p-inline-10 block-center">Details</a>
        </div>
          <div class="error-text fw-600 text-small">
            <a class="profile-menu__log-out flex-center-center c-gap-15" (click)="logout()">
              <img
                src="../../../assets/images/svg/header/logout-icon.svg"
                alt=""
                width="16"
                height="16"
                class="transition-300"
              >
              Logout
            </a>
          </div>
        </div>
      </div>

    </div>

  </header>
