import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-setup-source',
  templateUrl: './setup-source.component.html',
  styleUrls: ['./setup-source.component.scss']
})
export class SetupSourceComponent implements OnInit {
  @ViewChild('canvas', { static: true }) videoCanvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('video', { static: true }) mainVideoRef!: ElementRef<HTMLVideoElement>;

  public canvasImageDataURI: string | undefined;
  private canvasCtx!: CanvasRenderingContext2D | null;

  @Output() setupSource: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<void> = new EventEmitter();
  public streamForm!: FormGroup;

  constructor(private formBuilder: FormBuilder, public errorService: ErrorService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.streamForm = this.formBuilder.group({
      streamSource: [0, Validators.required],
      saveStreamToDownloads: [false],
      savedFileName: [''],
      streamName: ['', Validators.required],
      streamFromFileFilePath: [''],
      fileFromStream: [null]
    });

    this.setElemetsDefaults();
  }

  setElemetsDefaults() {
    // Disable saveStreamToDownloads based on streamSource value
    const streamSourceControl = this.streamForm.get('streamSource');
    const saveStreamControl = this.streamForm.get('saveStreamToDownloads');
    this.streamForm.get('savedFileName')!.disable();

    if (streamSourceControl && saveStreamControl) {
      streamSourceControl.valueChanges.subscribe(value => {
        if (value !== 0) {
          saveStreamControl.disable();
        } else {
          saveStreamControl.enable();
        }
      });
    }
    this.streamForm.get('saveStreamToDownloads')!.valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        this.streamForm.get('savedFileName')!.enable();
      } else {
        this.streamForm.get('savedFileName')!.disable();
      }
    });
  }

  browseFile() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0] as File;

    this.streamForm.get('streamFromFileFilePath')?.setValue(selectedFile.name);

    this.streamForm.get('fileFromStream')?.setValue(selectedFile);

    const canvas = this.videoCanvasRef.nativeElement as HTMLCanvasElement;
    const video = this.mainVideoRef.nativeElement as HTMLVideoElement;

    this.canvasCtx = canvas.getContext('2d');

    const videoSource = URL.createObjectURL(selectedFile);
    video.src = videoSource;
    video.load();
    video.currentTime = 0.002;

    video.addEventListener('loadedmetadata', () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    });

    video.addEventListener('canplay', () => {
      this.canvasCtx!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      this.canvasImageDataURI = canvas.toDataURL();
    });
  }
  deleteFile() {
    this.canvasImageDataURI = '';
    this.streamForm.get('streamFromFileFilePath')?.setValue('');
    this.streamForm.get('fileFromStream')?.setValue(null);
  }

  getTruncatedValueForStreamFromFileFileName() {
    const value = this.streamForm.get('streamFromFileFilePath')?.value;
    if (value.length <= 25) {
      return value;
    } else {
      return value.substring(0, 25) + '...';
    }
  }

  onSubmit() {
    this.setupSource.emit(this.streamForm.value);
  }
}
