import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UserPlayerSettings } from 'src/app/models/user-player-settings-model'
import Hls from 'hls.js';
import { StreamPreviewService } from 'src/app/services/stram-preview/stream-preview.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
//import { VideoJsPlayer } from "video.js";

@Component({
  selector: 'app-stream-video-player',
  templateUrl: './stream-video-player.component.html',
  styleUrls: ['./stream-video-player.component.scss']
})
export class StreamVideoPlayerComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  constructor(private route: ActivatedRoute,
  private steramPreviewService: StreamPreviewService,
  private dialog: MatDialog) { }
  public videoUrl:string = "";
  private hubConnection!: signalR.HubConnection;

  vmIP: any = '';
  id: any = '';
  userId: any = '';
  streamName: any = '';
  retryCount: number = 0;
  playerInterval: any;
  playerSettings: UserPlayerSettings = new UserPlayerSettings();

  public storageSupported = false;

  public togglePlay: boolean = false;
  public toggleMute: boolean = false;
  public manifestLoaded = false;
  public volumeRanger: any = {
    disabled: false,
    value: 80
  };

  public isPrivateModel: boolean = false;
  public privateStreamKey: string = '';
  allowViewStream: boolean = false;
  showPassWindow: boolean = false;

  public videoElement: any;

  public isStreamCreated: boolean = true;
  public playerHeader: string = "";
  public isRtmps: boolean = false;
  public countOfPlayerViewer: number = 1;

  ngOnInit(): void {

    setInterval(() => {
      this.isRtmps = localStorage.getItem('isRtmps') == 'true';
    }, 1000);

    
    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.PLAYER_VIEWER_HUB, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .build();

  this.hubConnection
    .start()
    .then(() => {
      this.hubConnection!.on("AddedToGroup", () => {
          this.addNewPlayerViewer();
      })
      this.hubConnection!.invoke("AddConnectionToGroup", Number(this.id));
      this.hubConnection!.on("CountOfViewers", (count:number) => {
        this.countOfPlayerViewer = count;
      });
    })
    .catch((err: any) => console.log('Error while starting connection: ' + err))

    if (navigator.cookieEnabled && typeof window.localStorage !== 'undefined') {
      this.storageSupported = true;
    }

    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add("player-body");
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id'); //streamInstanceId
      this.vmIP = params.get('vmIP')
      this.userId = params.get('userId')
      this.streamName = params.get('streamName')
    });

    if (this.id && this.vmIP && this.userId) {
      this.getUserPlayerSettings();
    }

    this.volumeRanger = <HTMLInputElement>document.querySelector('input[type="range"]');
  }
  playHlsVideo() {
    //var url = `https://${this.vmIP}/tv/${this.userId}.m3u8`;
    // var url = `https://${this.vmIP}/tv2/${this.userId}_src.m3u8`;
    var url = `https://${this.vmIP}/tv2/${this.streamName}_src.m3u8`;
    this.videoUrl = url;
    //var url = `https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8`;
    // if(this.id == "x36xhzz"){
    //   url = `https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8`;
    //   this.isStreamCreated = false;
    // }

    this.videoElement = document.getElementById('my_video') as HTMLMediaElement;

    const video = this.videoElement;

    this.playerInterval = setInterval(() => {
      if (Hls.isSupported()) {
        var config
        if (this.isPrivateModel) {
          config = {
            xhrSetup: (xhr: XMLHttpRequest, url: string) => {
              xhr.withCredentials,
                xhr.setRequestHeader('Authorization', "Basic " + this.playerHeader)
            },
            enableWorker: true,
				    lowLatencyMode: true,
				    backBufferLength: 90
          };
        } else{
          config = {
            enableWorker: true,
            lowLatencyMode: true,
            backBufferLength: 90
          };
        }
        const hls = new Hls(config);

        hls.loadSource(url);
        hls.attachMedia(this.videoElement);
        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          console.log(
            'manifest loaded, found ' + data.levels.length + ' quality level'
          );
          clearInterval(this.playerInterval);
          console.log(data);
          video.muted = ""; 
          document.addEventListener("visibilitychange", () => {
            if (document.hidden) {

            } else {
              // video.currentTime = video.duration;
            }
          });         
          video.play()?.then(() => {
            this.manifestLoaded = true;
            console.log("Play video started");
            this.togglePlay = !this.togglePlay;
            clearInterval(this.playerInterval);
          })
          .catch((error:any) => {
            this.manifestLoaded = true;
            console.log(error);
          });
        });

        hls.on(Hls.Events.ERROR, (e, errorData: any) => {
          console.log(errorData);
          if (errorData.fatal) {
            switch (errorData.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                console.log('fatal network error encountered, try to recover');
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log('fatal media error encountered, try to recover');
                hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                break;
            }
          }
          /*if (errorData && errorData?.response?.code == 404) {
            this.retryCount++;
            if (this.retryCount > 60) {
              clearInterval(this.playerInterval);
              alert("Stream not found. Please try to reload the page.");
              console.clear();
            }
          } else {
            console.log(errorData);
            clearInterval(this.playerInterval);
          }*/
        });
      } else {
        this.dialog.open(ErrorDialogComponent, {
          data: {
              error: "Your browser is not supported this video."
          }
        });
        //alert("Your browser is not supported this video.")
      }
    }, 5000);
  }

  playPause(): void {
    this.togglePlay = !this.togglePlay;

    if (this.videoElement.paused) {
      this.videoElement = document.getElementById('my_video') as HTMLMediaElement;

      const video = this.videoElement;
      video.currentTime = video.duration - 10;
      this.videoElement.play();
    }
    else {
      this.videoElement.pause();
    }
  }

  rangeValue(): void {
    this.videoElement.volume = this.volumeRanger.value / 100;
  }

  muteUnmuteVideo(): void {
    this.toggleMute = !this.toggleMute;

    if (this.videoElement.muted) {
      this.videoElement.muted = "";
      this.volumeRanger.disabled = false;
      this.volumeRanger.value = 80;
    }
    else {
      this.videoElement.muted = "muted";
      this.volumeRanger.disabled = true;
      this.volumeRanger.value = 0;
    }
  }


  // getIsPrivateMode(){
  //   var userId = this.userId;
  //   if(this.id == "x36xhzz"){
  //     userId = this.userId;
  //     this.isPrivateModel = false;
  //     this.allowViewStream = true;

  //     if (Hls.isSupported() && !this.isPrivateModel) {
  //       this.playHlsVideo();
  //     }

  //   } else{
  //     this.authService.checkPlayerPrivacyMode(userId).subscribe((data: boolean) => {
  //       this.isPrivateModel = data;
  //       this.allowViewStream = !data;

  //       if(this.storageSupported){
  //         this.privateStreamKey = localStorage.getItem("privateKey") as string;
  //       }

  //       if(this.isPrivateModel && this.privateStreamKey){          
  //         this.checkPrivateStreamKey();
  //       } else{
  //         if (Hls.isSupported() && !this.isPrivateModel) {
  //           this.playHlsVideo();
  //         }
  //       }
  //     });
  //   }
  // }

  getUserPlayerSettings() {
    this.subscriptions.add(
      this.steramPreviewService.getPlayrSettings(this.id).subscribe({
        next: (data: UserPlayerSettings) => {
          this.playerSettings = data;
          if (this.playerSettings.isPrivateStream) {
            this.isPrivateModel = true;

            if (this.storageSupported) {
              this.privateStreamKey = localStorage.getItem("privateKey") as string;
              if (!this.privateStreamKey || this.privateStreamKey == '')
                this.showPassWindow = true;
            } else{
              this.showPassWindow = true;
            }
            if (this.privateStreamKey) {
              this.checkPrivateStreamKey();
            }
          } else if (Hls.isSupported()) {
            this.playHlsVideo();
          }
        },
        error: (error) => {
          console.log(error);
        }
      })
    );
  }

  enterPrivateKey(event: any) {
    if (this.storageSupported) {
      localStorage.setItem("privateKey", event.value);
    }
    else{
      this.privateStreamKey = event.value;
    }

    if (this.id && this.vmIP && this.userId) {
      this.getUserPlayerSettings();
    }
  }

  checkPrivateStreamKey() {
    this.subscriptions.add(
      this.steramPreviewService.validatePrivateStreamKey(this.id, this.privateStreamKey, parseInt(this.userId)).subscribe({
        next: (data) => {
          if (data.authHeader && Hls.isSupported()) {
            this.playerHeader = data.authHeader;
            if (this.storageSupported) {
              localStorage.setItem("privateKey", this.privateStreamKey);
            }

            this.showPassWindow = false;
            this.allowViewStream = true;
            this.playHlsVideo();
          } else {
            if (this.storageSupported) {
              localStorage.setItem("privateKey", "");
            }

            this.playerHeader = "";
            this.showPassWindow = true;
            this.allowViewStream = false;

            this.dialog.open(ErrorDialogComponent, {
              data: {
                  error: "Key is not valid."
              }
            });
            //alert("Key is not valid");
          }
        },
        error: (error) => {
          console.log(error);
        }
      })
    );
  }

  toggleFullscreen() {
    let elem = this.videoElement;

    if (!document.fullscreenElement) {
      // elem.requestFullscreen().catch((err: any) => {
      //   this.dialog.open(ErrorDialogComponent, {
      //     data: {
      //         error: `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
      //     }
      //   });
      //   //alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
      // });
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  }

  addNewPlayerViewer(){
    this.subscriptions.add(
      this.steramPreviewService.getMyIp().subscribe({
        next: (data:any) => {
          this.subscriptions.add(
            this.steramPreviewService.addPlayerViewer(this.id, data.ip).subscribe({
              next: (count:any) => {
                this.countOfPlayerViewer = count;
              },
              error: (err) => {
                console.log(err);
              }
            })
          );
      
        },
        error: (err) => {
          console.log(err);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}