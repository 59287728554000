import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestreamItem } from 'src/app/models/restream-item';
import { StreamInstance } from 'src/app/models/stream-instance';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecentStreamService {
  constructor(private http: HttpClient) { }

  getRecentStreams(): Observable<RestreamItem[]> {
    return this.http.get<RestreamItem[]>(environment.BLUSTER_API_URL + 'Statistic/recent-streams')
      .pipe(map(data => data));
  }

  searchStreams(searchModel: any): Observable<StreamInstance[]> {
    let options = { params: searchModel };
    return this.http.get<StreamInstance[]>(environment.BLUSTER_API_URL + 'Statistic/search-streams', options);
  }

  initNewStream(): Observable<number> {
    return this.http.post<number>(environment.BLUSTER_API_URL + 'Restream/create-stream', null);
  }

  getResultVideoLink(pwd: string): Observable<any> {
    pwd = pwd.replace(' ', '+');
    const identifier = encodeURIComponent(pwd);
    const data = { Pwd: identifier };
    return this.http.post<any>(
      `${environment.BLUSTER_API_URL}Converter/generate-presignedurl-for-result-video`,
      data
    );
  }

  generateUrlForDownloadVideo(item: StreamInstance): Observable<any> {
    console.log(item, "STREAM INSTANCE")
    return this.http.get<any>(
      `${environment.BLUSTER_API_URL}Converter/get-url-for-result-video/${item.id}`,
    );
  }

}
