import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RTMPS_URL_PATTERN, RTMP_URL_PATTERN } from 'src/app/common/constants/destinations';
import { RestreamItem } from 'src/app/models/restream-item';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';

@Component({
  selector: 'app-custom-rtmp-settings',
  templateUrl: './custom-rtmp-settings.component.html',
  styleUrls: ['./custom-rtmp-settings.component.css']
})
export class CustomRtmpSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rtmpConfiguration: RestreamItem = this.data.item;
  @Output() validateDestinations = new EventEmitter()
  @Output() updatedRestreamItem = new EventEmitter()

  private subscriptions = new Subscription();
  error: any;
  searchTimeoutId: any;
  public rtmpConfigurationForm!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CustomRtmpSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(rtmpConfiguration = this.rtmpConfiguration) {
    this.rtmpConfigurationForm = this.formBuilder.group({
      rtmpUrl: [rtmpConfiguration.serviceConfigurationType === 5 ? '' : rtmpConfiguration.rtmpUrl, [this.getRtmpUrlRequiredValidators(), Validators.pattern(RTMP_URL_PATTERN)]],
      rtmpsUrl: [rtmpConfiguration.serviceConfigurationType === 3 ? '' : rtmpConfiguration.rtmpUrl, [this.getRtmpsUrlRequiredValidators(), Validators.pattern(RTMPS_URL_PATTERN)]],
      streamKey: [rtmpConfiguration.streamKey, Validators.required],
      streamName: [rtmpConfiguration.streamName, Validators.required]
    });
  }

   ngOnChanges(changes: SimpleChanges): void {
    if (changes.rtmpConfiguration) {
      this.initializeForm(changes?.rtmpConfiguration?.currentValue);
    }
  }
  
  getRtmpUrlRequiredValidators() {
    return (control: AbstractControl) => {
      if ( this.rtmpConfiguration.serviceConfigurationType === 3) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }

  getRtmpsUrlRequiredValidators() {
    return (control: AbstractControl) => {
      if (this.rtmpConfiguration.serviceConfigurationType === 5) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }

  validateRTMP() {
    if (!this.rtmpConfiguration.streamName) {
      this.error = 'Stream title is required';
    }
  }

  validateRtmpUrl() {
    if (this.rtmpConfiguration.rtmpUrl) {
      this.setupStreamService.validteRTMP(this.rtmpConfiguration.rtmpUrl).subscribe((validateResult: any) => {
        if (validateResult) {
          this.rtmpConfigurationForm.get('rtmpUrl')?.setErrors({ customError: true });
          this.rtmpConfigurationForm.get('rtmpsUrl')?.setErrors({ customError: true });
        }
        else {
          this.rtmpConfigurationForm.get('rtmpUrl')?.setErrors(null);
          this.rtmpConfigurationForm.get('rtmpsUrl')?.setErrors(null);
        }
      });
    }
  }

  onClose(): void {
    this.rtmpConfigurationForm.reset();
       Object.keys(this.rtmpConfigurationForm.controls).forEach(controlName => {
      this.rtmpConfigurationForm.get(controlName)?.setValue('');
    });
    this.dialogRef.close(false);
  }

  saveConfigurationUpdate(): void {
    console.log(this.rtmpConfiguration.serviceConfigurationType, 'rtmpConfiguration.serviceConfigurationType')
    console.log(this.rtmpConfigurationForm.value)
   console.log(this.rtmpConfigurationForm.invalid, 'this.rtmpConfigurationForm.invalid')
  if (this.rtmpConfigurationForm.invalid) {
    Object.keys(this.rtmpConfigurationForm.controls).forEach(controlName => {
      const control = this.rtmpConfigurationForm.get(controlName);
      if (control?.errors) {
        console.log(`Control: ${controlName}, Errors:`, control.errors);
      }
    });
  }
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.error = null;
      this.validateRTMP();
      const dataToServer = {
        ...this.rtmpConfiguration,
        ...this.rtmpConfigurationForm.value,
        rtmpUrl: this.rtmpConfigurationForm.value.rtmpsUrl ? this.rtmpConfigurationForm.value.rtmpsUrl : this.rtmpConfigurationForm.value.rtmpUrl,
      }
      this.subscriptions.add(
        this.setupStreamService.updateCustomRtmpSettings(dataToServer).subscribe({
          next: (data: RestreamItem) => {
           this.validateDestinations.emit();
            this.updatedRestreamItem.emit(data);
            this.dialogRef.close(false);
          },
          error: (error) => {
            console.log(error);
          }
        })
      );
    }, 500);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}