import { ErrorService } from '../services/error.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(private injector: Injector,
    private spinner: NgxSpinnerService) { }

  get errorService(): ErrorService {
    return this.injector.get(ErrorService);
  };

  handleError(error: any) {

    if (error instanceof Error || error instanceof TypeError) {
      const errorLogSubscript: Subscription = this.errorService.log(error.stack!).pipe(
        finalize(() => {
          errorLogSubscript.unsubscribe();
        }),
      )
        .subscribe(() => {});

      console.log(error)
      //alert(`Error. It has been logged and will be fixed. Start from beginning.`);
    }
  }
}
