import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-account-dialog',
  templateUrl: './manage-account-dialog.component.html',
  styleUrls: ['./manage-account-dialog.component.css']
})
export class ManageAccountDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ManageAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any){}

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
