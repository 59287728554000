export const STREAM_NAME_REQUIRED = 'Stream name is required';
export const STREAM_NAME_LENGHT = 'Stream name can not be longer than 100 characters';

export const DestinationErrors = {
    ZERO_DESTINATIONS: "You can't start stream without any destination",
    RTMPS_NAME_EMPTY: "Please add stream name for Custom RTMPS",
    YT_NAME_EMPTY: "Please add stream name for YouTube",
    TW_NAME_EMPTY: "Please add stream name for Twitch",
    INVALID_TW_TITLE_SIZE: "Stream title max size is 100 chars for Twitch",
    FB_NAME_EMPTY: "Please add stream name for Facebook",
    INVALID_FB_TITLE_SIZE: 'Stream title max size is 254 chars for Facebook',
    LN_NAME_EMPTY: "Please add stream name for LinkedIn",
    INVALID_LN_TITLE_SIZE: "Stream title max size is 100 chars for LinkedIn",
    INVALID_RTMP_URL: (streamName: string) => `Please add a valid rtmps url for ${streamName} Custom RTMPS`,
    WRONG_FPS_YT: (streamName: string) => `Choose 30fps or variable frame rate for ${streamName} YouTube`,
    WRONG_RESOLUTION_YT: (streamName: string) => `Choose variable frame rate and variable resolution for ${streamName} YouTube`,
    INVALID_YT_TITLE_SIZE: (streamName: string) => `Stream title max size is 100 chars for ${streamName} YouTube`,
    INVALID_YT_description_SIZE: (streamName: string) => `Stream description max size is 5000 chars for ${streamName} YouTube`,
    WRONG_FPS_YT_PART2: (streamName: string) => `Choose 60fps or variable frame rate for ${streamName} YouTube`,
    WRONG_LATENCY_YT: (streamName: string) => `Choose UltraLow latency for current frame rate for ${streamName} YouTube`,
    INVALID_FB_description_SIZE: (streamName: string) => `Stream description max size is 5000 chars for ${streamName} Facebook`,
    FB_EMPTY_STREAMTYPE: (streamName: string, streamType: string) => `Please choose ${streamType} for ${streamName} Facebook`,
};