import { Component, OnInit } from '@angular/core';
import { PurchaseService } from 'src/app/services/purchase-service/purchase.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  constructor(
    private purchaseService: PurchaseService
  ) { }

  userPurchases: any = [];
  currentPurchase: any;

  ngOnInit(): void {
    this.purchaseService.getPurchases().subscribe(res => {
      {
        this.userPurchases = res.PurchaseList.filter((x: any) => {
          x.BeginDate = new Date(x.BeginDate * 1000);
          x.EndDate = new Date(x.EndDate * 1000);
          return x.PurchaseTypeId.includes('blaster');
        });

        this.userPurchases.sort((n1: any, n2: any) => {
          if (n1.EndDate > n2.EndDate) {
            return -1;
          }

          if (n1.EndDate < n2.EndDate) {
            return 1;
          }

          return 0;
        });

        this.currentPurchase = this.userPurchases.find((x: any) => x.BeginDate < Date.now() && x.EndDate > Date.now());
      }
    },
    err => {} );
  }

}
