import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-channel-menu',
  templateUrl: './channel-menu.component.html',
  styleUrls: ['./channel-menu.component.scss']
})
export class ChannelMenuComponent implements OnInit {
  @Input() item: any
  @Input() streamInstance: any
  @Output() onRedirectToControl = new EventEmitter()
  @Output() onStopRestreamItem = new EventEmitter<{itemId: string, streamInstanceId: string}>()
  @ViewChild('menu')
  set subMenu(value: MatMenu)  {
  this.item.elementRef = value;
}

  constructor() { }

  ngOnInit(): void {
  }

  redirectToControl(): void {
    this.onRedirectToControl.emit(this.item);
  }
  stopRestreamItem(): void {
    this.onStopRestreamItem.emit({itemId: this.item.id, streamInstanceId: this.streamInstance.id});
  }

}
