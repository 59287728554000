import { Destination } from "src/app/models/destination";
import { ServiceConfigurationType } from "src/app/models/enums/ServiceConfigurationType";

export const DESTINATIONS: Destination[] = [
        {
        iconUrl: "/assets/images/svg/streams-destinations/play-circle-icon.svg",
        title: "Web Player",
        alt: "Web Player"
    },
    {
        iconUrl: 'assets/images/svg/streams-destinations/youtube-icon.svg',
        title: 'YouTube',
        alt: 'youtube',
        serviceConfigurationType: ServiceConfigurationType.YouTube
    },
    {
        iconUrl: 'assets/images/svg/destinations-channels-icons/facebook-icon.svg',
        title: 'Facebook',
        alt: 'facebook',
        serviceConfigurationType: ServiceConfigurationType.Facebook
    },
    {
        iconUrl: 'assets/images/svg/streams-destinations/linkedin-icon.svg',
        title: 'LinkedIn',
        alt: 'linkedinMenu',
        serviceConfigurationType: ServiceConfigurationType.LinkedIn
    },
    {
        iconUrl: 'assets/images/svg/streams-destinations/twitch-icon.svg',
        title: 'Twitch',
        alt: 'twitch',
        serviceConfigurationType: ServiceConfigurationType.Twitch
    },
    {
        iconUrl: 'assets/images/svg/destinations-channels-icons/rtmp-icon.svg',
        title: 'RTMP',
        alt: 'rtmp',
        serviceConfigurationType: ServiceConfigurationType.CustomRTMP
    },
    {
        iconUrl: 'assets/images/svg/destinations-channels-icons/RTMPs-icon.svg',
        title: 'RTMPs',
        alt: 'rtmps',
        serviceConfigurationType: ServiceConfigurationType.CustomRTMPS
    }
];

export const DESTINATIONS_OPTIONS: Destination[] = [
        {
        iconUrl: "/assets/images/svg/destinations-options/play-circle-icon.svg",
        title: "Web Player",
        alt: "Embedded Web Player",
        iconForChannel: 'assets/images/svg/destinations-channels-icons/play-circle-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-options/youtube-icon.svg',
        title: 'YouTube',
        alt: 'youtube',
        serviceConfigurationType: ServiceConfigurationType.YouTube,
        iconForChannel: 'assets/images/svg/destinations-channels-icons/youtube-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-options/facebook-icon.svg',
        title: 'Facebook',
        alt: 'facebook',
        serviceConfigurationType: ServiceConfigurationType.Facebook,
        iconForChannel: 'assets/images/svg/destinations-channels-icons/facebook-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-options/linkedin-icon.svg',
        title: 'LinkedIn',
        alt: 'linkedinMenu',
        serviceConfigurationType: ServiceConfigurationType.LinkedIn,
        iconForChannel: 'assets/images/svg/destinations-channels-icons/linkedin-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-options/twitch-icon.svg',
        title: 'Twitch',
        alt: 'twitch',
        serviceConfigurationType: ServiceConfigurationType.Twitch,
        iconForChannel: 'assets/images/svg/destinations-channels-icons/twitch-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-options/rtmp-icon.svg',
        title: 'RTMP',
        alt: 'rtmp',
        serviceConfigurationType: ServiceConfigurationType.CustomRTMP,
        iconForChannel: 'assets/images/svg/destinations-channels-icons/rtmp-icon.svg'
    },
    {
        iconUrl: 'assets/images/svg/destinations-channels-icons/RTMPs-icon.svg',
        title: 'RTMPs',
        alt: 'rtmps',
        serviceConfigurationType: ServiceConfigurationType.CustomRTMPS,
        
    }
];

export const RTMP_URL_PATTERN = /^((http|rtmp):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const RTMPS_URL_PATTERN = /^((https|rtmps):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;