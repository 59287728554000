<div class="facebook-settings">
  <h4 class="mb-20">Destination settings:</h4>

  <!-- title -->
  <form [formGroup]="rtmpConfigurationForm" (ngSubmit)="saveConfigurationUpdate()">
    <div class="form-group">
      <label class="form-label form-label_md" for="stream-name">Title:</label>
      <input class="form-control form-control_size-md" formControlName="streamName" id="stream-name" type="text" placeholder="Enter title here...">
    </div>

    <!-- rtmp url -->
    <div class="form-group" *ngIf="rtmpConfiguration.serviceConfigurationType == 3">
      <label class="form-label form-label_md" for="stream-name">RTMP url*:</label>
      <input class="form-control form-control_size-md" id="streamRTMPUrl" formControlName="rtmpUrl" type="url" placeholder="RTMP://">

      <!-- Display errors for rtmpUrl -->
      <div *ngIf="rtmpConfigurationForm.get('rtmpUrl')?.invalid && (rtmpConfigurationForm.get('rtmpUrl')?.dirty || rtmpConfigurationForm.get('rtmpUrl')?.touched)">
        <p class="info" *ngIf="rtmpConfigurationForm.get('rtmpUrl')?.hasError('required')">Rtmp url is required</p>
        <p class="info" *ngIf="rtmpConfigurationForm.get('rtmpUrl')?.hasError('pattern')">Invalid rtmp url</p>
      </div>
    </div>

    <!-- rtmps url -->
    <div class="form-group" *ngIf="rtmpConfiguration.serviceConfigurationType == 5">
      <label class="form-label form-label_md" for="stream-name">RTMPs url:</label>
      <input class="form-control form-control_size-md" id="streamRTMPUrl" formControlName="rtmpsUrl" type="url" placeholder="RTMPS://">

      <!-- Display errors for rtmpsUrl -->
      <div *ngIf="rtmpConfigurationForm.get('rtmpsUrl')?.invalid && (rtmpConfigurationForm.get('rtmpsUrl')?.dirty || rtmpConfigurationForm.get('rtmpsUrl')?.touched)">
        <p class="info" *ngIf="rtmpConfigurationForm.get('rtmpsUrl')?.hasError('required')">Rtmps url is required</p>
        <p class="info" *ngIf="rtmpConfigurationForm.get('rtmpsUrl')?.hasError('pattern')">Invalid rtmp url</p>
      </div>
    </div>

    <!-- stream key -->
    <div class="form-group">
      <label class="form-label form-label_md" for="stream-name">Stream Key:</label>
      <input class="form-control form-control_size-md" formControlName="streamKey" type="text" placeholder="Stream Key">
    </div>

    <div class="btw-wrapper d-flex align-items-center gap-15 mt-40">
      <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>

      <button [disabled]="rtmpConfigurationForm.invalid" class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" type="submit">Save</button>
    </div>
  </form>
</div>
