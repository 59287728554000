<div class="dialog dialog-copy-player">
    <div class="dialog__title-container">
      <h5 class="dialog__title dialog__title_md text-center mb-30">Stream is still running</h5>
    </div>
    <div style="margin-bottom:15px;text-align:center;" class="dialog__title">
        Are you sure want to stop stream?
    </div>
    <div class="dialog__actions">
        <button class="btn btn_size-primary btn_outline btn_outline-main btn_fluid btn_rounded-md" (pointerdown)="stopStreamAnyway()">Yes</button>
        <button class="btn btn_size-primary btn_color-secondary btn_fluid btn_rounded-md" (click)="close()">No</button>
    </div>
</div>