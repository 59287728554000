import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class MenuGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = new URL(window.location.href);

    // Get the ott parameter
    const ott = url.searchParams.get('ott');
    if (!this.cookieService.get('AccessToken') && !this.cookieService.get('RefreshToken') && !ott) {
      this.router.navigate(['/login']);
    }
    return true;
  }
  
}
