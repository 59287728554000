<div class="player-settings">
  <h4 class="mb-20">Player settings:</h4>

  <div
    class="player-settings__controls d-flex justify-content-between align-items-center flex-wrap r-gap-50 c-gap-30 mb-30">

    <!-- volume control -->
    <div class="d-flex align-items-center c-gap-30">
      <label class="checkbox-container">
        <input type="checkbox" name="volume-control" [(ngModel)]="playerSettings.allowVolumeControl" #ctrl="ngModel">
        <span class="checkbox"></span>
        <span>Allow volume control</span>
      </label>
      <app-tooltip tooltipText="Allows you and your visitors to control and adjust the sound in the embedded player."></app-tooltip>
    </div>

    <!-- full screen mode -->
    <div class="d-flex align-items-center c-gap-30">
      <label class="checkbox-container">
        <input type="checkbox" name="fullscreen-mode" [(ngModel)]="playerSettings.allowFullScreenControl"
          #ctrl="ngModel">
        <span class="checkbox"></span>

        <span>Fullscreen mode</span>
      </label>
      <app-tooltip
        tooltipText="Allows you and your visitors to use the full screen mode in the embedded player."></app-tooltip>
    </div>

    <!-- secure mode -->
    <div class="d-flex align-items-center c-gap-30">
      <label class="checkbox-container">
        <input type="checkbox" name="secure-mode" [(ngModel)]="playerSettings.isPrivateStream"
          (change)="generatePassword()" #ctrl="ngModel">
        <span class="checkbox"></span>
        <span>Secure mode</span>
      </label>
      <app-tooltip tooltipText="Allows you to secure your stream with the password."></app-tooltip>
    </div>
  </div>

  <!-- password -->
  <div class="player-settings__pass-block">
    <div class="form-group transition-300" [ngClass]="{'disabled': !playerSettings.isPrivateStream}">
      <div class="d-flex justify-content-between c-gap-10">
        <label class="form-label" for="player-password">Player password:</label>
        <label [ngClass]="copiedToClipBoard ? 'form-label-hint_showed' : ''"
          class="form-label form-label-hint pr-20 text-right t-color-primary fw-600">Copied to clipboard</label>
      </div>

      <div class="pass-block-container">
        <div class="input-container several-buttons">
          <input class="form-control form-control_size-md" id="player-password"
            [(ngModel)]="playerSettings.privateStreamKey" type="text" name="player-password">
          <button type="button" class="input-tool-btn input-tool-btn_size-md" (pointerdown)="copyToClipboard()">
            <i class="icon icon_md icon_copy"></i>
          </button>
        </div>
        <button class="btn btn_size-primary btn_outline btn_outline-secondary btn_rounded-sm"
          (click)="generatePassword()">Generate</button>
      </div>
    </div>

  </div>

  <div class="d-flex justify-content-center align-items-center gap-15 mt-40">
    <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md"
      (click)="onClose()">Cancel</button>
    <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md"
      (click)="setPlayerSettings()">Save</button>
  </div>

</div>
