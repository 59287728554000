<div class="settings-modal">
  <h4 class="mb-20 pl-20">Destination settings:</h4>

  <!-- account -->
  <div class="form-group">
    <label class="form-label form-label_md">Account:</label>

    <div class="input-container d-flex align-content-center justify-content-between gap-15">
      <div class="block-w-100 p-relative">
        <figure class="input-account account d-flex align-items-center c-gap-10">
          <span class="account-avatar account-avatar_size-md flex-shrink-0 rounded-circle">
            <img src="{{youtubeConfiguration.userProfile?.pictureUrl}}" alt="profile picture" width="38" height="38">
          </span>
          <figcaption>
            <span class="d-block fw-600">{{youtubeConfiguration.userProfile?.login}}</span>
            <span class="d-block text-xs t-color-gray-light">{{youtubeConfiguration.userProfile?.email}}</span>
          </figcaption>
        </figure>

        <input class="form-control form-control_size-md" type="text" disabled>
      </div>

    <button type="button" class="input-tool-btn_profile disabled">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M12.5556 13.4444L17 9M17 9L12.5556 4.55556M17 9H6.33333M6.33333 17H2.77778C2.30628 17 1.8541 16.8127 1.5207 16.4793C1.1873 16.1459 1 15.6937 1 15.2222V2.77778C1 2.30628 1.1873 1.8541 1.5207 1.5207C1.8541 1.1873 2.30628 1 2.77778 1H6.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Logout</span>
    </button>

    </div>
  </div>

  <!-- stream name -->
  <div class="form-group">
    <label class="form-label form-label_md" for="stream-name">Stream name*:</label>
    <input [(ngModel)]="youtubeConfiguration.streamName" required class="form-control form-control_size-md" id="stream-name" type="text" name="stream-name" placeholder="Enter name here...">
  </div>

  <!-- description -->
  <div class="form-group">
    <label class="form-label form-label_md" for="description">Description:</label>
    <textarea [(ngModel)]="youtubeConfiguration.description" class="form-control form-control_size-md" name="description" id="description" rows="4" placeholder="Enter description here..."></textarea>
  </div>

  <!-- settings -->
  <div class="pl-20">
    <div class="d-flex align-items-center justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Resolution:</label>
        <span class="t-color-gray-light">Stream quality. The higher the better.</span>
      </div>
      <app-custom-select [options]="resolutionList" [selectedElementValue]="this.youtubeConfiguration.resolution" (currentValueChange)="changeResolution($event)"></app-custom-select>
    </div>

    <div class="d-flex justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Frame rate:</label>
        <span class="t-color-gray-light">The more frames, the smoother the picture.</span>
      </div>
      <app-custom-select [options]="frameRateList" [selectedElementValue]="this.youtubeConfiguration.frameRate" (currentValueChange)="changeFrameRate($event)"></app-custom-select>
    </div>

    <div class="d-flex justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Privacy status:</label>
        <span class="t-color-gray-light">Allows you to restrict access to the broadcast.</span>
      </div>
      <app-custom-select [options]="privacyModeList" [selectedElementValue]="this.youtubeConfiguration.privacyStatus" (currentValueChange)="changePrivacyStatus($event)"></app-custom-select>
    </div>

    <div class="d-flex justify-content-between mb-20 c-gap-10">
      <div>
        <label class="form-label form-label_md mb-5">Latency preference:</label>
        <span class="t-color-gray-light">Controls the broadcast delay.</span>
      </div>
      <app-custom-select [options]="latencyList" [selectedElementValue]="this.youtubeConfiguration.latencyPreference" (currentValueChange)="changeLatency($event)"></app-custom-select>
    </div>
  </div>

  <!-- video for kids -->
  <label class="checkbox-container pl-20">
    <input type="checkbox" name="kids" [(ngModel)]="this.youtubeConfiguration.selfDeclaredMadeForKids">
    <span class="checkbox"></span>
    <span>Video for kids</span>
  </label>

  <!-- error message -->
  <div class="input-wrap error-text error-container mb-20">
    <span class="pl-20" *ngFor="let error of this.errors">{{error}}<br/></span>
  </div>

  <div class="btw-wrapper d-flex align-items-center gap-15 mt-40">
    <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>

    <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" (click)="saveConfigurationUpdate()">Save</button>
  </div>

</div>
