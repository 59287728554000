import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'purchaseName'
})
export class PurchaseNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    if(value.includes('mounthly')){
      return 'Blaster - monthly subscription';
    }
    
    if(value.includes('annual')){
      return 'Blaster - annual subscription';
    }

    return 'invalid purchase';
  }
}
