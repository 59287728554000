<div class="app d-flex flex-col bg-app-main">
  <app-sidebar></app-sidebar>

  <main class="d-flex flex-col">
    <section class="d-flex flex-col flex-1">
      <div class="container d-flex flex-col flex-1">
        <h2 class="screen-reader-only ">Stream Preview</h2>
        <div class="block wrapper d-flex flex-col flex-1 pt-25 pb-60">

          <div class="stream-preview-page d-grid c-gap-50 r-gap-30"
            [ngClass]="streamInstance.restreamItems && streamInstance.restreamItems.length && isChatsAllowed ? 'three-columnns' : 'two-columnns'">
            <!-- info -->
            <div class="stream-preview-page__info-block mt-10">
              <!-- Instance info -->
              <app-instance-info *ngIf="streamInstance && streamSource == 0" (onToggleAccordion)="toggleAccordion($event)"
                [rtmpUrl]="rtmpUrl" [rtmpsUrl]="rtmpsUrl" [streamKey]="streamKey">
              </app-instance-info>
              <!-- Destinations -->
              <app-channels-list *ngIf="streamInstance.restreamItems && streamInstance.restreamItems.length"
                [streamInstance]="streamInstance" (onToggleAccordion)="toggleAccordion($event)"
                (onStopRestreamItem)="stopRestreamItem($event)" (onRedirectToControl)="redirectToControl($event)"
                [playerMetaData]="playerMetaData" [streamId]="streamId"></app-channels-list>
            </div>

            <!-- player -->

            <div class="player-container">
              <h3 class="fw-600 ff-gilroy t-color-black">Stream preview</h3>

              <div class="stream-video mb-10 mt-10">
                <iframe id="video-frame" [src]="iframeUrlSafe" title="Video" frameborder="0" allowfullscreen>
                </iframe>
                <!-- info block -->
                <div class="stream-video__info-block p-absolute d-flex align-items-center c-gap-10 transition-300">
                  <div class="p-relative">
                    <!-- toggle class active to show/hide preview-stream-info panel -->
                    <button (click)="showStreamInfo()" [ngClass]="{'active': showStreamInfoBlock}"
                      class="show-stream-preview-info btn btn_size-sm btn_color-white text-md  btn_rounded-xs"
                      type="button">Info</button>

                    <app-incoming-stream-info *ngIf="showStreamInfoBlock"
                      [playerMetaData]="playerMetaData"></app-incoming-stream-info>
                  </div>
                  <button *ngIf="streamInstance.isPlayerSettingsEnabled" (click)="getEmbeddedVideo()"
                    class="btn btn_size-sm bg-primary-blue t-color-white text-md btn_rounded-xs" type="button">
                    < Embed player>
                  </button>
                </div>
              </div>

              <div
                class="stream-bottom-block d-flex justify-content-between align-items-center bg-table p-block-20 p-inline-30 rounded-md gap-20">
                <!-- <h4 class="text-center">Introduce to Graphic Design</h4> -->
                <button class="btn btn_size-md btn_outline btn_outline-red btn_rounded-sm text-md block-right"
                  *ngIf="!streamInstance.isFinished" (click)="stopStream()" type="button">Stop Stream</button>
              </div>
            </div>

            <div class="d-flex flex-col flex-1"
              *ngIf="streamInstance.restreamItems && streamInstance.restreamItems.length && isChatsAllowed">
              <div class="d-flex align-items-center">
                <h3 class="fw-600 ff-gilroy t-color-black mr-10">Chats</h3>
                  <img *ngIf="checkRestreamItemsAbility()" matTooltipPosition="above" matTooltip="Some of your destinations or channels has chats and comments limits. Messages and comments from these destinations will not be visible on this page" src="../../../assets/images/svg/chat-hint.svg" alt="help icon" width="24" height="24" />
              </div>
              <div class="stream-preview-page__info-block pt-10">
                <app-chat [vmIP]="streamInstance.vmDomainSsl || ''" [streamId]="streamId"
                  [restreamItems]="streamInstance.restreamItems"></app-chat>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </main>
</div>