import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RestreamItem } from 'src/app/models/restream-item';
import { map } from 'rxjs/operators';
import { StreamInstance } from 'src/app/models/stream-instance';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private http: HttpClient) { }

  checkFunctionalities() : Observable<any>{
    return this.http.get<any>(environment.BLUSTER_API_URL + 'Purchase/check-functionalities');
  }

  getPurchases(): Observable<any>{
    return this.http.get<any>(environment.MA_API_URL + 'PurchaseV2/GetPurchases?isBlaster=true');
  }
}
