import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'dexie';
import { RecentStreamService } from 'src/app/services/recent-stream/recent-stream.service';

@Component({
  selector: 'app-download-video-page',
  templateUrl: './download-video-page.component.html',
  styleUrls: ['./download-video-page.component.css']
})
export class DownloadVideoPageComponent implements OnInit {
 private subscriptions: Subscription[] = [];
  private pwd: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private recentStreamService: RecentStreamService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        this.pwd = params['pwd'];
        this.getDownloadLink();
      })
    );
  }

  getDownloadLink() {
    this.subscriptions.push(
      this.recentStreamService.getResultVideoLink(this.pwd).subscribe((res) => {
        window.open(res.presignedUrl, '_blank');
        this.router.navigate(['recent-streams']);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
