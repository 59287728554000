<div class="app d-flex flex-col">
  <main class="d-flex flex-col flex-1 auth-page">

    <section class="login-page d-flex flex-1">
      <h1 class="visually-hidden">Blaster authentication</h1>
      <div class="login-banner d-flex gap-20 bg-white">
        <figure class="login-banner__logo">
          <img src="./../../../assets/images/logo-login.svg" alt="Blaster logo" width="174" height="87">
        </figure>

        <figure class="login-banner__main flex-center-center flex-1 flex-col  align-self-center">
          <figcaption class="text-center mb-60">
            <p class="text-xl">Description about service and it functions</p>
          </figcaption>
          <img src="./../../../assets/images/auth-illustration.png" alt="Blaster illustration" width="565" height="390">
        </figure>
        <div class="login-banner__link">
          <p class="d-flex justify-content-center c-gap-5 text-xl">Go to
            <a
              href="https://www.cinamaker.com/"
              target="_blank"
              class="d-flex align-items-center t-color-primary-blue text-underline c-gap-5"
            >
              Cinamaker site
              <i class="icon icon_md arrow arrow-right arrow_color-primary-secondary"></i>
            </a>
          </p>
        </div>
      </div>

      <div class="login-form d-flex flex-col">

        <div class="login-form__wrapper d-flex flex-col justify-content-center align-items-center flex-1">
          <div class="auth-panel  bg-white rounded-xl">
            <figure class="auth-panel__logo mb-40">
              <img
                src="./../../../assets/images/logo-login.svg"
                class="block-center"
                alt="Blaster logo"
                width="174"
                height="87"
              >
            </figure>

            <div class="auth-panel__title text-center mb-20">
              <h3>Welcome back</h3>
            </div>

            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <div id="userNameDiv" class="form-group">
                <label class="form-label form-label_md" for="user-name-id">Login</label>
                <div class="input-container">
                  <input name="user-name" id="user-name-id" class="form-control form-control_size-md" type="text" placeholder="Login" formControlName="login">
                </div>
                <div *ngIf="efc?.login?.invalid && (efc?.login?.dirty || efc?.login?.touched)">
                  <p class="info" *ngIf="efc?.login?.errors?.required">Login is required</p>
                </div>
              </div>
              <div id="passwordDiv" class="form-group">
                <label class="form-label form-label_md" for="password-id">Password</label>
                <div class="input-container">
                  <input id="password-id" [attr.type] = "showPassword ? 'text' : 'password'" name="password" class="form-control form-control_size-md" placeholder="Password" formControlName="password">
                  <button type="button" class="input-tool-btn input-tool-btn_size-md">
                    <i (click)="showHidePassword()" class="icon icon_md icon-pass-off" [ngClass]="showPassword ? 'icon_pass-on_orange' : 'icon_pass-off_orange'"></i>
                  </button>
                </div>
                <div *ngIf="efc?.password?.invalid && (efc?.password?.dirty || efc?.password?.touched)">
                  <p class="info" *ngIf="efc?.password?.errors?.required">Password is required</p>
                  <p class="info"
                     *ngIf="efc?.password?.errors?.minlength && efc?.password?.errors?.minlength?.requiredLength">
                    Password should be at least 6 characters long
                  </p>
                </div>
              </div>
              <div style="color: red;" *ngIf="loginErrorMessage" class="login-form-error-message form-group">{{loginErrorMessage}}</div>
              <div class="auth-panel__btn mt-30 mb-30">
                <button class="btn btn_color-main btn_size-primary btn_rounded-md btn_fluid" type="submit" [disabled]="loginForm?.invalid || loginForm?.disabled">Sign
                  In</button>
              </div>
            </form>

            <div class="mb-15 text-right">
              <a (click)="resetPassword()" class="text-underline-hover">
                Forgot password?
              </a>
            </div>

            <div class="text-center mb-30">
              <span class="text-md">or <br>sign in with</span>
            </div>

            <div class="auth-panel__socials d-flex justify-content-center flex-wrap c-gap-15 r-gap-20 p-inline-10">
              <button (click)="loginWithApple()" type="button">
                <img src="../../../assets/images/svg/auth-socials/apple-icon.svg" alt="">
              </button>
              <button (click)="loginWithGoogle()" type="button">
                <img src="../../../assets/images/svg/auth-socials/google-icon.svg" alt="">
              </button>
              <button (click)="loginWithFacebook()" type="button">
                <img src="../../../assets/images/svg/auth-socials/facebook-icon.svg" alt="">
              </button>
              <button (click)="loginWithLinkedIn()" type="button">
                <img src="../../../assets/images/svg/auth-socials/linkedin-icon.svg" alt="">
              </button>
              <button (click)="loginWithTwitter()" type="button">
                <img src="../../../assets/images/svg/auth-socials/twitter-icon.svg" alt="">
              </button>
              <!-- <button type="button">
                <img src="../../../assets/images/svg/auth-socials/twitch-icon.svg" alt="">
              </button> -->
              <button (click)="loginWithZoom()" type="button">
                <img src="../../../assets/images/svg/auth-socials/zoom-icon.svg" alt="">
              </button>
            </div>
          </div>
        </div>
        <div class="login-form__footer text-center">
          <span class="text-small">
            ©{{currentYear}} Cinamaker Inc. All Rights Reserved
          </span>
        </div>
      </div>
    </section>

  </main>
</div>


