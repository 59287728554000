import { Component, Output, Input, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CustomSelectOption } from 'src/app/models/custom-select-option.model';
import { RestreamItem } from 'src/app/models/restream-item';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';


@Component({
  selector: 'app-linked-in-settings',
  templateUrl: './linked-in-settings.component.html',
  styleUrls: ['./linked-in-settings.component.scss']
})
export class LinkedInSettingsComponent implements OnInit {

  @Input() linkedInConfiguration: RestreamItem = this.data.item;
  @Output() validateDestinations = new EventEmitter()
  @Output() updatedRestreamItem = new EventEmitter()

  private subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<LinkedInSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  public regionList: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  public privacyModeList: Array<CustomSelectOption> = new Array<CustomSelectOption>();

  userGroups: any;
  errors: any = [];
  searchTimeoutId: any;

  ngOnInit(): void {
    this.regionList.push({ name: "WEST_US", value: "WEST_US" }, { name: "EAST_US_NORTH", value: "EAST_US_NORTH" }, { name: "EAST_US_SOUTH", value: "EAST_US_SOUTH" }, { name: "CENTRAL_US", value: "CENTRAL_US" }, { name: "SOUTH_CENTRAL_US", value: "SOUTH_CENTRAL_US" }, { name: "SOUTH_AMERICA", value: "SOUTH_AMERICA" }, { name: "SOUTHEAST_ASIA", value: "SOUTHEAST_ASIA" }, { name: "CENTRAL_INDIA", value: "CENTRAL_INDIA" }, { name: "NORTH_EUROPE", value: "NORTH_EUROPE" }, { name: "WEST_EUROPE", value: "WEST_EUROPE" });
    this.privacyModeList.push({ name: "PUBLIC", value: "PUBLIC" }, { name: "LOGGED_IN", value: "LOGGED_IN" });
    // this.validateItem();
  }

  validateItem(): void {
    if (!this.linkedInConfiguration.streamName) {
      this.errors.push('stream title is required');
    }
    else if (this.linkedInConfiguration.streamName.length >= 100) {
      this.errors.push('stream title max size is 100 chars');
    }
  }

  saveConfigurationUpdate(): void {
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.errors = [];

      this.validateItem();

      this.subscriptions.add(
        this.setupStreamService.updateSettings(this.linkedInConfiguration).subscribe({
          next: (data) => {
           this.validateDestinations.emit();
            this.dialogRef.close(false);
            console.log(data);
          },
          error: (error) => {
            console.log(error);

          }
        })
      );
    }, 500);
  }

  changeRegion(selectedItem: any) {
    this.linkedInConfiguration.region = selectedItem.value;
  }

  changePrivacyStatus(selectedItem: any) {
    this.linkedInConfiguration.privacyStatus = selectedItem.value;
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }
}

