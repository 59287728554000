import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { BillingInfoComponent } from './components/billing-info/billing-info.component';
import { ProfileComponent } from './components/profile/profile.component';
import {StreamVideoPlayerComponent} from './components/stream-video-player/stream-video-player.component'
import { AuthGuard } from './guards/auth.guard';
import { MenuGuard } from './guards/menu.guard';
import { RecentStremsComponent } from './components/recent-strems/recent-strems.component';
import { SetupStreamComponent } from './components/setup-stream/setup-stream.component';
import { StreamPreviewComponent } from './components/stream-preview/stream-preview.component';
import { StatisticComponent } from './components/statistic/statistic.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VimePlayerComponent } from './components/vime-player/vime-player.component';
import { DownloadVideoPageComponent } from './components/download-video-page/download-video-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { 
    path: 'login',
    component: AuthComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'recent-streams',
    component: RecentStremsComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'setup-stream/:id/:itemId',
    component: SetupStreamComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'setup-stream/:id',
    component: SetupStreamComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'setup-stream',
    component: SetupStreamComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent,
  },
  {
    path: 'payments',
    component: PaymentsComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-password/:code',
    component: ResetPasswordComponent,
  },
  {
    path: 'biling-info',
    component: BillingInfoComponent,
    canActivate: [MenuGuard]
  },
  {
    path: 'stream-video-player/:vmIP/:id/:userId/:streamName',
    component: StreamVideoPlayerComponent,
  },
  {
    path: 'stream-video-player/:vmIP/:id/:userId',
    component: StreamVideoPlayerComponent,
  },
  {
    path: 'stream-video-player/:vmIP/:id',
    component: StreamVideoPlayerComponent,
  },
  {
    path: 'stream-vime-player-test/:vmIP/:id/:userId',
    component: VimePlayerComponent,
  },
  {
    path: 'stream-vime-player-test/:vmIP/:id',
    component: VimePlayerComponent,
  },
  {
    path: 'stream-preview',
    component: StreamPreviewComponent
  },
  {
    path: 'stream-preview/:id',
    component: StreamPreviewComponent
  },
  {
    path: 'statistic',
    component: StatisticComponent
  },
  {
    path: 'statistic/:id',
    component: StatisticComponent
  },
    {
    path: 'download',
    component: DownloadVideoPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
