import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as signalR from '@aspnet/signalr';
import Hls from 'hls.js';
import { Subscription } from 'rxjs';
import { UserPlayerSettings } from 'src/app/models/user-player-settings-model';
import { StreamPreviewService } from 'src/app/services/stram-preview/stream-preview.service';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-vime-player',
  templateUrl: './vime-player.component.html',
  styleUrls: ['./vime-player.component.css']
})
export class VimePlayerComponent implements OnInit, OnDestroy {

 
  private subscriptions = new Subscription();
  constructor(private route: ActivatedRoute,
  private steramPreviewService: StreamPreviewService,
  private dialog: MatDialog) { }
  public videoUrl:string = "";
  private hubConnection!: signalR.HubConnection;

  vmIP: any = '';
  id: any = '';
  userId: any = '';
  retryCount: number = 0;
  playerInterval: any;
  playerSettings: UserPlayerSettings = new UserPlayerSettings();
  
  onPlaybackReady() {
    console.log('playback ready')
  }

  public storageSupported = false;

  public togglePlay: boolean = true;
  public toggleMute: boolean = true;
  public volumeRanger: any;

  public isPrivateModel: boolean = false;
  public privateStreamKey: string = '';
  allowViewStream: boolean = false;
  showPassWindow: boolean = false;

  public videoElement: any;

  public isStreamCreated: boolean = true;
  public playerHeader: string = "";
  public isRtmps: boolean = false;
  public countOfPlayerViewer: number = 1;

  ngOnInit(): void {
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add("player-body");
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id'); //streamInstanceId
      this.vmIP = params.get('vmIP')
      this.userId = params.get('userId')
    });

    if (this.id && this.vmIP && this.userId) {
      this.playHlsVideo()
    }
  }
  playHlsVideo() {
    var url = `https://${this.vmIP}/tv/${this.userId}.m3u8`;
    this.videoUrl = url;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
