import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';

@Component({
  selector: 'app-force-stop-stream',
  templateUrl: './force-stop-stream.component.html',
  styleUrls: ['./force-stop-stream.component.css']
})
export class ForceStopStreamComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ForceStopStreamComponent>,
    private spinner: NgxSpinnerService,
    private router: Router,
    private setupStreamService: SetupStreamService,
     @Inject(MAT_DIALOG_DATA) public data: any) { }

     private subscriptions = new Subscription();

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  stopStreamAnyway(): void {

    this.subscriptions.add(
      this.setupStreamService.stopVmInstance(this.data.streamInstanceId, true).subscribe({
        next:(data) => {
          this.dialogRef.close();
          localStorage.setItem("privateKey", "");

          this.router.navigate(['/recent-streams']);
        },
        error:(err) => {
          console.log(err);
          this.dialogRef.close();

        }
      })
    )
  }
}
