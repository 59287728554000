import { Component, Output, Input, OnDestroy, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestreamItem } from 'src/app/models/restream-item';
import { SetupStreamService } from 'src/app/services/setup-stream/setup-stream.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomSelectOption } from 'src/app/models/custom-select-option.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-twitch-settings',
  templateUrl: './twitch-settings.component.html',
  styleUrls: ['./twitch-settings.component.scss']
})

export class TwitchSettingsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  @Input() twitchConfiguration: RestreamItem = this.data.item;
  @Output() validateDestinations = new EventEmitter()
  @Output() updatedRestreamItem = new EventEmitter()

  constructor(
    private dialogRef: MatDialogRef<TwitchSettingsComponent>,
    private setupStreamService: SetupStreamService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  searchTimeoutId: any;
  twitchInfo: any = {};
  twitchLanguages: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  twitchGames: Array<CustomSelectOption> = new Array<CustomSelectOption>();
  twitchUser: any = {};
  errors: any = [];
  isGamesLoaded: boolean = false;
  public currentserviceConfiguration: any = {};

  ngOnInit(): void {
    // this.validateItem();
this.spinner.show();
    this.subscriptions.add(this.setupStreamService.getTwitchInfo(this.twitchConfiguration.id).subscribe(res => {
      this.setupStreamService.getTwitchUser(this.twitchConfiguration.id).subscribe(res => {
        this.twitchUser = res;

        this.setupStreamService.getTwitchGames(this.twitchConfiguration.id).subscribe(res => {
          res.data.filter((x: any) => {
            this.twitchGames.push({ name: x.name, value: x.id });
          });

          this.twitchLanguages.push({ name: "English", value: "en" }, { name: "Bahasa Indonesia", value: "id" }, { name: "Català", value: "ca" },
            { name: "Dansk", value: "da" }, { name: "Deutsch", value: "de" }, { name: "Español", value: "es" }, { name: "Français", value: "fr" }, { name: "Italiano", value: "it" },
            { name: "Magyar", value: "hu" }, { name: "Nederlands", value: "nl" }, { name: "Norsk", value: "no" }, { name: "Polski", value: "pl" }, { name: "Português", value: "pt" },
            { name: "Română", value: "ro" }, { name: "Slovenčina", value: "sk" }, { name: "Suomi", value: "fi" }, { name: "Svenska", value: "sv" }, { name: "Tagalog", value: "tl" },
            { name: "Tiếng Việt", value: "vi" }, { name: "Türkçe", value: "tr" }, { name: "Čeština", value: "cs" }, { name: "Ελληνικά", value: "el" }, { name: "Български", value: "bg" },
            { name: "Русский", value: "ru" }, { name: "Українська", value: "uk" }, { name: "العربية", value: "ar" }, { name: "بهاس ملايو", value: "ms" }, { name: "मानक हिन्दी", value: "hi" },
            { name: "ภาษาไทย", value: "th" }, { name: "中文", value: "zh" }, { name: "日本語", value: "ja" }, { name: "粵語", value: "zh-hk" }, { name: "한국어", value: "ko" },
            { name: "American Sign Language", value: "asl" }, { name: "Other", value: "other" });

          this.isGamesLoaded = true;
        });

        this.twitchInfo.title = this.twitchConfiguration?.streamName ?? res.title;

        this.twitchInfo.broadcaster_language = this.twitchConfiguration?.broadcasterLanguage ?? res.broadcaster_language;

        this.twitchInfo.game_id = this.twitchConfiguration?.gameId ?? res.game_id;

        this.twitchInfo.delay = this.twitchConfiguration?.delay ?? res.delay;
      });
      setTimeout (() => {
        this.spinner.hide();
      }, 1000);
    }));
  }

  validateItem(): void {
    if (!this.twitchConfiguration.streamName) {
      this.errors.push('stream title is required');
    }
    else if (this.twitchConfiguration.streamName.length >= 100) {
      this.errors.push('stream title max size is 100 chars');
    }
  }

  changeGameId(selectedItem: any) {
    this.twitchConfiguration.gameId = selectedItem.value;
  }

  changeLanguage(selectedItem: any) {
    this.twitchConfiguration.broadcasterLanguage = selectedItem.value;
  }

  saveConfigurationUpdate(): void {
    clearTimeout(this.searchTimeoutId);
    this.searchTimeoutId = setTimeout(() => {
      this.errors = [];

      this.validateItem();

      this.twitchConfiguration.delay = this.twitchUser.broadcaster_type == 'partner' ? this.twitchInfo.delay : null;
      this.twitchConfiguration.streamName = this.twitchInfo.title;

      this.subscriptions.add(
        this.setupStreamService.updateSettings(this.twitchConfiguration).subscribe({
          next: (data) => {
            this.validateDestinations.emit();
            this.dialogRef.close(false);
            console.log(data);
          },
          error: (error) => {
            console.log(error);

          }
        })
      );
    }, 500);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }
}

