
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MaUserModel } from 'src/app/models/ma-user-model';
import { AuthService } from 'src/app/services/auth.service';
import { PurchaseService } from 'src/app/services/purchase-service/purchase.service';
import { LogoutDialogComponent } from '../auth/logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit, OnDestroy {

  @HostListener('window:scroll', ['$event']) onScroll($event: Event): void {
    if($event) {
      this.windowOfsetY = window.pageYOffset;
    }
  }


  private subscriptions = new Subscription();

  public isOpenedDropdown: boolean = false;
  public isOpenedNavMenu: boolean = false;
  public user: MaUserModel = new MaUserModel();
  public windowOfsetY:any = 0;
  public scrolled:boolean = false;
  public currentPurchase: any;

  constructor(private authService: AuthService,
    private purchaseService: PurchaseService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUser();
    window.addEventListener('scroll', this.onScroll, true);
    setTimeout(()=>{
      this.windowOfsetY = window.pageYOffset;
    }, 100);

    this.purchaseService.getPurchases().subscribe(res => {
      {
        let userPurchases = res.PurchaseList.filter((x: any) => {
          x.BeginDate = new Date(x.BeginDate * 1000);
          x.EndDate = new Date(x.EndDate * 1000);
          return x.PurchaseTypeId.includes('blaster');
        });

        userPurchases.sort((n1: any, n2: any) => {
          if (n1.EndDate > n2.EndDate) {
            return -1;
          }

          if (n1.EndDate < n2.EndDate) {
            return 1;
          }

          return 0;
        });

        this.currentPurchase = userPurchases.find((x: any) => x.BeginDate < Date.now() && x.EndDate > Date.now());
      }
    },
    err => {});
  }

  logout(): boolean {
    this.dialog.open(LogoutDialogComponent,
      {
        maxWidth: '460px',
        width: 'calc(100% - 40px)'
      })

    return true;
  }

  getUser():void{
    this.authService.meBluster().subscribe((res: any) => {
      this.subscriptions.add(
        this.authService.me().subscribe({
          next:(data:MaUserModel) => {
            this.user = data;
          },
          error:(error:any) => {
            console.log(error);
          }
        })
      )
    },
    (err: any) => {
      this.subscriptions.add(
        this.authService.createBlusterUser(false).subscribe((res:any) => {
          this.authService.me().subscribe({
            next:(data:MaUserModel) => {
              this.user = data;
            },
            error:(error:any) => {
              this.authService.logout();
              console.log(error);
            }
          })
        }, (err: any) => {
          this.authService.logout();
        })
      )
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
