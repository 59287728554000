<div class="app d-flex flex-col">
    <main class="d-flex flex-col flex-1 auth-page">

      <section class="login-page d-flex flex-1">
        <h1 class="visually-hidden">Blaster authentication</h1>
        <div class="login-banner d-flex gap-20 bg-white">
          <figure class="login-banner__logo">
            <img src="./../../../assets/images/logo-login.svg" alt="Blaster logo" width="174" height="87">
          </figure>

          <figure class="login-banner__main align-self-center">
            <figcaption class="text-center mb-60">
              <p>Description about service and it functions</p>
            </figcaption>
            <img src="./../../../assets/images/auth-illustration.png" alt="Blaster illustration" width="565" height="390">
          </figure>
          <div class="login-banner__link">
            <p class="d-flex justify-content-center c-gap-5">Go to
              <a href="https://www.cinamaker.com/" target="_blank" class="d-flex align-items-center t-color-primary-blue text-underline c-gap-5">
                Cinamaker site
                <i class="icon icon_md arrow arrow-right arrow_color-primary-secondary"></i>
              </a>
            </p>
          </div>
        </div>

        <div class="login-form d-flex flex-col">

          <div class="login-form__wrapper d-flex flex-col justify-content-center align-items-center flex-1">
            <div class="auth-panel  bg-white rounded-xl">
              <figure class="auth-panel__logo mb-40">
                <img
                  src="./../../../assets/images/logo-login.svg"
                  class="block-center"
                  alt="Blaster logo"
                  width="174"
                  height="87"
                >
              </figure>

              <div class="auth-panel__title text-center mb-20">
                <h3>Reset password</h3>
              </div>

              <div *ngIf="!emailIsSent && !code">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="sendResetPasswordEmail()">
                  <div id="userNameDiv" class="form-group">
                    <label class="form-label form-label_md" for="user-name-id">Email</label>
                    <div class="input-container">
                      <input name="user-name" id="user-name-id" class="form-control form-control_size-md" type="email" ngModel [email]="true" placeholder="Email" formControlName="login">
                    </div>
                    <div *ngIf="efc.login?.invalid && (efc.login?.dirty || efc.login?.touched)">
                      <p class="info" *ngIf="efc.login?.errors?.required">Email is required</p>
                    </div>
                  </div>

                  <div *ngIf="emailResetPasswordError" class="login-form-error-message form-group">{{emailResetPasswordError}}</div>
                  <div class="auth-panel__btn mt-30 mb-30">
                    <button class="btn btn_color-main btn_size-primary btn_rounded-md btn_fluid" type="submit" [disabled]="resetPasswordForm.invalid || resetPasswordForm.disabled">Reset</button>
                  </div>
                </form>

                <div class="text-center mb-30">
                  <span class="text-md">Have an account? <a class="t-color-primary text-underline-hover" href="/">Sign in</a></span>
                </div>
              </div>
              <div *ngIf="emailIsSent" class="text-center">
                Password recovery instructions have been sent to the mailbox.
                <div class="auth-panel__btn mt-30 mb-30">
                  <a href="/" class="btn btn_color-main btn_size-primary btn_rounded-md btn_fluid">To Main Page</a>
                </div>
              </div>
              <div *ngIf="code && !isCodeExpired && !successChangePasswordMessage">
                <form [formGroup]="setNewPasswordForm" (ngSubmit)="saveNewPassword()">
                  <div id="passwordDiv" class="form-group">
                    <label class="form-label form-label_md" for="password-id">New password</label>
                    <div class="input-container">
                      <input id="password-id" [attr.type] = "showPassword ? 'text' : 'password'" name="password" class="form-control form-control_size-md" placeholder="Confirm password" formControlName="password">
                      <button type="button" class="input-tool-btn input-tool-btn_size-md">
                        <i (click)="showHidePassword()" class="icon icon_md icon-pass-off" [ngClass]="showPassword ? 'icon_pass-on_orange' : 'icon_pass-off_orange'"></i>
                      </button>
                    </div>
                    <div *ngIf="pasFormControls.password?.invalid && (pasFormControls.password?.dirty || pasFormControls.password?.touched)">
                      <p class="info" *ngIf="efc.password?.errors?.required">Password is required</p>
                      <p class="info"
                         *ngIf="pasFormControls.password?.errors?.minlength && pasFormControls.password?.errors?.minlength?.requiredLength">
                        Password should be at least 6 characters long
                      </p>
                    </div>
                  </div>
                  <div class="auth-panel__btn mt-30 mb-30">
                    <button class="btn btn_color-main btn_size-primary btn_rounded-md btn_fluid" type="submit" [disabled]="setNewPasswordForm.invalid || setNewPasswordForm.disabled">Save</button>
                  </div>
                </form>
              </div>
              <div *ngIf="isCodeExpired" class="text-center">Reset Password token is expired. Please send new reset password <a class="t-color-primary-blue text-underline" href="/reset-password">request</a></div>
              <div *ngIf="successChangePasswordMessage" class="text-center">{{successChangePasswordMessage}}</div>
            </div>
          </div>
          <div class="login-form__footer text-center">
            <span class="text-small">
              ©2022 Cinamaker Inc. All Rights Reserved
            </span>
          </div>
        </div>
      </section>

    </main>
  </div>



