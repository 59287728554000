<div class="twitch-settings">
  <h4 class="mb-20">Destination settings:</h4>

  <div class="form-group">
    <label class="form-label form-label_md">Account:</label>

    <div class="input-container">
      <figure class="input-account account d-flex align-items-center c-gap-10">
        <span class="account-avatar account-avatar_size-md flex-shrink-0 rounded-circle">
          <!-- <img src="../../../assets/images/profile-image.jpg" alt="profile picture" width="38" height="38"> -->
          <img src="{{twitchUser?.profile_image_url}}" alt="profile picture" width="38" height="38">
        </span>
        <figcaption>
          <span class="d-block fw-600">{{twitchUser?.login}}</span>
          <span class="d-block text-xs t-color-gray-light">{{twitchConfiguration.userProfile?.email}}</span>
        </figcaption>
      </figure>

      <input class="form-control form-control_size-md" type="text" disabled>
      <!-- <button type="button" class="input-tool-btn input-tool-btn_profile">
        <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.167 17.501v-1.667a3.333 3.333 0 00-2.5-3.225M14.167 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H4.167a3.333 3.333 0 00-3.334 3.333V17.5M13.333 2.61a3.333 3.333 0 010 6.458M7.5 9.167a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667z"
            stroke="#fff" stroke-width="1.667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button> -->

    </div>
  </div>

  <div class="dialog__main">
    <div class="form-group">
      <label class="form-label" for="stream-name">Stream name*:</label>
      <input [(ngModel)]="this.twitchInfo.title"
        class="form-control form-control_size-md" id="stream-name" type="text" name="stream-name"
        placeholder="Enter name here...">
    </div>

    <div>
      <div class="d-flex align-items-center justify-content-between mb-20 c-gap-10">
        <div>
          <label class="form-label mb-5">Language:</label>
          <span class="t-color-gray-light">Stream language</span>
        </div>

        <app-custom-select *ngIf="isGamesLoaded" [options]="twitchLanguages" [selectedElementValue]="twitchInfo.broadcaster_language" (currentValueChange)="changeLanguage($event)"></app-custom-select>
      </div>

      <div *ngIf="this.twitchUser.broadcaster_type == 'partner'" id="delay" class="form-group">
        <label class="form-label" for="delay">Delay:</label>
        <input [(ngModel)]="this.twitchInfo.delay"
          class="form-control form-control_size-md" id="delay" type="number" name="delay"
          placeholder="Enter delay here...">
      </div>

      <div>
        <div class="d-flex align-items-center justify-content-between c-gap-10">
          <div>
            <label class="form-label mb-5">Category:</label>
            <span class="t-color-gray-light">Stream Category</span>
          </div>

          <app-custom-select *ngIf="isGamesLoaded" [options]="twitchGames" [selectedElementValue]="twitchInfo.game_id"
            (currentValueChange)="changeGameId($event)"></app-custom-select>
        </div>

        <div class="input-wrap error-text error-container">
          <span *ngFor="let error of this.errors">{{error}}</span>
        </div>
      </div>
      <!-- <div class="closeButton">
        <button class="btn saveButton btn--secondary" (click)="saveConfigurationUpdate()">Save</button>
      </div> -->
    </div>
  </div>
  
  <div class="btw-wrapper d-flex align-items-center gap-15 mt-40">
    <button class="btn btn_outline btn_outline-grey btn_size-primary btn_fluid btn_rounded-md" (click)="onClose()">Cancel</button>

    <button class="btn btn_color-secondary btn_size-primary btn_fluid btn_rounded-md" (click)="saveConfigurationUpdate()">Save</button>
  </div> 

</div>