import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimePipe implements TimePipe {
    transform(endDate: Date, startDate: Date): string {

        if (!endDate || !startDate) {
            return '00:00:00';
        }

        const diffMs = +new Date(endDate!) - +new Date(startDate!);
        var Seconds_from_T1_to_T2 = diffMs / 1000;
        let sec = Math.abs(Seconds_from_T1_to_T2);

        let hours: number | string = Math.floor(sec / 3600);
        let minutes: number | string = Math.floor((sec - (hours * 3600)) / 60);
        let seconds: number | string = Math.floor(sec - (hours * 3600) - (minutes * 60));
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }
}