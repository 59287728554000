import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service'
import { VmStatus } from '../models/enums/VmStatus';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { ChatMessage } from '../models/chat';
import { ChatService } from './chat.service';
import { Observable, Subject, merge } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {

  @Output() initVmStatus = new EventEmitter();
  @Output() createVmError = new EventEmitter();

  private hubConnection!: signalR.HubConnection;
  private chatsHubConnection!: signalR.HubConnection;
  private messageSubject = new Subject<ChatMessage[]>();
   private allMessagesSubject = new Subject<any[]>();
   private messagesLength = 0;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private chatService: ChatService,
    private dialog: MatDialog) { }

    addConnectionToGroup = (instanceId: number) => {
      if(!this.hubConnection)
      {
        this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(environment.SIGNALR_URL, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
          })
          .build();

        this.hubConnection
          .start()
          .then(() => {
            return this.hubConnection!.invoke("addConnectionToGroup", instanceId);
          })
          .then(() => {
            this.vmCreated();
          })
          .catch(err => console.log(err));
      }
      else{
        this.hubConnection!.invoke("addConnectionToGroup", instanceId);
      }
    }

  vmCreated() {
    this.hubConnection!.on("Error", (err: any) => {
      console.log(err);
      this.initVmStatus.emit(0);
      this.createVmError.emit(err);
    });

    this.hubConnection!.on("vmCreated", (res: any) => {
      console.log('VC Created');

      let accessToken = this.cookieService.get('AccessToken');
      const parsedToken = this.authService.parseAccessToken(accessToken!);

      let redirectUrl = localStorage.getItem('CDSRedirectUrl');

      if(parsedToken.redirectUrl){
        window.location.replace(parsedToken.redirectUrl);
      }
      else if(redirectUrl){
        console.log('redirectUrl', redirectUrl);
        localStorage.removeItem('CDSRedirectUrl')
        window.location.replace(redirectUrl);
      }

      console.log("VM Started.");
    });

    this.hubConnection!.on("VmStatus", (res: VmStatus) => {
      console.log(res);
      this.initVmStatus.emit(res);
    });
  }

  getChatMessages(vmIP: string): Observable<any[]> {
    this.chatsHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`https://${vmIP}/transfer`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return this.cookieService.get('AccessToken')!;
        }
      })
      .build();

    const hubConnection$ = new Observable<void>((observer) => {
      this.chatsHubConnection!
        .start()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((err: any) => {
          observer.error(err);
        });
    });

    const hubMessages$ = new Observable<any>((observer) => {
      this.chatsHubConnection!.on("NewMessages", (res: any) => {
        observer.next([res]);
      });
    });

    merge(hubConnection$, hubMessages$).pipe(
      catchError((err) => {
        console.error('Error in chat messages:', err);
        return [];
      }),
      map((res) => [res])
      // switchMap(() => this.chatService.getAllMessages(streamId, skip)),
      // map((allMessages) => {
      //   return allMessages
      //   // .sort((a: ChatMessage, b: ChatMessage) => {
      //   //   const dateA = new Date(a.messageDate).getTime();
      //   //   const dateB = new Date(b.messageDate).getTime();
      //   //   return dateA - dateB;
      //   // });
      // })
      )

    .subscribe((allMessages) => {
      this.allMessagesSubject.next(allMessages);
    });

    return this.allMessagesSubject.asObservable();
  }

  ngOnDestroy() {
    if (this.hubConnection) {
      this.hubConnection!.off("vmCreated");
      this.hubConnection!.off("VmStatus");
      this.hubConnection!.off("Error");
      this.hubConnection!.stop();
    }

    if (this.chatsHubConnection) {
      this.chatsHubConnection!.off("NewMessages");
      this.chatsHubConnection!.stop();
    }
  }
}
