<div class="player">
  <div class="player__top-controls-container p-absolute flex-center-between p-inline-20">
    <div class="flex-center-between flex-wrap c-gap-5">
      <div *ngIf="isRtmps" class="secure-mark p-block-5 p-inline-15 bg-white rounded-md">
        <img src="../../../assets/images/svg/protected.svg" alt="secured RTMP">
      </div>
      <div class="live-label p-block-5 p-inline-15 text-xl rounded-md">Live</div>
    </div>

    <div class="d-flex align-items-center c-gap-10 p-block-5 p-inline-15 text-xl bg-white rounded-md fw-600 shadow-md">
      <img src="../../../assets/images/svg/eye-black-icon.svg" alt="" width="24" height="24">
     <span>{{countOfPlayerViewer}}</span>
    </div>
  </div>

    <video class="player__video" visibilityState="visible" id="my_video" autoplay="" playsinline="">
        <source src="{{videoUrl}}" type="application/vnd.apple.mpegurl">
    </video>

    <div class="player__bottom-controls-container p-absolute flex-center-between p-inline-30  transition-300">
        <button [hidden]="!manifestLoaded" class="play-control" [ngClass]="{'played' : togglePlay}" (click)="playPause()">
        </button>

        <div class="d-flex align-items-center c-gap-15 block-right">
          <div class="volume-container p-relative d-flex align-items-center p-10" [ngClass]="{'active-control': playerSettings.allowVolumeControl}">
            <button type="button" class="volume-btn" (click)="muteUnmuteVideo()" [ngClass]="{'mute' : toggleMute}">
              <span></span>
            </button>
            <input class="volume-slider" (input)="rangeValue()" type="range" min="0" max="100" step="1" value="80">
          </div>

          <button class="player-fullscreen-btn" *ngIf="playerSettings.allowFullScreenControl" (click)="toggleFullscreen()" type="button">
            <img src="../../../assets/images/svg/player-fullscreen.svg" alt="" width="24" height="23">
          </button>
        </div>
    </div>
</div>

<!-- Enter code dialog -->
<div *ngIf="isPrivateModel && !allowViewStream && showPassWindow" class="overlay active flex-center-center">
  <div class="dialog-container player-dialog" role="dialog">
    <h5 class="dialog__title dialog__title_md text-center mb-20">Please enter the password</h5>

    <div class="form-group">
      <input type="text" #privateKey class="form-control form-control_size-md"  placeholder="Enter password">
    </div>
    <div class="dialog__actions">
      <button type="button" (click)="enterPrivateKey(privateKey)" class="btn btn_size-primary btn_color-secondary btn_fluid btn_rounded-md">Go ahead</button>
    </div>
  </div>
</div>





