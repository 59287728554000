<div class="app d-flex flex-col bg-app-main">
    <app-sidebar></app-sidebar>

    <main class="d-flex flex-col">
        <section class="pricing-page d-flex flex-col flex-1 pt-30">
            <div class="container container_md2 d-flex flex-col flex-1">
              <div class="pricing-page__title">
                <h1 class="mb-5 text-center">Choose your plan</h1>
                <p class="text-md text-center">Try free version</p>
              </div>

              <div class="pricing-plans d-grid c-gap-40">
                <div class="pricing-card">
                  <div class="mb-50">
                    <p class="text-center">Try</p>
                    <h3 class="pricing-card__price text-center">Free</h3>
                  </div>
                  <ul class="mb-45">
                    <li class="available">Up to 4 destination</li>
                    <li class="available">2 hours of restreaming</li>
                    <li class="available">6 restream instances in paralel</li>
                    <li class="not-available">Chats</li>
                    <li class="not-available">RTMPS</li>
                    <li class="not-available">Embedded player</li>
                  </ul>

                  <a href="{{tryFtree}}" class="btn btn_custom btn_color-main btn_size-primary btn_rounded-xs block-center">Get Started</a>
                </div>
                <div class="pricing-card pricing-card_popular p-relative">
                  <div class="mb-50">
                    <p class="text-center">Annual</p>
                    <h3 class="pricing-card__price text-center">$249</h3>
                  </div>
                  <ul class="mb-45">
                    <li class="available">Up to 10 destination</li>
                    <li class="available">Unlimited hours of restreaming</li>
                    <li class="available">12 restream instances in paralel</li>
                    <li class="available">Chats</li>
                    <li class="available">Secure RTMPS</li>
                    <li class="available">Embedded player</li>
                  </ul>

                  <a href="{{environment.BLASTER_ANUAL}}" target="_blank" class="btn btn_custom btn_color-main btn_size-primary btn_rounded-xs block-center">Get Started</a>
                </div>
                <div class="pricing-card">
                  <div class="mb-50">
                    <p class="text-center">Monthly</p>
                    <h3 class="pricing-card__price text-center">$29</h3>
                  </div>
                  <ul class="mb-45">
                    <li class="available">Up to 10 destination</li>
                    <li class="available">Unlimited hours of restreaming</li>
                    <li class="available">12 restream instances in paralel</li>
                    <li class="available">Chats</li>
                    <li class="available">Secure RTMPS</li>
                    <li class="available">Embedded player</li>
                  </ul>

                  <a href="{{environment.BLASTER_MOUNTHLY}}" target="_blank" class="btn btn_custom btn_color-main btn_size-primary btn_rounded-xs block-center">Get Started</a>
                </div>
              </div>
            </div>
        </section>
    </main>
</div>


