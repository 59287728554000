// debounce.service.ts
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DebounceService {
  private clickSubject = new Subject();

  private debounceTime = 1000;

  constructor() {}

  public handleButtonClick(): Observable<void> {
    return this.clickSubject.pipe(
      debounceTime(this.debounceTime),
      switchMap(() => this.handleActualButtonClick())
    );
  }

  private handleActualButtonClick(): Observable<void> {
    console.log('Button clicked!');
    return new Observable<void>((observer) => {
      observer.next();
      observer.complete();
    });
  }
  public notifyButtonClick(): void {
    this.clickSubject.next();
  }
}
