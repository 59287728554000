import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCopy]'
})
export class CopyDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click')
  copyText() {
    const inputElement = this.elementRef.nativeElement.previousElementSibling;
    let textToCopy = '';

    if (inputElement.tagName === 'INPUT' || inputElement.tagName === 'TEXTAREA') {
      textToCopy = inputElement.value.trim();
    } else if (inputElement.tagName === 'LABEL') {
      textToCopy = inputElement.textContent.trim();
    }

    if (textToCopy) {
      const tempInput = document.createElement('textarea');
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    }
  }
}
