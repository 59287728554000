<div class="app d-flex flex-col bg-app-main">
    <app-sidebar></app-sidebar>

    <main class="d-flex flex-col">
        <section class="statistic-page d-flex flex-col flex-1">
            <div class="container d-flex flex-col flex-1">
                <div class="block wrapper d-flex flex-col flex-1 pt-25 pb-60">
                    <div class="main-section__title">
                        <h1>Profile Info</h1>
                    </div>
                    <div class="main-section__content">
                        <div class="account-wrap">
                            <div class="user">
                                <!-- <div class="user-img">
                            <img src="./../../../assets/images/user-100.jpg" alt="">
                        </div> -->
                                <div class="user-info">
                                    <div class="user__name">{{userProfile.FirstName}} {{userProfile.LastName}}</div>
                                    <div class="user__subtitle">{{userProfile.Email}}</div>
                                    <div class="user__plan">
                                        <span>Cinamaker Director Studio Package: <b>{{userProfile.Purchases.length > 0 ?
                                                userProfile.Purchases[0].PurchaseTypeId : 'free'}}</b></span>
                                        <!-- <button class="btn btn--secondary btn--sm">Upgrate</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</div>
