<div class="app d-flex flex-col bg-app-main">
  <app-sidebar></app-sidebar>

  <main class="d-flex flex-col">
    <section class="statistic-page d-flex flex-col flex-1">
      <div class="container d-flex flex-col flex-1">
        <div class="block wrapper d-flex flex-col flex-1 pt-25 pb-60">
          <div class="statistic-page-top d-flex mb-30 gap-20">
            <div class="form-group">
              <div class="input-container input-container_search">
                <i class="icon icon_md icon_search"></i>
                <input [(ngModel)]="searchModel.Search" (keyup.enter)="searchStreams()" (blur)="searchStreams()"
                  type="search" class="form-control form-control_size-sm" placeholder="Search">
              </div>
            </div>

            <app-filter-dropdown [options]="filetrList" [selectedElementValue]="filetrList[0].value"
              (currentValueChange)="changeOrder($event)" class="statistic-page-filter"></app-filter-dropdown>


          </div>
          <!-- statistics table -->
          <div class="streams-table-container custom-scroll custom-scroll_size-md">
            <table class="streams-table statistic-table">
              <thead>
                <tr>
                  <th>Stream name</th>
                  <th>Date</th>
                  <th>Received</th>
                  <th>Sent</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody *ngFor="let streamInstance of streamInstances">
                <!-- stream info -->
                <tr [ngClass]="{'active' : streamInstance.id == selectedId}">
                  <td class="text-overflow">
                    {{streamInstance.streamName}}
                  </td>
                  <td>
                    {{streamInstance.startDate! | date: 'MM.dd.yyyy - hh:mm a'}}
                  </td>
                  <td>{{streamInstance.streamBytesIn! | byte}}</td>
                  <td>{{streamInstance.streamBytesOut! | byte}}</td>
                  <td>
                    <div class="flex-center-between c-gap-20 r-gap-10">
                      <span>Finished</span>
                      <button (click)="downloadVideo(streamInstance)"
                        [disabled]="!streamInstance?.saveStreamToDownloads || streamInstance?.concatInstance?.convertStatus !== 2"
                        *ngIf="streamInstance?.concatInstance"
                        class=" btn btn_icon table-btn table-btn_md btn_color-main btn_outline-main" mat-button
                        matTooltip="Your stream recording will be ready in several minutes"
                        [matTooltipDisabled]="streamInstance?.concatInstance?.convertStatus === 2">Download</button>
                      <!-- it needs toggle active -->
                      <button [ngClass]="{'active' : streamInstance.id == selectedId}"
                        (click)="changeActive(streamInstance.id)" type="button"
                        class="btn btn_icon table-btn table-btn_md-2 btn_outline btn_outline-main c-gap-10">
                        Details
                        <i class="icon icon_md arrow arrow-right arrow-color-primary-orange"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- stream info inner table -->
                <tr [ngClass]="{'active' : streamInstance.id == selectedId}" [hidden]="streamInstance.id != selectedId"
                  class="streams-table__row-has-inner">
                  <td colspan="5">
                    <table class="streams-table streams-table_table-inner">
                      <thead>
                        <tr>
                          <th class="text-left">Destinations</th>
                          <th>Duration</th>
                          <th>Resolution</th>
                          <th>Bitrate</th>
                          <th>FPS</th>
                          <th>Codec</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let restreamItem of streamInstance.restreamItems">
                          <td *ngIf="restreamItem.serviceConfigurationType == 0">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/youtube-icon.svg" alt="">
                              <figcaption>
                                <span class="fw-600">YouTube</span>
                              </figcaption>
                            </figure>
                          </td>
                          <td *ngIf="restreamItem.serviceConfigurationType == 1">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/facebook-icon.svg" alt="">
                              <figcaption>
                                <span class="fw-600">Facebook</span>
                              </figcaption>
                            </figure>
                          </td>
                          <td *ngIf="restreamItem.serviceConfigurationType == 2">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/linkedin-icon.svg" alt="">
                              <figcaption>
                                <span class="fw-600">LinkedIn</span>
                              </figcaption>
                            </figure>
                          </td>
                          <td *ngIf="restreamItem.serviceConfigurationType == 3">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/rtmp.svg" alt="">
                              <figcaption>
                                <span class="fw-600">RTMP</span>
                              </figcaption>
                            </figure>
                          </td>
                          <td *ngIf="restreamItem.serviceConfigurationType == 4">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/twitch-icon.svg" alt="">
                              <figcaption>
                                <span class="fw-600">Twitch</span>
                              </figcaption>
                            </figure>
                          </td>
                          <td *ngIf="restreamItem.serviceConfigurationType == 5">
                            <figure class="d-flex align-items-center c-gap-15">
                              <img src="../../../assets/images/svg/streams-destinations/rtmps.svg" alt="">
                              <figcaption>
                                <span class="fw-600">RTMPs</span>
                              </figcaption>
                            </figure>
                          </td>

                          <td>{{restreamItem.endDate! | time: restreamItem.startDate!}}</td>
                          <td>{{streamInstance.videoHeight}}p</td>
                          <td *ngIf="streamInstance.bwVideoCount && streamInstance.bwVideoCount != 0">
                            {{((streamInstance.bwVideo! / streamInstance.bwVideoCount!) / 1000000).toFixed(2)}} Mbps
                          </td>
                          <td *ngIf="!streamInstance.bwVideoCount || streamInstance.bwVideoCount == 0">0 Mbps</td>
                          <td>{{streamInstance.videoFps}}</td>
                          <td>{{streamInstance.videoCodec!}}</td>
                          <!--                          <td>{{restreamItem.streamBytesIn! | byte}}</td>-->
                          <!--                          <td>{{restreamItem.streamBytesOut! | byte}}</td>-->
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>


<div class="overlay"></div>