<figure class="account d-flex align-items-center c-gap-10">
    <div class="p-relative flex-shrink-0">
        <span class="account-avatar account-avatar_size-md rounded-circle d-block">
            <img [src]="item.profileIconUrl || item.iconUrl" alt="" width="38" height="38">
        </span>
        <span *ngIf="item.serviceConfigurationType !== 3 && item.serviceConfigurationType !== 5" class="avatar-social-label p-absolute rounded-circle">
            <img [src]="item.iconForChannel" [alt]="item.alt"
                width="15" height="15">
        </span>
    </div>
    <figcaption>
        <span class="d-block fw-600 text-overflow mr-20">{{item.streamName}}</span>
        <span *ngIf="item.isActive" class="d-block text-small t-color-gray-light">{{resolution}},
            {{fps}}</span>
    </figcaption>
</figure>