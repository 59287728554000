<!-- setup source -->
<section>
  <h2 class="mb-15">Setup Source</h2>
  <form [formGroup]="streamForm" (ngSubmit)="onSubmit()" class="stream-section__wrapper">
    <div class="stream-section stream-section__source d-flex flex-col">

      <div class="mb-20 stream-name">
        <label class="form-label form-label_md mb-5" for="stream-name">Stream name:</label>
        <input class="form-control form-control_size-sm" (change)="onSubmit()" formControlName="streamName">

        <p class="info" *ngFor="let error of errorService.instanceErrors" style="color: red;"
          class="alert alert-danger">
          {{error}}
        </p>
      </div>

      <div class="d-flex flex-col gap-15">
        <p class="form-label form-label_md">Stream Source:</p>

        <!-- RTMP -->
        <div class="d-flex align-items-center c-gap-25">
          <label class="checkbox-container">
            <input type="radio" name="streamSource" formControlName="streamSource" [value]="0">
            <span class="checkbox checkbox-radio"></span>
            <span>RTMP/RTMPs</span>
          </label>
          <app-tooltip
            tooltipText="The RTMP/RTMPS stream URL and stream key will be provided after you will press on Start Live Stream button."></app-tooltip>
        </div>

        <!-- Stream from file checkbox -->
        <div class="d-flex align-items-center c-gap-5">
          <label class="checkbox-container">
            <input type="radio" name="streamSource" formControlName="streamSource" [value]="1">
            <span class="checkbox checkbox-radio"></span>
            <span>Stream from file </span>
          </label>
          <app-tooltip tooltipText="Stream the file uploaded from your computer."></app-tooltip>
        </div>

        <!-- save stream -->
        <div class="d-flex flex-col gap-15 save-file pt-20 ml-50" *ngIf="streamForm.get('streamSource')!.value === 0">
          <div class="d-flex align-items-center">
            <label class="checkbox-container">
              <input type="checkbox" name="save-stream" formControlName="saveStreamToDownloads">
              <span class="checkbox checkbox-sm"></span>
              <span>Save Stream to Downloads </span>
            </label>
          </div>

          <div class="mb-20 file-name">
            <label class="form-label form-label_md mb-5">File name:</label>
            <input class="form-control form-control_size-sm" formControlName="savedFileName"
              [disabled]="!streamForm.get('saveStreamToDownloads')!.disabled">
          </div>

        </div>

        <div class="d-flex flex-col gap-15 save-file pt-20 ml-50" *ngIf="streamForm.get('streamSource')?.value === 1">
          <div class="file-name">
            <label class="form-label form-label_md mb-5">File name:</label>
            <div class="input-wrapper">
              <input class="form-control form-control_size-sm file-input" name="streamFromFileFilePath"
                formControlName="streamFromFileFilePath" type="text"
                [value]="getTruncatedValueForStreamFromFileFileName()" readonly>
              <button type="button" (click)="browseFile()">Browse</button>
              <input type="file" id="fileInput" style="display:none;" (change)="onFileSelected($event)">
            </div>
          </div>

          <div *ngIf="canvasImageDataURI" class="preview-container">
            <div class="preview-wrapper">
              <img class="file-name preview-image" [src]="canvasImageDataURI" alt="Video Preview" />
              <div class="white-stripe"></div>
              <button class="remove-button" (click)="deleteFile()">Delete</button>
            </div>
          </div>
        </div>

        <div>
          <video controls #video>
            <source type="video">
          </video>
          <canvas #canvas></canvas>
        </div>
      </div>
    </div>
  </form>
</section>