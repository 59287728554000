import { HttpBackend, HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { MaUserModel } from 'src/app/models/ma-user-model';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudCoreServiceService {
  constructor(private http: HttpClient, private authService: AuthService, private handler: HttpBackend) { }

  getTempCredentials(): Observable<any> {
    return this.http.get<any>(environment.CLOUDCORE_URL + 'FileAccess');
  }

  getPresignedUrl(filePath: string, fileType: string): Observable<any> {
    return new Observable(observer => {
      this.authService.me().subscribe((userData: MaUserModel) => {
        const userId = userData.MAUserId;
        this.http.get<any>(environment.CLOUDCORE_URL + `Blaster/GetPresignedUrlAuth?filePath=${filePath}&fileType=${encodeURIComponent(fileType)}&maUserId=${userId}`)
          .subscribe(response => {
            observer.next(response.url);
            observer.complete();
          }, error => {
            observer.error(error);
          });
      }, error => {
        observer.error(error);
      });
    });
  }
  uploadVideo(file: File, presignedUrl: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });

    const httpClient = new HttpClient(this.handler);

    return httpClient.put(presignedUrl, file, { headers: headers });
  }
  uploadFile(fileToUpload: File): Observable<Boolean> {
    return new Observable<boolean>(observer => {
      this.getPresignedUrl(fileToUpload.name, fileToUpload.type).subscribe((presignedUrl: string) => {
        this.uploadVideo(fileToUpload, presignedUrl).subscribe((response: any) => {
          console.log('File uploaded successfully:', response);
          observer.next(true);
          observer.complete();
        },
          error => {
            console.error('Error uploading file:', error);
            observer.next(false);
            observer.complete();
          });
      },
        error => {
          console.error('Error getPresignedUrl:', error);
          observer.next(false);
          observer.complete();
        });
    });
  }
}
