import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quality'
})
export class QualityPipe implements PipeTransform {

  transform(bps: number): string {
    let quality: string = '';
    let mbps = bps / 1000000;
    if (mbps >= 0.4 && mbps < 0.7) {
      quality = '240p';
    }
    else if (mbps >= 0.7 && mbps < 1.2) {
      quality = '360p';
    }
    else if (mbps >= 1.2 && mbps < 2.8) {
      quality = '480p';
    }
    else if (mbps >= 2.8 && mbps < 4.5) {
      quality = '720p';
    }
    else if (mbps >= 4.5 && mbps < 20) {
      quality = '1080p';
    }
    else if (mbps >= 20) {
      quality = '4Kp';
    }
    return quality;
  }

}
