import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StreamPreviewService {

  constructor(private http: HttpClient) { }

  public getStreamStatistic(domain:string | undefined): Observable<any> {
    var url = `https://${domain}/stat`;
    return this.http.get(url,{responseType:'text'});
  }

  public getPlayrSettings(instanceId: number) :Observable<any>{
    return this.http.get(environment.BLUSTER_API_URL+`restream/instance/${instanceId}/get-player-settings`)
  }

  validatePrivateStreamKey(streamInstanceId:string, key:string, userId:number){
    return this.http.post<any>(environment.BLUSTER_API_URL + `restream/stream-instance/${streamInstanceId}/validateKey`, 
    {
      privateStreamKey:key,
      userId: userId
    });
  }

  getFacebookProducerId(restreamItemId: number): Observable<any>{
    return this.http.get(environment.BLUSTER_API_URL+`restream/restreamItem/${restreamItemId.toString()}/facebook/producer`)
  }

  addPlayerViewer(instanceId: number, ip: string): Observable<any>{
    return this.http.post(environment.BLUSTER_API_URL+`restream/${instanceId}/addNewStreamViewer`,{ ip: ip});
  }

  getMyIp(): Observable<any>{
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getRestreamItem(restreamItemId: number): Observable<any>{
    return this.http.get(environment.BLUSTER_API_URL+`restream/restreamItem/get/${restreamItemId.toString()}`)
  }
}
