import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getFingerPrint, getSubDomainName } from 'src/app/common/utils';
import { environment } from '../../environments/environment';
import { MaUserModel } from '../models/ma-user-model';
import { User } from '../models/user';
import { UserProfile } from '../models/user-profile';

declare const FB: any;

export interface UserProfileInterface {
  MAUserId: number
  FirstName: string
  LastName: string
  Email: string
  Login: string
  Purchases: any[]
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private cookieService: CookieService,
    private http: HttpClient) { }
  user: any;

  popupwindow(url: string, title: string = '', w: number = 600, h: number = 600) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  login(user: User): Observable<any> {
    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }
    let headers = new HttpHeaders({
      'DeviceId': deviceId
    });
    let options = { headers: headers };
    return this.http.post(environment.SSO_URL + 'LoginFromBlaster', user, options);
  }

  loginFromSocialNetwork(model: any): Observable<any> {
    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }
    const headers = new HttpHeaders({
      'DeviceId': deviceId,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const user = {
      login: model.email,
      email: model.email,
      appKey: '2469c6847fca01f4c60fd98-0c06cb66-8a0a-11e5-9db5-003e57fecdee',
      appName: 'Cinamaker',
      token: model.accessToken,
      authType: model.authType,
      remoteUserId: model.remoteUserId,
      authToken: model.authToken,
      authTokenSecret: model.authTokenSecret,
      isBlaster: true,
    };

    const body = new HttpParams()
      .set('login', user.login)
      .set('email', user.email)
      .set('appKey', user.appKey)
      .set('appName', user.appName)
      .set('token', user.token)
      .set('authType', user.authType)
      .set('remoteUserId', user.remoteUserId)
      .set('authToken', user.authToken)
      .set('authTokenSecret', user.authTokenSecret)
      .set('password', 'awfaf')
      .set('isBlaster', user.isBlaster.toString());

    return this.http.post(environment.SSO_URL + 'Authorize', body.toString(), { headers });
  }

  loginFromTwitter(code: string): Observable<any> {
    let model = {
      code
    };

    return this.http.post(environment.BLUSTER_API_URL + 'Account/signIn-twitter', model);
  }

  loginFromApple(accessToken: string): Observable<any> {
    let model = {
      'token': accessToken
    };

    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }

    let headers = new HttpHeaders({
      'DeviceId': deviceId
    });
    let options = { headers: headers };

    return this.http.post(environment.SSO_URL + 'AccountV2/SignInWithAppleBlaster', model, options);
  }

  getLinkedInAccessToken(code: string, redirectUrl: string): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Account/get-linkedIn-access-token?code=${code}&redirectUrl=${redirectUrl}`);
  }

  checkLinkedInExpired(): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Account/check-linkedIn-token`);
  }

  addLinkedInToUser(code: string): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Account/add-linkedIn-to-user', { code });
  }

  signInWithLinkedIn(accessToken: string): Observable<any> {
    let model = {
      accessToken
    };

    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }

    let headers = new HttpHeaders({
      'DeviceId': deviceId
    });
    let options = { headers: headers };

    return this.http.post(environment.SSO_URL + 'AccountV2/SignInWithLinkedInBlaster', model, options);
  }

  createBlusterUser(isTrackInWoopra: boolean): Observable<any> {
    var utcOffsetMinutes = moment().utcOffset();
    return this.http.post(environment.BLUSTER_API_URL + 'Account/create-if-new', {
      utcOffsetMinutes: utcOffsetMinutes,
      isTrackInWoopra
    });
  }

  refreshTokens(refreshToken: string | null): Observable<any> {
    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }

    let headers = new HttpHeaders({
      'RefreshToken': this.cookieService.get('RefreshToken') as string,
      'DeviceId': deviceId
    });
    let options = { headers: headers };
    const tokens = {
      refreshToken: refreshToken
    };
    return this.http.post(environment.SSO_URL + 'RefreshTokens', tokens, options);
  }

  logout(): Observable<any> {
    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }

    let headers = new HttpHeaders({
      'DeviceId': deviceId
    });
    let options = { headers: headers };

    this.cookieService.set('AccessToken', '', -1, '/', getSubDomainName(), true, 'None');
    this.cookieService.set('RefreshToken', '', -1, '/', getSubDomainName(), true, 'None');

    return this.http.post(environment.SSO_URL + 'LogOut', {}, options);
  }

  me(): Observable<MaUserModel> {
    return this.http.get<MaUserModel>(environment.SSO_URL + 'Me', {});
  }

  getUserWithPurchases(): Observable<UserProfileInterface> {
    return this.http.get<UserProfileInterface>(environment.SSO_URL + 'GetUserWithPurchases');
  }

  addGoogleToUser(code: string): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Account/add-google-to-user', { code });
  }

  // addFacebookToUser(code: string): Observable<any> {
  //   return this.http.post(environment.BLUSTER_API_URL + 'Account/add-facebook-to-user', { code });
  // }

  addFacebookToUser(model: any): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Account/add-facebook-to-user', model);
  }

  getUserProfiles(): Observable<Array<UserProfile>> {
    return this.http.get<Array<UserProfile>>(environment.BLUSTER_API_URL + 'Account/user-profiles');
  }

  meBluster(): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + 'Account/me');
  }

  logoutFromSocialNetwork(profileType: number): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Account/logout-from-social-network', { profileType: profileType });
  }

  addTwitchToUser(code: string): Observable<any> {
    return this.http.post(environment.BLUSTER_API_URL + 'Account/add-twitch-to-user', { code });
  }

  sendResetPasswordEmail(email: string): Observable<any> {

    email = encodeURIComponent(email);

    const body = `email=${email}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post(environment.SSO_URL + 'email/ResetEmailFromBlaster', body, { headers });
  }

  isResetPasswordRequestActual(code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams()
      .set('code', code);

    return this.http.post(environment.SSO_URL + 'IsResetPasswordRequestActual', body.toString(), { headers });
  }

  saveNewPassword(newPassword: string, code: string): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams()
      .set('newPassword', newPassword)
      .set('code', code);

    return this.http.post(environment.SSO_URL + 'UpdatePassword', body, { headers });
  }

  facebookMe(accessToken: string): Observable<any> {
    let headers = {
      'Authorization': 'Bearer ' + accessToken
    }
    return this.http.get(environment.FACEBOOKGRAPHAPIURL + 'me?fields=id,email', { headers: headers });
  }

  googleMe(code: string): Observable<any> {
    return this.http.get(environment.BLUSTER_API_URL + `Account/google-me?code=${code}`);
  }

  authorizeWithOTT(oTT: string, redirectUrl: string): Observable<any> {
    let deviceId = this.cookieService.get('CMDeviceID');
    if (!deviceId) {
      deviceId = getFingerPrint();
      this.cookieService.set('CMDeviceID', deviceId, undefined, '/', getSubDomainName(), true, 'None');
    }
    let headers = new HttpHeaders({
      'DeviceId': deviceId
    });
    let options = { headers: headers };
    return this.http.post(environment.SSO_URL + `AuthenticateByOtt?ott=${oTT}&redirectUrl=${redirectUrl}`, {}, options);
  }

  parseAccessToken(token: string) {
    try {
      const base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      // Add padding if necessary
      switch (base64.length % 4) {
        case 2:
          base64 += '==';
          break;
        case 3:
          base64 += '=';
          break;
        default:
          break;
      }

      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Failed to parse access token:', error);
      return null;
    }
  }


  // parseAccessToken(token: string) {
  //   const base64Url = token.split('.')[1];
  //   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
  //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //   }).join(''));

  //   return JSON.parse(jsonPayload);
  // }
}