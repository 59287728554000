<div class="app d-flex flex-col bg-app-main">
    <app-sidebar></app-sidebar>

    <main class="d-flex flex-col">
        <section class="payments-page d-flex flex-col flex-1 pt-30">

          <div class="container container_md">
            <div class="mb-50">
              <h1 *ngIf="currentPurchase" class="mb-5 text-center">{{currentPurchase.PurchaseTypeId | purchaseName}}</h1>
              <h1 *ngIf="!currentPurchase" class="mb-5 text-center">Free</h1>

              <!-- for a free -->
              <p class="text-md text-center">Your subscription license</p>

              <!-- for a paid subscription-->
              <!-- <p class="text-md text-center">Expiration date: <time>01.24.2024</time></p> -->

              <a
                href="/pricing"
                class="btn btn_custom btn_color-main btn_size-primary btn_rounded-xs block-center mt-15"
              >
                <span>Change Plan</span>
              </a>
            </div>
          </div>
            <div class="container container_xs d-flex flex-col flex-1">
                <div class="block streams pt-20 pb-5 p-inline-15">
                  <div class="streams-table-container custom-scroll custom-scroll_size-md">
                    <table class="streams-table">
                      <caption class="streams-table__title text-left">
                        <h3>History</h3>
                      </caption>

                      <thead>
                        <tr>
                          <th>Subscription</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let userPurchase of userPurchases">
                          <td class="text-overflow">{{userPurchase.PurchaseTypeId | purchaseName}}</td>
                          <td>{{userPurchase.BeginDate | dateFormat : 'date'}}</td>
                          <td>{{userPurchase.EndDate | dateFormat : 'date'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </section>
    </main>
</div>

